export default {
    "1_1":["Сесть/Лечь",1,"Сидеть (Уверенно)"],
    "1_2":["Сесть/Лечь",2,"Сидеть (Уверенно)(2)"],
    "1_3":["Сесть/Лечь",3,"Сидеть на бордюре"],
    "1_4":["Сесть/Лечь",4,"Сидеть (Психолог)"],
    "1_5":["Сесть/Лечь",5,"Сидеть (Чилл)"],
    "1_6":["Сесть/Лечь",6,"Сидеть (Ж)(Нормально)"],
    "1_7":["Сесть/Лечь",7,"Сидеть (Скрестить руки)(Ж)"],
    "1_8":["Сесть/Лечь",8,"Сидеть (2)"],
    "1_9":["Сесть/Лечь",9,"Сидеть 1 (М)"],
    "1_10":["Сесть/Лечь",10,"Сидеть на пляже (М)"],
    "1_11":["Сесть/Лечь",11,"Сидеть на туалете"],
    "1_12":["Сесть/Лечь",12,"Сидеть на пляже 2 (М)"],
    "1_13":["Сесть/Лечь",13,"Сидеть на пляже 3 (М)"],
	"1_14":["Сесть/Лечь",14,"Сидеть на пляже (Ж)"],
    "1_15":["Сесть/Лечь",15,"Сидеть на пляже 2 (Ж)"],
    "1_16":["Сесть/Лечь",16,"Сидеть на пляже 3 (Ж)"],
    "1_17":["Сесть/Лечь",17,"Сидеть на пляже 4 (Ж)"],
    "1_18":["Сесть/Лечь",18,"Загорать (П нога)"],
    "1_19":["Сесть/Лечь",19,"Загорать (Л нога)"],
    "1_20":["Сесть/Лечь",20,"Сидеть гопник"],
    "1_21":["Сесть/Лечь",21,"Сидеть за стойкой (2)"],
    "1_22":["Сесть/Лечь",22,"Сидеть с оружием"],
    "1_23":["Сесть/Лечь",23,"Сидеть на барном стуле (М)"],
    "1_24":["Сесть/Лечь",24,"Сидеть (Кортаны)"],
    "1_25":["Сесть/Лечь",25,"Сесть"],
    "1_26":["Сесть/Лечь",26,"Сесть 2"],
    "1_27":["Сесть/Лечь",27,"Сесть (Ж)"],
    "1_28":["Сесть/Лечь",28,"Присесть (Ж)"],
    "1_29":["Сесть/Лечь",29,"Только не бейте (сидя)"],
    "1_30":["Сесть/Лечь",30,"Просить пощады"],
    "1_31":["Сесть/Лечь",31,"Развалиться 1"],
    "1_32":["Сесть/Лечь",32,"Развалиться 2"],
    "1_33":["Сесть/Лечь",33,"Шезлонг"],
    "1_34":["Сесть/Лечь",34,"Лежать на кровати"],
    "1_35":["Сесть/Лечь",35,"Лежать на шезлонге"],
    "1_36":["Сесть/Лечь",36,"Лежать на шезлонге 1"],
    "1_37":["Сесть/Лечь",37,"Лежать на шезлонге 2"],
    "1_38":["Сесть/Лечь",38,"Лежать на шезлонге 3"],
    "1_39":["Сесть/Лечь",39,"Сидеть на ступеньках 2"],
    "1_40":["Сесть/Лечь",40,"Сидеть на земле 1"],
    "1_41":["Сесть/Лечь",41,"Сидеть на земле 2"],
    "1_42":["Сесть/Лечь",42,"Сидеть на земле 3"],
    "1_43":["Сесть/Лечь",43,"Сидеть на коленях (2)"],
    "1_44":["Сесть/Лечь",44,"Сидеть (Держась за ухо)"],
    "1_45":["Сесть/Лечь",45,"Присесть"],
    "1_46":["Сесть/Лечь",46,"Присесть-1"],
    "1_47":["Сесть/Лечь",47,"Присесть-2"],
    "1_48":["Сесть/Лечь",48,"Сидеть на барном стуле (Ж)"],
    "1_49":["Сесть/Лечь",49,"Сидеть (Ж)(Стыдно)"],
    "1_50":["Сесть/Лечь",50,"Джакузи 1"],
    "1_51":["Сесть/Лечь",51,"Джакузи 2"],
    "1_52":["Сесть/Лечь",52,"Джакузи 3"],
    "1_53":["Сесть/Лечь",53,"Джакузи 4"],
    "1_54":["Сесть/Лечь",54,"Сидеть на ступеньках"],
    "1_55":["Сесть/Лечь",55,"Сидеть уверенно (М)"],
    "1_56":["Сесть/Лечь",56,"Сидит, смотрит туда сюда"],
    "1_57":["Сесть/Лечь",57,"Сидеть расслабленно"],
    "1_58":["Сесть/Лечь",58,"Сидеть нога на ногу"],
    "1_59":["Сесть/Лечь",59,"Сидеть нога на ногу 2"],
    "1_60":["Сесть/Лечь",60,"Сидеть переключая ТВ"],
    "1_61":["Сесть/Лечь",61,"Плакать сидя"],
    "1_62":["Сесть/Лечь",62,"Сидеть в кресле М"],
    "1_63":["Сесть/Лечь",63,"Сидеть грустно"],
    "1_64":["Сесть/Лечь",64,"Болтать сидя"],
    "1_65":["Сесть/Лечь",65,"Сидеть в клубе (1)"],
    "1_66":["Сесть/Лечь",66,"Сидеть в клубе (2)"],
    "1_67":["Сесть/Лечь",67,"Сидеть в клубе (3)"],

	"2_1":["Социальные",1,"Поднять руки"],
	"2_2":["Социальные",2,"Готов к драке"],
	"2_3":["Социальные",3,"Возмутиться"],
	"2_4":["Социальные",4,"Злиться 1"],
	"2_5":["Социальные",5,"Успокоить"],
	"2_6":["Социальные",6,"Вау"],
	"2_7":["Социальные",7,"Уважение"],
	"2_8":["Социальные",8,"И точка"],
	"2_9":["Социальные",9,"Че за нах!?"],
	"2_10":["Социальные",10,"Не твоё, а моё"],
	"2_11":["Социальные",11,"Нет (Медленно)"],
	"2_12":["Социальные",12,"Черт"],
	"2_13":["Социальные",13,"Отвали"],
	"2_14":["Социальные",14,"Только не это"],
	"2_15":["Социальные",15,"Отказать"],
	"2_16":["Социальные",16,"Its me"],
	"2_17":["Социальные",17,"За мной (Грозно)"],
	"2_18":["Социальные",18,"Ты!"],
	"2_19":["Социальные",19,"Нет (Две руки)"],
	"2_20":["Социальные",20,"О да!"],
	"2_21":["Социальные",21,"Пошли"],
	"2_22":["Социальные",22,"Согласиться"],
	"2_23":["Социальные",23,"Мы сможем"],
	"2_24":["Социальные",24,"Впоряде"],
	"2_25":["Социальные",25,"Лайк"],
	"2_26":["Социальные",26,"Отказать 2"],
	"2_27":["Социальные",27,"Разочароваться"],
	"2_28":["Социальные",28,"Испуг"],
	"2_29":["Социальные",29,"Facepalm"],
	"2_30":["Социальные",30,"Дурак"],
	"2_31":["Социальные",31,"Виноват"],
	"2_32":["Социальные",32,"Гэнгста Общение"],
	"2_33":["Социальные",33,"Рукалицо (Ж)"],
	"2_34":["Социальные",34,"Эй, привет"],
	"2_35":["Социальные",35,"Свистнуть"],
	"2_36":["Социальные",36,"Давай досвидание"],
	"2_37":["Социальные",37,"Дай пять!"],
	"2_38":["Социальные",38,"Согласиться 2"],
	"2_39":["Социальные",39,"Стоять!"],
	"2_40":["Социальные",40,"Чётко"],
	"2_41":["Социальные",41,"Да, это оно!"],
	"2_42":["Социальные",42,"Вы прикалываетесь?"],
	"2_43":["Социальные",43,"Успокойся"],
	"2_44":["Социальные",44,"Хорошая идея"],
	"2_45":["Социальные",45,"Посмотреть время"],
	"2_46":["Социальные",46,"Печатать"],
	"2_47":["Социальные",47,"Постучать в дверь"],
	"2_48":["Социальные",48,"Трясти попой (Ж)"],
	"2_49":["Социальные",49,"Почесать задницу"],
	"2_50":["Социальные",50,"Потереть ладони"],
	"2_51":["Социальные",51,"Ползти назад"],
	"2_52":["Социальные",52,"Ковырять в земле"],
	"2_53":["Социальные",53,"Кувырок влево"],
	"2_54":["Социальные",54,"Поклониться-2"],
	"2_55":["Социальные",55,"Переступить через"],
	"2_56":["Социальные",56,"Мыть капот"],
	"2_57":["Социальные",57,"Press F"],
	"2_58":["Социальные",58,"Идти общаться"],
	"2_59":["Социальные",59,"Нести"],
	"2_60":["Социальные",60,"Чинить (2)"],
	"2_61":["Социальные",61,"Осмотреть"],
	"2_62":["Социальные",62,"Хлопать в ладоши (2)"],
	"2_63":["Социальные",63,"Прыжок подогнуть ноги"],
	"2_64":["Социальные",64,"Подумать"],
	"2_65":["Социальные",65,"Крутануться 180"],
	"2_66":["Социальные",66,"Пожать плечами"],


    "3_1":["Позы",1,"У стены 1"],
    "3_2":["Позы",2,"У стены 2"],
    "3_3":["Позы",3,"У стены 3"],
    "3_4":["Позы",4,"Опереться 1"],
    "3_5":["Позы",5,"Опереться 2"],
    "3_6":["Позы",6,"Скрестить руки 1"],
    "3_7":["Позы",7,"Скрестить руки 2"],
    "3_8":["Позы",8,"Опираться на стенку"],
    "3_9":["Позы",9,"Опереться назад"],
    "3_10":["Позы",10,"Опереться скрестив руки"],
    "3_11":["Позы",11,"Скрестить руки (Гангста)"],
    "3_12":["Позы",12,"Скрестить руки-1"],
    "3_13":["Позы",13,"Скрестить руки-2"],
    "3_14":["Позы",14,"Скрестить руки (3)"],
    "3_15":["Позы",15,"Скрестить руки"],
    "3_16":["Позы",16,"Скрестить руки (Ж)(2)"],
    "3_17":["Позы",17,"Поднять руки (Высоко)"],
    "3_18":["Позы",18,"Бояться 1"],
    "3_19":["Позы",19,"Бояться 2"],
    "3_20":["Позы",20,"Стресс"],
    "3_21":["Позы",21,"Руки на пояс"],
    "3_22":["Позы",22,"Руки вверх"],
    "3_23":["Позы",23,"Руки за спину"],
    "3_24":["Позы",24,"Охрана (М)"],
    "3_25":["Позы",25,"Поза охранника"],
    "3_26":["Позы",26,"Неуверенно"],
    "3_27":["Позы",27,"Заложник 1"],
    "3_28":["Позы",28,"Больно"],
    "3_29":["Позы",29,"Спать"],
    "3_30":["Позы",30,"Лежать"],
    "3_31":["Позы",31,"Лежать на спине"],
    "3_32":["Позы",32,"Лечь на спину"],
    "3_33":["Позы",33,"Лежать на спине (2)"],
    "3_34":["Позы",34,"Лежать на животе"],
    "3_35":["Позы",35,"Лежать на животе (3)"],
    "3_36":["Позы",36,"Лечь на живот"],
    "3_37":["Позы",37,"Лечь на бок"],
    "3_38":["Позы",38,"Недовольно стоять"],
    "3_39":["Позы",39,"Недовольно стоять (2)"],
    "3_40":["Позы",40,"Недовольно стоять (3)"],
    "3_41":["Позы",41,"Стоять (Ж)(3)"],
    "3_42":["Позы",42,"Стоять (Тяжело)"],
    "3_43":["Позы",43,"Стоять (Тревор)"],
    "3_44":["Позы",44,"Стоять (Стыдно)"],
    "3_45":["Позы",45,"Стоять виноватым"],
    "3_46":["Позы",46,"Стоять виноватой"],
    "3_47":["Позы",47,"Стоять уверенно (Ж)"],
    "3_48":["Позы",48,"Стоять (Листать телефон)"],
    "3_49":["Позы",49,"Стоять скромно (Ж)"],
    "3_50":["Позы",50,"Стоять (Перекрестить ноги)"],
    "3_51":["Позы",51,"Стоять грустным"],
    "3_52":["Позы",52,"Т-Поза"],
    "3_53":["Позы",53,"Руки в боки"],
    "3_54":["Позы",54,"Руки в боки (2)"],
    "3_55":["Позы",55,"Руки в боки (Ж)(2)"],
    "3_56":["Позы",56,"Опереться на стойку М"],
    "3_57":["Позы",57,"Опереться на стойку Ж"],
    "3_58":["Позы",58,"Скрывать пояс (М)"],
    "3_59":["Позы",59,"Скрывать пояс (Ж)"],
    "3_60":["Позы",60,"Стесняться руки назад"],
    "3_61":["Позы",61,"Лежать раненым (Колено)"],
    "3_62":["Позы",62,"Лежать связанным"],
    "3_63":["Позы",63,"Руки в стороны"],
    "3_64":["Позы",64,"Лежать мечтая"],
    "3_65":["Позы",65,"Наклониться"],
    "3_66":["Позы",66,"Стоять нервничая"],
    "3_67":["Позы",67,"Стоять размышляя"],
    "3_68":["Позы",68,"Опереться сложа руки"],
    "3_69":["Позы",69,"Рука у рта"],
    "3_70":["Позы",70,"Опереться на барную стойку"],
    "3_71":["Позы",71,"Стоять нервничать"],
    "3_72":["Позы",72,"Загорать (Ж)"],
    "3_73":["Позы",73,"Стоять у барной стойки танцуя"],
    "3_74":["Позы",74,"Лежать на левом боку"],
    "3_75":["Позы",75,"Лежать на правом боку"],
    "3_76":["Позы",76,"Смотреть с балкона (Ж)"],
    "3_77":["Позы",77,"Смотреть с балкона (М)"],
    "3_78":["Позы",78, "Вышибала"],
    "3_79":["Позы",79,"Рука на талии (Ж)"],
    "3_80":["Позы",80,"Позировать 3 (Ж)"],
    "3_81":["Позы",81,"Позировать 4 (Ж)"],
   
	"4_1":["Неприличное",1,"Показать средний палец"],
	"4_2":["Неприличное",2,"Показать что-то ещё"],
	"4_3":["Неприличное",3,"Ковыряться в носу"],
	"4_4":["Неприличное",4,"Показать средний палец всем"],
	"4_5":["Неприличное",5,"Поцеловать 1"],
	"4_6":["Неприличное",6,"Поцеловать 2"],
	"4_7":["Неприличное",7,"Поцеловать 3"],
	"4_8":["Неприличное",8,"Все на месте!"],
	"4_9":["Неприличное",9,"Намек 1"],
	"4_10":["Неприличное",10,"Намек 2"],
	"4_11":["Неприличное",11,"Анал (М)"],
	"4_12":["Неприличное",12,"Анал (Ж)"],
	"4_13":["Неприличное",13,"Жёстко драааать"],
	"4_14":["Неприличное",14,"Объятия"],
	"4_15":["Неприличное",15,"Секс у стены (Ж)"],
	"4_16":["Неприличное",16,"Секс у стены (М)"],
	"4_17":["Неприличное",17,"Поцелуй-4"],
	"4_18":["Неприличное",18,"Приватный 1"],
	"4_19":["Неприличное",19,"Приватный 2"],
	"4_20":["Неприличное",20,"Приватный 3"],
	"4_21":["Неприличное",21,"Приватный 4"],
	"4_22":["Неприличное",22,"Приватный 5"],
	"4_23":["Неприличное",23,"Приватный 6"],
	"4_24":["Неприличное",24,"Приватный 7"],
	"4_25":["Неприличное",25,"Приватный 8"],
	"4_26":["Неприличное",26,"На шесте"],
	
	"5_1":["Физ. упражнения",1,"Отжиматься"],
	"5_2":["Физ. упражнения",2,"Качать пресс"],
	"5_3":["Физ. упражнения",3,"Качаться"],
	"5_4":["Физ. упражнения",4,"Подтягиваться"],
	"5_5":["Физ. упражнения",5,"Разминка 1"],
	"5_6":["Физ. упражнения",6,"Разминка 2"],
	"5_7":["Физ. упражнения",7,"Разминка 3"],
	"5_8":["Физ. упражнения",8,"Разминка 4"],
	"5_9":["Физ. упражнения",9,"Размяться"],
	"5_10":["Физ. упражнения",10,"Показать бицепс-1"],
	"5_11":["Физ. упражнения",11,"Показать бицепс-2"],
	"5_12":["Физ. упражнения",12,"Медитировать"],
	"5_13":["Физ. упражнения",13,"Медитировать 1"],
	"5_14":["Физ. упражнения",14,"Медитировать 2"],
	"5_15":["Физ. упражнения",15,"Медитировать 3"],
	"5_16":["Физ. упражнения",16,"Медитировать 4"],
	"5_17":["Физ. упражнения",17,"Медитировать 5"],
	"5_18":["Физ. упражнения",18,"Медитировать 6"],
	"5_19":["Физ. упражнения",19,"Медитировать 7"],
	"5_20":["Физ. упражнения",20,"Зарядка 1"],
	"5_21":["Физ. упражнения",21,"Заниматься йогой 1"],
	"5_22":["Физ. упражнения",22,"Заниматься йогой 2"],
	"5_23":["Физ. упражнения",23,"Заниматься йогой 3"],
	"5_24":["Физ. упражнения",24,"Заниматься йогой 4"],
	"5_25":["Физ. упражнения",25,"Заниматься йогой 5"],

	"6_1":["Танцы",1,"Чечётка"],
	"6_2":["Танцы",2,"Оторваться по полной (М)"],
	"6_3":["Танцы",3,"Оторваться по полной (Ж)"],
	"6_4":["Танцы",4,"Ночной клуб (Ж)"],
	"6_5":["Танцы",5,"Ночной клуб (М)"],
	"6_6":["Танцы",6,"Ночной клуб 2 (М)"],
	"6_7":["Танцы",7,"Жизнерадостный"],
	"6_8":["Танцы",8,"Рэперские телодвижения"],
	"6_9":["Танцы",9,"Подвыпивший"],
	"6_10":["Танцы",10,"Руки вверх"],
	"6_11":["Танцы",11,"Диджей"],
	"6_12":["Танцы",12,"Пластика (Ж)"],
	"6_13":["Танцы",13,"Пластика 2 (Ж)"],
	"6_14":["Танцы",14,"Эротичный (Ж)"],
	"6_15":["Танцы",15,"Энергия руками"],
	"6_16":["Танцы",16,"Репер"],
	"6_17":["Танцы",17,"Неумелый"],
	"6_18":["Танцы",18,"Плавный"],
	"6_19":["Танцы",19,"Плавный (Ж)"],
	"6_20":["Танцы",20,"Танец Шафл 01"],
	"6_21":["Танцы",21,"Танец Шафл 02"],
	"6_22":["Танцы",22,"Танец Шафл 03"],
	"6_23":["Танцы",23,"Танец Шафл 04"],
	"6_24":["Танцы",24,"Танец Шафл 05"],
	"6_25":["Танцы",25,"Танец Шафл 06"],
	"6_26":["Танцы",26,"Танец Шафл 07"],
	"6_27":["Танцы",27,"Танец Шафл 08"],
	"6_28":["Танцы",28,"Танец Шафл 09"],
	"6_29":["Танцы",29,"Танец Шафл 10"],
	"6_30":["Танцы",30,"Танец Шафл 11"],
	"6_31":["Танцы",31,"Танец Шафл 12"],
	"6_32":["Танцы",32,"Танец Шафл 13"],
	"6_33":["Танцы",33,"Танец Шафл 14"],
	"6_34":["Танцы",34,"Танец Шафл 15"],
	"6_35":["Танцы",35,"Танец Шафл 16"],
	"6_36":["Танцы",36,"Танец Шафл 17"],
	"6_37":["Танцы",37,"Танец Шафл 18"],
	"6_38":["Танцы",38,"Танец Шафл 19"],
	"6_39":["Танцы",39,"Танец Шафл 20"],
	"6_40":["Танцы",40,"Танец Шафл 21"],
	"6_41":["Танцы",41,"Танец Шафл 22"],
	"6_42":["Танцы",42,"Танец Шафл 23"],
	"6_43":["Танцы",43,"Танец Шафл 24"],
	"6_44":["Танцы",44,"Танец Шафл 25"],
	"6_45":["Танцы",45,"Танец Шафл 26"],
	"6_46":["Танцы",46,"Танец 1"],
	"6_47":["Танцы",47,"Танец 2"],
	"6_48":["Танцы",48,"Танец 3"],
	"6_49":["Танцы",49,"Танец 4"],
	"6_50":["Танцы",50,"Ночной клуб(ж) 2"],
	"6_51":["Танцы",51,"Танец Змейка"],
	"6_52":["Танцы",52,"Ночной клуб(ж) 3"],
	"6_53":["Танцы",53,"Ночной клуб(ж) 4"],
	"6_54":["Танцы",54,"Танцевать за барной стойкой "],
	"6_55":["Танцы",55,"Танцевать за барной стойкой 2"],
	"6_56":["Танцы",56,"Танцевать за барной стойкой 3"],
	"6_57":["Танцы",57,"Танцевать за барной стойкой 4"],
	"6_58":["Танцы",58,"Танец в отрыве"],
	"6_59":["Танцы",59,"Танец в отрыве 2"],
	"6_60":["Танцы",60,"Танец Шафл 27"],
	"6_61":["Танцы",61,"Танец 5"],
	"6_62":["Танцы",62,"Ночной клуб 2 (Ж)"],
	"6_63":["Танцы",63,"Плавный 2"],
	"6_64":["Танцы",64,"DJ 2"],
	"6_65":["Танцы",65,"Развязный женский"],
	"6_66":["Танцы",66,"Трясти руками"],
	"6_67":["Танцы",67,"Танец зайки"],
	"6_68":["Танцы",68,"Флекс правой рукой (М)"],
	"6_69":["Танцы",69,"Флекс двумя руками (М)"],
	"6_70":["Танцы",70,"Вертихвостка (1)"],
	"6_71":["Танцы",71,"Вертихвостка (2)"],
	"6_72":["Танцы",72,"Вертихвостка (3)"],
	"6_73":["Танцы",73,"Вертихвостка (4)"],
	"6_74":["Танцы",74,"Клуб 1х1 (Ж)"],
	"6_75":["Танцы",75,"Клуб 1х1 (М)"],
	"6_76":["Танцы",76,"Двигать кулаками"],
	"6_77":["Танцы",77,"Хаслить"],
	"6_78":["Танцы",78,"Клубная 1"],
	"6_79":["Танцы",79,"Клубная 2"],
	"6_80":["Танцы",80,"Клубная 3"],
	"6_81":["Танцы",81,"Клубная 4"],
	"6_82":["Танцы",82,"Клубная 5"],
	"6_83":["Танцы",83,"Клубная 6"],
	"6_84":["Танцы",84,"Клубная 7"],
	"6_85":["Танцы",85,"Танцевать с напитком"],

}
