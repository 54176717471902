<svg xmlns="http://www.w3.org/2000/svg" width="91.25" height="99.56" viewBox="0 0 91.25 99.56">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="electric" class="cls-1" d="M4252,5426l-25,50-20,20-41-9,29-86,55,20Z" transform="translate(-4163.31 -5399.94)"/>
</svg>
