export default {
    hair: {
        true: [
            {id: 1, name: 'Коротко', price: 1000, creator: false},
            {id: 2, name: '"Ястреб"', price: 1000, creator: true},
            {id: 3, name: '"Хипстер"', price: 1000, creator: true},
            {id: 4, name: 'Челка на бок', price: 1000, creator: false}, 
            {id: 5, name: 'Коротко', price: 1000, creator: false},
            {id: 6, name: '"Байкер"', price: 1000, creator: true},
            {id: 7, name: '"Хвост"', price: 1000, creator: false},
            {id: 8, name: 'Косички', price: 1000, creator: true},
            {id: 9, name: '"Прилиза"', price: 1000, creator: false},
            {id: 10, name: '"Коротко"', price: 1000, creator: true},
            {id: 11, name: '"Шипы"', price: 1000, creator: true},
            {id: 12, name: '"Цезарь"', price: 1000, creator: false},
            {id: 13, name: '"Чоппи"', price: 1000, creator: true},
            {id: 14, name: 'Дреды', price: 1000, creator: true},
            {id: 15, name: 'Длинные', price: 1000, creator: false},
            {id: 16, name: 'Лохматые кудри', price: 1000, creator: false}, 
            {id: 17, name: '"Сёрфингист"', price: 1000, creator: false},
            {id: 18, name: '"Набок"', price: 1000, creator: true},
            {id: 19, name: '"Зализ"', price: 1000, creator: false},
            {id: 20, name: '"Длинные"', price: 1000, creator: false},
            {id: 21, name: '"Юный хипстер"', price: 1000, creator: true},
            {id: 22, name: '"Веник"', price: 1000, creator: true},
            {id: 24, name: 'Классически "косички"', price: 1000, creator: false},
            {id: 25, name: 'Косички - "пальма"', price: 1000, creator: false},
            {id: 26, name: 'Косички - "молния"', price: 1000, creator: false},
            {id: 27, name: 'Зачесанные на верх косички', price: 1000, creator: false}, 
            {id: 28, name: 'Косички - зигзаги', price: 1000, creator: false},
            {id: 29, name: 'Косички - "улитки"', price: 1000, creator: false},
            {id: 30, name: 'Хай-топ', price: 1000, creator: false},
            {id: 31, name: 'Растрепанный зачес назад', price: 1000, creator: false},
            {id: 32, name: 'Подстриженный зачес назад', shortname: "Подстр. зачес назад", price: 1000, creator: true},
            {id: 33, name: 'Подстриженный зачес на бок', shortname: "Подстр. зачес на бок", price: 1000, creator: true},
            {id: 34, name: 'Шипастый ирокез', price: 1000, creator: false},
            {id: 35, name: '"Стиляга"', price: 1000, creator: true},
            {id: 36, name: '"Стиляга" со слоями', price: 1000, creator: false},
        ],
        false: [
            {id: 1, name: 'Коротко', price: 1000, creator: true},
            {id: 2, name: '"Слои"', price: 1000, creator: true},
            {id: 3, name: 'Косички', price: 1000, creator: true},
            {id: 4, name: '"Хвост"', price: 1000, creator: true},
            {id: 5, name: '"Ирокез"', price: 1000, creator: true},
            {id: 6, name: '"Косички"', price: 1000, creator: false},
            {id: 7, name: '"Боб"', price: 1000, creator: false},
            {id: 8, name: '"Ястреб"', price: 1000, creator: false},
            {id: 9, name: '"Ракушка"', price: 1000, creator: false},
            {id: 10, name: '"Лонг Боб"', price: 1000, creator: true},
            {id: 11, name: '"Свободно"', price: 1000, creator: true},
            {id: 12, name: '"Пиксель"', price: 1000, creator: true},
            {id: 13, name: 'Подбритые виски', price: 1000, creator: true},
            {id: 14, name: '"Узел"', price: 1000, creator: false},
            {id: 15, name: '"Волнистый Боб"', price: 1000, creator: true},
            {id: 16, name: '"Красотка"', price: 1000, creator: true},
            {id: 17, name: ' "Пучок"', price: 1000, creator: true},
            {id: 18, name: '"Флэппер Боб"', price: 1000, creator: false},
            {id: 19, name: '"Тугой узел"', price: 1000, creator: false},
            {id: 20, name: '"Одуванчик"', price: 1000, creator: true},
            {id: 21, name: '"Взрыв"', price: 1000, creator: false},
            {id: 22, name: '"Узел"', price: 1000, creator: false},
            {id: 25, name: '"Сколотые косички"', price: 1000, creator: false},
            {id: 26, name: 'Косички - "листья"', price: 1000, creator: false},
            {id: 27, name: 'Косички - зигзаги', price: 1000, creator: false},
            {id: 28, name: '"Хвостики" с челкой', price: 1000, creator: false},
            {id: 29, name: 'Косички - "волны"', price: 1000, creator: false},
            {id: 30, name: 'Косички - завитки', price: 1000, creator: false},
            {id: 31, name: 'Челка - валик', price: 1000, creator: false},
            {id: 32, name: 'Растрёпанный зачес назад', price: 1000, creator: false},
            {id: 33, name: 'Подстриженный зачес назад', price: 1000, creator: true},
            {id: 34, name: 'Подстриженный зачес набок', price: 1000, creator: true},
            {id: 35, name: 'Шипастый ирокез', price: 1000, creator: false},
            {id: 36, name: 'Бандана и косички', price: 1000, creator: false},
            {id: 37, name: '"Челси"', price: 1000, creator: true},
            {id: 38, name: '"Стиляга" со слоями', price: 1000, creator: true},
        ]
    },
    eyebrows: [
        {id: 0, name: 'Аккуратные', price: 100, creator: true},
        {id: 1, name: 'Модные', price: 100, creator: true},
        {id: 2, name: 'Клеопатра', price: 100, creator: false},
        {id: 3, name: 'Ироничные', price: 100, creator: true},
        {id: 4, name: 'Женственные', price: 100, creator: false},
        {id: 5, name: 'Обольстительные', price: 100, creator: false}, 
        {id: 6, name: 'Нахмуренные', price: 100, creator: true},
        {id: 7, name: 'Чикса', price: 100, creator: false},
        {id: 8, name: 'Торжествующие', price: 100, creator: false},
        {id: 9, name: 'Беззаботные', price: 100, creator: true},
        {id: 10, name: 'Дугой', price: 100, creator: true},
        {id: 11, name: 'Мышка', price: 100, creator: true},
        {id: 12, name: 'Двойная высечка', price: 100, creator: false},
        {id: 13, name: 'Впалые', price: 100, creator: false},
        {id: 14, name: 'Нарисованные карандашом', price: 100, creator: false},
        {id: 15, name: 'Выщипанные', price: 100, creator: false},
        {id: 16, name: 'Прямые и тонкие', price: 100, creator: false},
        {id: 17, name: 'Естественная', price: 100, creator: false},
        {id: 18, name: 'Пышные', price: 100, creator: true},
        {id: 19, name: 'Неопрятные', price: 100, creator: true},
        {id: 20, name: 'Широкие', price: 100, creator: false},
        {id: 21, name: 'Обычные', price: 100, creator: false},
        {id: 22, name: 'Южно-европейские', price: 100, creator: true},
        {id: 23, name: 'Ухоженные', price: 100, creator: true}, 
        {id: 24, name: 'Кустистые', price: 100, creator: true},
        {id: 25, name: 'Перышки', price: 100, creator: true},
        {id: 26, name: 'Колючие', price: 100, creator: false},
        {id: 27, name: 'Сросшиеся', price: 100, creator: false},
        {id: 28, name: 'Крылатые', price: 100, creator: false},
        {id: 29, name: 'Тройная высечка', price: 100, creator: true},
        {id: 30, name: 'Высечка дугой', price: 100, creator: false},
        {id: 31, name: 'Подрезанные', price: 100, creator: false},
        {id: 32, name: 'Сходящие на нет', price: 100, creator: false}, 
        {id: 33, name: 'Высечка', price: 100, creator: false},
    ],
    facialhair: [
        {id: 255, name: "Гладкая кожа", price: 100, creator: true},
        {id: 0, name: 'Легкая щетина', price: 100, creator: true},
        {id: 1, name: 'Бальбо', price: 100, creator: true},
        {id: 2, name: 'Круглая борода', price: 100, creator: true},
        {id: 3, name: 'Эспаньолка', price: 100, creator: true},
        {id: 4, name: 'Козлиная бородка', price: 100, creator: true},
        {id: 5, name: 'Островок', price: 100, creator: true},
        {id: 6, name: 'Тонкая бородка', price: 100, creator: true},
        {id: 7, name: 'Короткая бородка', price: 100, creator: true},
        {id: 8, name: 'Мушкетёр', price: 100, creator: true},
        {id: 9, name: 'Усы', price: 100, creator: true},
        {id: 10, name: 'Подстриженная борода', price: 100, creator: true},
        {id: 11, name: 'Щетина', price: 100, creator: true},
        {id: 12, name: 'Круглая борода', price: 100, creator: true},
        {id: 13 , name: 'Борода-подкова', price: 100, creator: false},
        {id: 14, name: 'Карандашные усы и баки', price: 100, creator: false},
        {id: 15, name: 'Борода-ремень', price: 100, creator: false},
        {id: 16, name: 'Бальбо и баки', price: 100, creator: false},
        {id: 17, name: 'Баки', price: 100, creator: false},
        {id: 18, name: 'Короткая бородка', price: 100, creator: false},
        {id: 19, name: 'Дали', price: 100, creator: false},
        {id: 20, name: 'Дали и борода', price: 100, creator: false},
        {id: 21, name: 'Велосипедный руль', price: 100, creator: false},
        {id: 22, name: 'Островок с усами', price: 100, creator: false},
        {id: 23, name: 'Английские усы с пеньком', price: 100, creator: false},
        {id: 24, name: 'Голливудская борода', price: 100, creator: false},
        {id: 25, name: 'Фу манчу', price: 100, creator: false},
        {id: 26, name: 'Островок с баками', price: 100, creator: false},
        {id: 27, name: 'Широкие баки', price: 100, creator: false},
        {id: 28, name: 'Борода "ширма"', price: 100, creator: false},
    ],
    chesthair: [//-
        {id: 255, name: "Гладкая кожа", price: 100},
        {id: 0, name: "Естественный", price: 100},
        {id: 1, name: "Полоска", price: 100},
        {id: 2, name: "Дерево", price: 100},
        {id: 3, name: "Волосатая", price: 100}, 
        {id: 4, name: " Заросшая", price: 100},
        {id: 5, name: "Обезьяна", price: 100},
        {id: 6, name: "Причёсанная обезьяна", price: 100},
        {id: 7, name: "Бикини", price: 100},
        {id: 8, name: "Молния", price: 100},
        {id: 9, name: "Обратная молния", price: 100},
        {id: 10, name: "Сердечко", price: 100},
        {id: 11, name: "Усы", price: 100},
        {id: 12, name: "Смайлик", price: 100}, 
        {id: 13, name: "Череп", price: 100},
        {id: 14, name: "Тропинка", price: 100},
        {id: 15, name: "Тропинка и соски", price: 100}, 
        {id: 16, name: "Волосатые руки", price: 100}
    ],
    blemishes: [
        {id: 255, name: "Нет"},
        {id: 0, name: "Краснуха"},
        {id: 1, name: "Корь"},
        {id: 2, name: "Пятна"},
        {id: 3, name: "Сыпь"},
        {id: 4, name: "Угри"},
        {id: 5, name: "Налёт"},
        {id: 6, name: "Гнойники"},
        {id: 7, name: "Прыщики"},
        {id: 8, name: "Большие прыщи"},
        {id: 9, name: "Прыщи"},
        {id: 10, name: "Сыпь на щёках"},
        {id: 11, name: "Сыпь на лице"},
        {id: 12, name: "Расковыренные"},
        {id: 13, name: "Пубертат"},
        {id: 14, name: "Язва"},
        {id: 15, name: "Сыпь на челюсти"},
        {id: 16, name: "С двумя лицами"},
        {id: 17, name: "Зона T"},
        {id: 18, name: "Сальная кожа"},
        {id: 19, name: "Шрамы"},
        {id: 20, name: "Шрамы от прыщей"},
        {id: 21, name: "Рубцы от прыщей"},
        {id: 22, name: "Герпес"},
        {id: 23, name: "Лишай"},
    ],
    ageing: [
        {id: 255, name: "Нет"},
        {id: 0, name: "Морщины глаз"},
        {id: 1, name: "Признаки старения"},
        {id: 2, name: "Средний возраст"},
        {id: 3, name: "Морщины"},
        {id: 4, name: "Депрессия"},
        {id: 5, name: "Пожилой"},
        {id: 6, name: "Старость"},
        {id: 7, name: "Обветренная кожа"},
        {id: 8, name: "Морщинистая кожа"},
        {id: 9, name: "Обвисшая кожа"},
        {id: 10, name: "Тяжёлая жизнь"},
        {id: 11, name: "Винтаж"},
        {id: 12, name: "Пенсионный"},
        {id: 13, name: "Наркомания"},
        {id: 14, name: "Престарелость"},
        {id: 15, name: "Депрессия"}
    ],
    complexion: [
        {id: 255, name: "Нет"},
        {id: 0, name: "Румянец"},
        {id: 1, name: "Раздражение от щетины"},
        {id: 2, name: "Покраснение"},
        {id: 3, name: "Солнечный ожог"},
        {id: 4, name: "Синяки"},
        {id: 5, name: "Алкоголизм"},
        {id: 6, name: "Пятна"},
        {id: 7, name: "Тотем"},
        {id: 8, name: "Кровеносные сосуды"},
        {id: 9, name: "Повреждения"},
        {id: 10, name: "Бледная"},
        {id: 11, name: "Мёртво-бледная"}
    ],
    molesfreckles: [
        {id: 255, name: "Нет"},
        {id: 0, name: "Ангелочек"},
        {id: 1, name: "Повсюду"},
        {id: 2, name: "Местами"},
        {id: 3, name: "Единичные"},
        {id: 4, name: "Переносица"},
        {id: 5, name: "Куколка"},
        {id: 6, name: "Фея"},
        {id: 7, name: "Загорелая"},
        {id: 8, name: "Родинки"},
        {id: 9, name: "Ряд"},
        {id: 10, name: "Как у модели"},
        {id: 11, name: "Редкие"},
        {id: 12, name: "Веснушки"},
        {id: 13, name: "Капельки"},
        {id: 14, name: "Удвоенность"},
        {id: 15, name: "Одна сторона"},
        {id: 16, name: "Пары"},
        {id: 17, name: "Бородавки"}
    ],
    sundamage: [
        {id: 255, name: "Нет"},
        {id: 0, name: "Неровная"},
        {id: 1, name: "Наждак"},
        {id: 2, name: "Пятнистая"},
        {id: 3, name: "Грубая"},
        {id: 4, name: "Жёсткая"},
        {id: 5, name: "Шероховатая"},
        {id: 6, name: "Загрубленная"},
        {id: 7, name: "Неровная"},
        {id: 8, name: "Со складками"},
        {id: 9, name: "Трещины"},
        {id: 10, name: "Твердая"}
    ],
    eyescolor: [
        {id: 0, name: 'Зеленые', price: 100},
        {id: 1, name: 'Изумрудные', price: 100},
        {id: 2, name: 'Голубые', price: 100},
        {id: 3, name: 'Синие', price: 100},
        {id: 4, name: 'Светлый шатен', price: 100},
        {id: 5, name: 'Темно-коричневый', price: 100},
        {id: 6, name: 'Карий', price: 100},
        {id: 7, name: 'Темно-серый', price: 100},
        {id: 8, name: 'Светло-серый', price: 100}
    ],
    blush: [ // Румяна//-
        {id: 255, name: "Нет", price: 100},
        {id: 0, name: "Полный", price: 100},
        {id: 1, name: "Под углом", price: 100},
        {id: 2, name: "Округлый", price: 100},
        {id: 3, name: "Горизонтальный", price: 100},
        {id: 4, name: "На скулах", price: 100},
        {id: 5, name: "Красотка", price: 100},
        {id: 6, name: "В стиле 80-х", price: 100},
    ],
    eyesmakeup: [//-
        {id: 255, name: "Нет", price: 100},
        {id: 0, name: "Дымчато-чёрный", price: 100},
        {id: 1, name: "Бронзовый", price: 100},
        {id: 2, name: "Мягкий серый", price: 100},
        {id: 3, name: "Ретро-гламур", price: 100},
        {id: 4, name: "Естественный", price: 100},
        {id: 5, name: "Кошачьи глаза", price: 100},
        {id: 6, name: "Чикса", price: 100},
        {id: 7, name: "Вамп", price: 100},
        {id: 8, name: "Вайнвудский гламур", price: 100},
        {id: 9, name: "Баблгам", price: 100},
        {id: 10, name: "Мечта о море", price: 100},
        {id: 11, name: "Пин-ап", price: 100},
        {id: 12, name: "Лиловая страсть", price: 100},
        {id: 13, name: "Дымчатые кошачьи глаза", price: 100},
        {id: 14, name: "Огненный рубин", price: 100},
        {id: 15, name: "Эстрадная приценсса", price: 100},
        {id: 16, name: "Тушь для глаз", price: 100},
        {id: 17, name: "Кровены слёзы", price: 100},
        {id: 18, name: "Хеви-метал", price: 100},
        {id: 19, name: "Печаль", price: 100},
        {id: 20, name: "Князь тьмы", price: 100},
        {id: 21, name: "Рокер", price: 100},
        {id: 22, name: "Гот", price: 100},
        {id: 23, name: "Панк", price: 100},
        {id: 24, name: "Сокрушённость", price: 100},
    ],
    lipstick: [
        {id: 255, name: "Нет", price: 100},
        {id: 0, name: "Цветные матовые", price: 100},
        {id: 1, name: "Цветные блестящие", price: 100},
        {id: 2, name: "Контур, матовые", price: 100},
        {id: 3, name: "Контур, блестящие", price: 100},
        {id: 4, name: "Жирный контур, матовые", price: 100},
        {id: 5, name: "Жирный контур, блестящие", price: 100},
        {id: 6, name: "Некрашен, контур, матовые", price: 100},
        {id: 7, name: "Некрашен, контур, блеск", price: 100},
        {id: 8, name: "Размазанная помада", price: 100},
        {id: 9, name: "Гейша", price: 100}
    ]
}