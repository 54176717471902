<script>
    import { translateText } from 'lang'
</script>
{#if false}
<div class="adminmenu">
    <div class="adminmenu__title">Vitaliy_Zdobich (ID: 228)</div> 
    <div class="adminmenu__grid">
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Время входа')}:</div>
            <div>16:14:29</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Позиция')}:</div>
            <div>Мир</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Уровень')}:</div>
            <div>30</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Опыт')}:</div>
            <div>90/93</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Пинг')}:</div>
            <div>36</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Игра')}:</div>
            <div>EpicGames</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Фракция')}:</div>
            <div>Полиция</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Ранг')}:</div>
            <div>1</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Работа')}:</div>
            <div>Почтальон</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Варны')}:</div>
            <div>0</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Здоровье')}:</div>
            <div>200</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Броня')}:</div>
            <div>100</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Скорость')}:</div>
            <div>179</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Деньги')}:</div>
            <div>500$</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'В банке')}:</div>
            <div>228$</div>
        </div>
    </div>
    <div class="adminmenu__title">{translateText('player2', 'Транспорт')} Police1 (ID: 2456, {translateText('player2', 'Номер')}: E5S123)</div>
    <div class="adminmenu__grid">
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Здоровье')}:</div>
            <div>500</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Скорость')}:</div>
            <div>150/200</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Топливо')}:</div>
            <div>14/70</div>
        </div>
        <div class="adminmenu__element">
            <div class="gray">{translateText('player2', 'Двигатель')}:</div>
            <div>Заведён</div>
        </div>
    </div>
</div>
{/if}