<svg xmlns="http://www.w3.org/2000/svg" width="161.97" height="268.09" viewBox="0 0 161.97 268.09">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="zavod" class="cls-1" d="M5623,4392H5501l-3,251,16,1v8l64,2,3-49,9-9,14-2,27-2,15-38,8-56-1-10-21-4Z" transform="translate(-5495 -4389)"/>
</svg>
