<svg xmlns="http://www.w3.org/2000/svg" width="318" height="345.72" viewBox="0 0 318 345.72">
  <defs>
    <style>
      .cls-1 {
        fill: #d9d9d9;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="farm3" class="cls-1" d="M5505,2335l-146,145-59-55-103-29,14-71-19-31,124-121,21-33,66,36,18,3,39-6,11,4Z" transform="translate(-5189.88 -2137.72)"/>
</svg>
