<svg xmlns="http://www.w3.org/2000/svg" width="116.88" height="110.16" viewBox="0 0 116.88 110.16">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="mech" class="cls-1" d="M4084,6359l2,33,17,25v46l-17-1-95-61,28-39,25-2Z" transform="translate(-3989.12 -6356)"/>
</svg>
