<svg xmlns="http://www.w3.org/2000/svg" width="114.28" height="91.5" viewBox="0 0 114.28 91.5">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="sklad2" class="cls-1" d="M5525,3190l52,21,4-5,23,7-21,56-1,4-8,2-79-32,13-36,5-2,4-13Z" transform="translate(-5492.34 -3187.19)"/>
</svg>
