<script>
    import image from '../../images/gang.jpg'
	import image2 from '../../images/gang2.jpg'
</script>

<div class="text">
    <p><span style="font-weight: 400;">Находясь в банде для вас, открываются новые возможности, и совершенно новые виды деятельности, которые чаще всего запрещены законами. Среди них вы сможете найти: <strong>грабежи</strong>, <strong>угоны</strong>, <strong>война за территории</strong>, <strong>торговля оружием и наркотиками</strong>.</span></p>
    <p><span style="font-weight: 400;">Всего в штате можно найти 5 банд: <strong>Blood Street Gang</strong>, <strong>The Families</strong>, <strong>The Ballas Gang</strong>, <strong>Los Santos Vagos</strong>, <strong>Marabunta Grande</strong>. Также как и мафии некоторые из них могут быть в союзе, а некоторые вести ожесточенные войны.</span></p>
    <p><span style="font-weight: 400;">Одна из главных задач каждой банды - <strong>контроль наибольшего количества территорий</strong>, за каждую из них они получают по 90$. Поэтому между бандами происходит война за территорию, после начала войны, главная задача банды уничтожить всех своих противников.</span></p>
    <p><span style="font-weight: 400;">Нелегальные предметы которые помогают в ограблениях, похищениях и других криминальных делах, бандиты смогут купить на "<strong>Чёрном рынке</strong>"</span></p>
    <p><span style="font-weight: 400;"><strong>Наборы</strong> в банды проходят через подачу объявлений, если у вас появилось желание попасть в одну из них, то не забывайте, что вы должны полностью соответствовать её внутренним правилам.</span></p>
    <p><strong>Команды</strong>
        <br /><strong>/follow</strong> - вести за собой
        <br /><strong>/openstock</strong> - открыть склад
        <br /><strong>/closestock</strong> - закрыть склад
        <br /><strong>/capture</strong> - начать захват территории
        <br /><strong>/tpcapture</strong> - телепортироваться в зону капта</p>
     <div style="display: flex; flex-direction: column;"><img src={image} alt="Криминальные фракции" class="contentImg"/><img src={image2} alt="Криминальные фракции" class="contentImg"/></div>

</div>