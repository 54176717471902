<svg xmlns="http://www.w3.org/2000/svg" width="76.41" height="85.25" viewBox="0 0 76.41 85.25">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="boatrent" class="cls-1" d="M2676,6277l18,14,8,31,46-30-46-52Z" transform="translate(-2674.09 -6240.41)"/>
</svg>
