<svg xmlns="http://www.w3.org/2000/svg" width="953.31" height="1000.31" viewBox="0 0 953.31 1000.31">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="airport1" class="cls-1" d="M3118,7090l-9-11,10-12-159-94-4-79-58,22-252,378-14,14h-36l-21,3-130,223,40,26-20,43,88,55,32-42,341,188-14,16,92,52,16-25,64,38,13-25,47,28,126-224,2-5-120-70,122-208-10-14-18-31,5-31,111-198,32-34-35-40-73,78-114,208-49,23-48-14-37-33-8-31,11-26Z" transform="translate(-2442.81 -6891.38)"/>
</svg>
