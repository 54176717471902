<svg xmlns="http://www.w3.org/2000/svg" width="320.03" height="240.09" viewBox="0 0 320.03 240.09">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="forest2" class="cls-1" d="M6039,2356l-21-47-32-24-25-27-28-22-77-27-71-8,10-21,38,3,17-9,27-2,21,3,26,2,32,8,28,12,27,19,15,19,7,14-2,8,16,5,4,9,12,10,11,3,3,9,8,19,1,8,10,2,2,12v11l-4,13-7,8,11,8-2,9,5,20-31,2-23,1Z" transform="translate(-5782.59 -2169.03)"/>
</svg>
