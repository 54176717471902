export const stageItem = {
    none: 0,
    get: 1,
    move: 2,
    moveEnd: 3,
    info: 4,
    useGet: 5,
	useItem: 6,
	fastClick: 7,
	moveBlock: 7
}

export const clearSlot = {
    use: true,
    hover: false,
    active: false,
    ItemId: 0,
    item_type: 0,
    item_amount: 0,
    item_money: 0
};

export const defaulSelectItem = {
    use: stageItem.none,
    width: 0,
    height: 0
};

export const defaulHoverItem = {
    id: -1,
    arrayName: false
};

export const getInfo = {
    inventory: 1,
    weapons: 2,
    accessories: 3,
    backpack: 4,
    other: 5,
    Inv_VehicleUpgrades: 6,
    Inv_VehicleTuning: 7,
    trade: 8
};

export const maxSlots = {
    accessories: 15,
    inventory: 35,
    backpack: 20,
    other: 30,
    fastSlots: 5,        
    trade: 8,
    with_trade: 8,
}

export const otherName = [
    {
        name: "",
		descr: "",
        icon: "",
    }, 
    {
        name: "Машина",
		descr: "Багажник автомобиля. Помни, что вещи из него могут украсть другие игроки.",
        icon: "inventoryicons-car",
    },    
    {
        name: "Сейф",
		descr: "Помни, что вещи из него могут украсть другие игроки.",
        icon: "inventoryicons-delivery-box",
    },
    {
        name: "Шкаф",
		descr: "Помни, что вещи из него могут украсть другие игроки.",
        icon: "inventoryicons-delivery-box",
    },
    {
        name: "Шифонер",
		descr: "Помни, что вещи из него могут украсть другие игроки.",
        icon: "inventoryicons-delivery-box",
    },
    {
        name: "Склад фракции",
		descr: "Помни, что вещи со склада могут украсть другие игроки.",
        icon: "inventoryicons-open-box",
    },
    {
        name: "Семья",
		descr: "Помни, что вещи со склада могут украсть другие игроки.",
        icon: "inventoryicons-open-box"
    },
    {
        name: "Связка",
		descr: "Связка ключей",
        icon: "inventoryicons-open-padlock",
    },
    {//8
        name: "Рядом",
		descr: "Предметы, которые находятся на полу рядом с тобой.",
        icon: "inventoryicons-drop",
    },
    {//9
        name: "Модификации",
		descr: "Модификации, установленные в оружии.",
        icon: "inventoryicons-rifle",
    },
    {//10
        name: "Хранилище",
		descr: "Место, где хранятся награды и посылки.",
        icon: "inventoryicons-padlock",
    },
    {//11
        name: "Палатка",
		descr: "Торговая палатка, в которой можно приобрести что-то интересное.",
        icon: "inventoryicons-shop-cart",
    },
    {//12
        name: "Ящик",
        descr: "Место, где хранятся награды и посылки.",
        icon: "inventoryicons-padlock",
    },
]

export const otherType = {
	None: 0,
	Vehicle: 1,
	Safe: 2,
	Wardrobe: 3,
	Chiffonier: 4,
	Fraction: 5,
	Organization: 6,
	Key: 7,
	Nearby: 8,
	wComponents: 9,
	Storage: 10,
	Tent: 11,
    Case: 12
}

export const clothesId = [
    "Hats",
    "Masks",
    "Ears",
    "Glasses",
    "Accessories",
    "Tops",
    "Undershirts",
    "Armors",
    "Bags",
    "Legs",
    "Bracelets",
    "Watches",
    "Torsos",
    "Shoes",
];

export const itemIdCaseToId = {
    250: 0,
    251: 1,
    252: 2,
    253: 3,
    254: 4,
    255: 5,
    256: 6,
    257: 7,
    258: 8,
    259: 9,
    260: 10,
    261: 11,
    262: 12,
    265: 13,
    268: 14,
    281: 15,
    282: 16,
    283: 17,
    284: 18,
    285: 19,
    286: 20,
    287: 21,
};


export const ItemToWeaponHash = {
	"109":"911657153",
	"136":"100416529",
	"137":"205991906",
	"139":"177293209",
	"140":"1785463520",
	"150":"-1355376991",
	"100":"453432689",
	"105":"137902532",
	"108":"584646201",
	"101":"1593441988",
	"107":"3249783761",
	"103":"3218215474",
	"104":"3523564046",
	"102":"2578377531",
	"152":"2441047180",
	"151":"727643628",
	"106":"3696079510",
	"110":"1198879012",
	"111":"2548703416",
	"112":"3219281620",
	"113":"2285322324",
	"114":"3415619887",
	"119":"171789620",
	"115":"324215364",
	"117":"736523883",
	"123":"3173288789",
	"116":"3675956304",
	"118":"4024951519",
	"153":"1198256469",
	"120":"2634544996",
	"121":"2144741730",
	"122":"1627465347",
	"124":"2024373456",
	"125":"3686625920",
	"127":"2210333304",
	"126":"3220176749",
	"129":"3231910285",
	"138":"3342088282",
	"128":"2937143193",
	"130":"2132975508",
	"131":"1649403952",
	"132":"961495388",
	"266":"-1658906650",
	"133":"4208062921",
	"134":"2526821735",
	"135":"2228681469",
	"141":"487013001",
	"146":"984333226",
	"144":"3800352039",
	"143":"2640438543",
	"142":"2017895192",
	"145":"2828843422",
	"147":"4019527611",
	"148":"317205821",
	"149":"1432025498"
}

export const WeaponHashToItem = {
    "911657153": "109",
    "100416529": "136",
    "205991906": "137",
    "177293209": "139",
	"1785463520": "140",
	"-1355376991": "150",
    "137902532": "105",
    "171789620": "119",
    "317205821": "148",
    "324215364": "115",
    "453432689": "100",
    "487013001": "141",
    "584646201": "108",
    "727643628": "151",
    "736523883": "117",
    "961495388": "132",
    "-1658906650": "266",
    "984333226": "146",
    "1198256469": "153",
    "1198879012": "110",
    "1432025498": "149",
    "1593441988": "101",
    "1627465347": "122",
    "1649403952": "131",
    "2017895192": "142",
    "2024373456": "124",
    "2132975508": "130",
    "2144741730": "121",
    "2210333304": "127",
    "2228681469": "135",
    "2285322324": "113",
    "2441047180": "152",
    "2526821735": "134",
    "2548703416": "111",
    "2578377531": "102",
    "2634544996": "120",
    "2640438543": "143",
    "2828843422": "145",
    "2937143193": "128",
    "3173288789": "123",
    "3218215474": "103",
    "3219281620": "112",
    "3220176749": "126",
    "3231910285": "129",
    "3249783761": "107",
    "3342088282": "138",
    "3415619887": "114",
    "3523564046": "104",
    "3675956304": "116",
    "3686625920": "125",
    "3696079510": "106",
    "3800352039": "144",
    "4019527611": "147",
    "4024951519": "118",
    "4208062921": "133"
}

export const clothesData = {
	"-12": {
		slotId: 0,
		event: "setPropIndex",
		componentId: 0
	},
	"-1": {
		slotId: 1,
		event: "setComponentVariation",
		componentId: 1
	},
	"-2": {
		slotId: 2,
		event: "setPropIndex",
		componentId: 2
	},
	"-13": {
		slotId: 3,
		event: "setPropIndex",
		componentId: 1
	},
	"-7": {
		slotId: 4,
		event: "setComponentVariation",
		componentId: 7
	},
	"-11": {
		slotId: 5,
		event: "setComponentVariation",
		componentId: 11
	},
	"-8": {
		slotId: 6,
		event: "setComponentVariation",
		componentId: 11
	},
	"-9": {
		slotId: 7,
		event: "setComponentVariation",
		componentId: 9
	},
	"-5": {
		slotId: 8,
		event: "setComponentVariation",
		componentId: 5
	},
	"-4": {
		slotId: 9,
		event: "setComponentVariation",
		componentId: 4
	},
	"-15": {
		slotId: 10,
		event: "setPropIndex",
		componentId: 7
	},
	"-14": {
		slotId: 11,
		event: "setPropIndex",
		componentId: 6
	},
	"-3": {
		slotId: 12,
		event: "setComponentVariation",
		componentId: 11
	},
	"-6": {
		slotId: 13,
		event: "setComponentVariation",
		componentId: 6
	},
}
export const clothes = {
    Hats: {// 0 - Hats
        slotId: 0,
        name: "шляпы",
        type: "Hats",
        icon: "inv-item-cap",
        itemId: -12
    },
    Masks: {// 1 - Masks
        slotId: 1,
        name: "маски",
        type: "Masks",
        icon: "inv-item-mask",
        itemId: -1
    },
    Ears: {// 2 - Ears
        slotId: 2,
        name: "аксессуары на ухо",
        type: "Ears",
        icon: "inv-item-ears",
        itemId: -2
    },
    Glasses: {// 3 - Glasses
        slotId: 3,
        name: "очки",
        type: "Glasses",
        icon: "inv-item-glasses",
        itemId: -13
    },
    Accessories: {// 4 - Accessories
        slotId: 4,
        name: "аксессуары на шею",
        type: "Accessories",
        icon: "inv-item-necklace",
        itemId: -7
    },
    Tops: {// 5 - Tops
        slotId: 5,
        name: "верхняя одежда",
        type: "Tops",
        icon: "inv-item-jacket",
        itemId: -11
    },
    Undershirts: {// 6 - Undershirts
        slotId: 6,
        name: "нижняя одежда",
        type: "Undershirts",
        icon: "inv-item-shirt",
        itemId: -8
    },
    Armors: {// 7 - Armors
        slotId: 7,
        name: "бронежилет",
        type: "Armors",
        icon: "inv-item-armor",
        itemId: -9
    },
    Bags: {// 8 - Bags
        slotId: 8,
        name: "на спину",
        type: "Bags",
        icon: "inv-item-backpack",
        itemId: -5
    },
    Legs: {// 9 - Legs
        slotId: 9,
        name: "штаны",
        type: "Legs",
        icon: "inv-item-shorts",
        itemId: -4
    },
    Bracelets: {// 10 - Bracelets
        slotId: 10,
        name: "браслет",
        type: "Bracelets",
        icon: "inv-item-bracelet",
        itemId: -15
    },
    Watches: {// 11 - Watches
        slotId: 11,
        name: "часы",
        type: "Watches",
        icon: "inv-item-clock",
        itemId: -14
    },
    Torsos: {// 12 - Torsos
        slotId: 12,
        name: "перчатки",
        type: "Torsos",
        icon: "inv-item-glove",
        itemId: -3
    },
    Shoes: {// 13 - Shoes
        slotId: 13,
        name: "ботинки",
        type: "Shoes",
        icon: "inv-item-sneakers",
        itemId: -6
    },
    Suit: {// 14 - Suit
        slotId: 14,
        name: "украшения",
        type: "Suit",
        icon: "inv-item-headphones",
        itemId: -10
    }
}

export const clothesName = {
    "Masks_0": "Без маски",
    "Masks_1": "Свинья",
    "Masks_2": "Череп",
    "Masks_3": "Pogo",
    "Masks_4": "Хоккейная",
    "Masks_5": "Горилла",
    "Masks_6": "Карнавальная",
    "Masks_7": "Вампир",
    "Masks_8": "Санта-Клаус",
    "Masks_9": "Олень",
    "Masks_10": "Снеговик",
    "Masks_11": "Загадочная",
    "Masks_12": "Маскарадная",
    "Masks_13": "Купидон",
    "Masks_14": "Хоккейная простреленная",
    "Masks_15": "Хоккейная устрашающая",
    "Masks_16": "Воин",
    "Masks_17": "Кот",
    "Masks_18": "Лиса",
    "Masks_19": "Сова",
    "Masks_20": "Енот",
    "Masks_21": "Медведь",
    "Masks_22": "Бизон",
    "Masks_23": "Бык",
    "Masks_24": "Орёл",
    "Masks_25": "Стервятник",
    "Masks_26": "Волк",
    "Masks_27": "Пилот кукурузника",
    "Masks_28": "Тактическая",
    "Masks_29": "Череп устрашающий",
    "Masks_30": "Хоккейная маска хулигана",
    "Masks_31": "Пингвин",
    "Masks_32": "Носок",
    "Masks_33": "Весёлая печенька",
    "Masks_34": "Эльф",
    "Masks_35": "Балаклава №1",
    "Masks_36": "Респиратор №1",
    "Masks_37": "Балаклава №2",
    "Masks_38": "Противогаз №1",
    "Masks_39": "Плотоядный",
    "Masks_40": "Мумия",
    "Masks_41": "Пучеглазый вампир",
    "Masks_42": "Фрэнк",
    "Masks_43": "Impotent Rage",
    "Masks_44": "Princess Robot Bubblegum",
    "Masks_45": "Moorehead",
    "Masks_46": "Противогаз №2",
    "Masks_47": "Полицейская изолента",
    "Masks_48": "Изолента",
    "Masks_49": "Пакет",
    "Masks_50": "Лунная",
    "Masks_51": "Бандана №1",
"Masks_52": "Балаклава №3",
"Masks_53": "Балаклава №4",
"Masks_54": "Балаклава №5",
"Masks_55": "Балаклава №6",
"Masks_56": "Балаклава №7",
"Masks_57": "Балаклава №8",
"Masks_58": "Балаклава №9",
"Masks_59": "Снежный человек",
"Masks_60": "Злая тыква",
"Masks_61": "Зомби старик",
"Masks_62": "Восставший из пепла",
"Masks_63": "Анатомическая",
"Masks_64": "Хищник",
"Masks_65": "Оборотень",
"Masks_66": "Стрекоза",
"Masks_67": "Орк",
"Masks_68": "Дьявол",
"Masks_69": "Мешок",
"Masks_70": "Гипнотизер",
"Masks_71": "Баба-яга",
"Masks_72": "Люцифер",
"Masks_73": "Без маски",
"Masks_74": "Подгоревшая печенька",
"Masks_75": "Печенька в недоумении",
"Masks_76": "Санта с похмелья",
"Masks_77": "Ёлочка",
"Masks_78": "Кулич",
"Masks_79": "Йети",
"Masks_80": "Йети в кепке LS",
"Masks_81": "Йети с козырьком",
"Masks_82": "Йети с повязкой",
"Masks_83": "Йети с шапкой Санты",
"Masks_84": "Старый йети",
"Masks_85": "Курица",
"Masks_86": "Зубная фея",
"Masks_87": "Эльф с сигаретой",
"Masks_88": "Бабушка",
"Masks_89": "Тактическая",
"Masks_90": "Респиратор тактический",
"Masks_91": "Хай-тек",
"Masks_92": "Чудище",
"Masks_93": "Динозавр",
"Masks_94": "Дьявол с рожками",
"Masks_95": "Клоун",
"Masks_96": "Обезьяна",
"Masks_97": "Лошадь",
"Masks_98": "Единорог",
"Masks_99": "Череп с узорами",
"Masks_100": "Мопс",
    "Masks_101": 'Bigness',
    "Masks_102": "Испанская",
    "Masks_103": "Цифровой зомби",
    "Masks_104": "Лыжная",
    "Masks_105": "Ночной демон",
    "Masks_106": "Тактическая камуфяжная",
    "Masks_107": "Защитная камуфляжная",
    "Masks_108": "Получереп",
    "Masks_109": "Пилот кукурузника №2",
    "Masks_110": "Робот",
    "Masks_111": "Бандана №2",
    "Masks_112": "Рыло",
    "Masks_113": "Балаклава №10",
    "Masks_114": "Открытый шемаг",
    "Masks_115": "Закрытый шемаг",
    "Masks_116": "Шарф-труба",
    "Masks_117": "Балаклава №11",
    "Masks_118": "Футболка",
    "Masks_119": "Балаклава №12",
    "Masks_120": "Без маски",
    "Masks_121": "Гарнитура",
    "Masks_122": "Открытая балаклава",
    "Masks_123": "Цифровая",
    "Masks_124": "Электрический разряд",
    "Masks_125": "Защитная Mono",
    "Masks_126": "Укреплённая",
    "Masks_127": "Борода",
    "Masks_128": "Лунная",
    "Masks_129": "Индустриальный противогаз",
    "Masks_130": "Респиратор с зеркальными линзами",
    "Masks_131": "Крампус",
    "Masks_132": "Прибор ночного видения",
    "Masks_133": "Хоккейная с кастетами",
    "Masks_134": "Стар-трек",
    "Masks_135": "Шлем робота",
    "Masks_136": "Серый стервятник",
    "Masks_137": "Ловец",
    "Masks_138": "Налётчик",
    "Masks_139": "Зомби-зубоскал",
    "Masks_140": "Одноглазый монстр",
    "Masks_141": "Раскосый монстр",
    "Masks_142": "Мародер",
    "Masks_143": "Paco The Taco",
    "Masks_144": "Burger Shot",
    "Masks_145": "Cluckin' Bell",
    "Masks_146": "Оптика",
    "Masks_147": "Обезьяна с сигаретой",
    "Masks_148": "Робин",
    "Masks_149": "Клубника",
    "Masks_150": "Лимон",
    "Masks_151": "Виноград",
    "Masks_152": "Ананас",
    "Masks_153": "Вишня",
    "Masks_154": "Jackpot",
    "Masks_155": "Джокер",
    "Masks_156": "Король",
    "Masks_157": "Королева",
    "Masks_158": "Валет",
    "Masks_159": "Череп в шляпе",
    "Masks_160": "Детская улыбка",
    "Masks_161": "Маньяк",
    "Masks_162": "Свинтус",
    "Masks_163": "Пучеглазая обезьяна",
    "Masks_164": "Разбитый смайлик",
    "Masks_165": "Милый кролик",
    "Masks_166": "Респиратор уличный",
    "Masks_167": "Снайпер",
    "Masks_168": "Без кожи",
    "Masks_169": "Бандана №3",
    "Masks_170": "Наблюдатель",
    "Masks_171": "Лисичка из мультика",
    "Masks_172": "Котик из мультика",
    "Masks_173": "Свинка из мультика",
    "Masks_174": "Балаклава с улыбкой",
    "Masks_175": "Респиратор с прозрачными линзами",
    "Masks_176": "Собачка из мультика",
    "Masks_177": "Цербер",
    "Masks_178": "Балаклава с улыбкой",
    "Masks_179": "Эмоции",
    "Glasses_0_F": "Спортивные",
    "Glasses_1_F": "Ретро-стрекоза",
    "Glasses_2_F": "Ретро",
    "Glasses_3_F": "Защитные",
    "Glasses_4_F": "Ретро с тигровой оправой",
    "Glasses_5_F": "-",
    "Glasses_6_F": "Стрекоза с фиолетовыми линзами",
    "Glasses_7_F": "Восьмерка",
    "Glasses_8_F": "Квадратные с розовыми линзами",
    "Glasses_9_F": "Спортивные с зелеными линзами",
    "Glasses_10_F": "Летние",
    "Glasses_11_F": "Авиаторы",
    "Glasses_12_F": "-",
    "Glasses_13_F": "-",
    "Glasses_14_F": "Кошачий глаз",
    "Glasses_15_F": "-",
    "Glasses_16_F": "Стрекоза с коричневыми линзами",
    "Glasses_17_F": "Квадратные с фиолетовыми линзами",
    "Glasses_18_F": "Спортивные с темными прозрачными линзами",
    "Glasses_19_F": "Спортивные с темными линзами",
    "Glasses_20_F": "Квадратные затемненные",
    "Glasses_21_F": "Квадратные обычные",
    "Glasses_22_F": "Звёзды",
    "Glasses_23_F": "Патриотичные",
    "Glasses_24_F": "Квадратные темные",
    "Glasses_25_F": "Стрелковые в цветной оправе",
    "Glasses_26_F": "Винтажные",
    "Glasses_27_F": "Серферские",
    "Glasses_28_F": "Маска аквалангиста",
    "Glasses_29_F": "Крупные",
    "Glasses_30_F": "Хипстерские",
    "Glasses_31_F": "Роскошные",
    "Hats_0_F": "Защитные наушники",
    "Hats_1_F": "Бумажный колпак",
    "Hats_2_F": "Ковбойская шляпа",
    "Hats_3_F": "Панама лёгкая",
    "Hats_4_F": "Кепка LS",
    "Hats_5_F": "Шапка короткая",
    "Hats_6_F": "Шапка с козырьком",
    "Hats_7_F": "Кепка-хулиганка",
    "Hats_8_F": "Шляпка элегантная",
    "Hats_9_F": "Кепка iFruit",
    "Hats_10_F": "Бейсболка",
    "Hats_11_F": "Шляпка пляжная",
    "Hats_12_F": "Шапка свободная",
    "Hats_13_F": "Соломенная шляпка",
    "Hats_14_F": "Берет",
    "Hats_15_F": "Наушники Beat Off",
    "Hats_16_F": "Шлем Western MC",
    "Hats_17_F": "Открытый шлем",
    "Hats_18_F": "Закрытый мотоциклетный шлем",
    "Hats_19_F": "Наушники пилота",
    "Hats_20_F": "Шляпа соломенная волнистая",
    "Hats_21_F": "Панама садовая",
    "Hats_22_F": "Шляпка соломенная пляжная",
    "Hats_23_F": "Шапка Санта Клауса",
    "Hats_24_F": "Шапка и уши эльфа",
    "Hats_25_F": "Рожки",
    "Hats_26_F": "Котелок утонченный",
    "Hats_27_F": "Цилиндр утонченный",
    "Hats_28_F": "Шляпа детектива",
    "Hats_29_F": "Хулиганка",
    "Hats_30_F": "Шляпа патриот",
    "Hats_31_F": "Цилиндр патриота прямой",
    "Hats_32_F": "Цилиндр патриота волнистый",
    "Hats_33_F": "Шапка патриот",
    "Hats_34_F": "Диадема патриота",
    "Hats_35_F": "Патриотические звёзды",
    "Hats_36_F": "Кепка с пивом",
    "Hats_37_F": "Шлем пилота закрытый",
    "Hats_38_F": "Шлем с защитой открытый",
    "Hats_39_F": "Шапка 'Елочка'",
    "Hats_40_F": "Шапка с омелой",
    "Hats_41_F": "Шапка с бубоном длинная",
    "Hats_42_F": "Шапка с коротким бубоном",
    "Hats_43_F": "Кепка 'Naughty'",
    "Hats_44_F": "Кепка с козырьком назад",
    "Hats_45_F": "Полицейская фуражка",
    "Hats_46_F": "Шлем пилота закрытый",
    "Hats_47_F": "Внедорожный глянцевый шлем открытый",
    "Hats_48_F": "Внедорожный матовый шлем",
    "Hats_49_F": "Байкерский матовый шлем закрытый",
    "Hats_50_F": "Байкерский матовый зеркальный шлем",
    "Hats_51_F": "Байкерский глянцевый шлем",
    "Hats_52_F": "Байкерский глянцевый зеркальный шлем",
    "Hats_53_F": "Кепка FAM",
    "Hats_54_F": "Шляпа с тонкой вставкой",
    "Hats_55_F": "Кепка 'Broker'",
    "Hats_56_F": "Кепка 'Cagnetica'",
    "Hats_57_F": "Без головного убора",
    "Hats_58_F": "Кепка с тканевой вставкой",
    "Hats_59_F": "Пулестойкий шлем с защитой",
    "Hats_60_F": "Военная кепка",
    "Hats_61_F": "Шляпа с широкой вставкой",
    "Hats_62_F": "Мотоциклетный шлем",
    "Hats_63_F": "Военная кепка прямая",
    "Hats_64_F": "Кепка 'SecuroServ' с козырьком вперёд",
    "Hats_65_F": "Кепка 'SecuroServ' с козырьком назад",
    "Hats_66_F": "Открытый глянцевый мотоциклетный шлем",
    "Hats_67_F": "Открытый матовый мотоциклетный шлем",
    "Hats_68_F": "Открытый зеркальный мотоциклетный шлем",
    "Hats_69_F": "Открытый мотоциклетный шлем с глянцевым стеклом",
    "Hats_70_F": "Открытый матовый зеркальный шлем",
    "Hats_71_F": "Открытый мотоциклетный шлем №2",
    "Hats_72_F": "Закрытый ретро-шлем",
    "Hats_73_F": "Открытый ретро-шлем",
    "Hats_74_F": "Шлем Faggio",
    "Hats_75_F": "Кепка 'ATOMIC'",
    "Hats_76_F": "Кепка-бандитка",
    "Hats_77_F": "Закрытый шлем ДК",
    "Hats_78_F": "Открытый шлем ДК",
    "Hats_79_F": "Золотой закрытый шлем ДК",
    "Hats_80_F": "Золотой открытый шлем ДК",
    "Hats_81_F": "Закрытый мотоциклетный шлем",
    "Hats_82_F": "Шапка хлопковая",
    "Hats_83_F": "Каска с шипами",
    "Hats_84_F": "Полушлем",
    "Hats_85_F": "Полушлем с козырьком",
    "Hats_86_F": "Полушлем с длинными шипами",
    "Hats_87_F": "Полушлем с короткими шипами",
    "Hats_88_F": "Каска чугунная",
    "Hats_89_F": "Хромированная каска",
    "Hats_90_F": "Неоновый закрытый шлем",
    "Hats_91_F": "Неоновый открытый шлем",
    "Hats_92_F": "Каскадерский шлем",
    "Hats_93_F": "Панама прогулочная",
    "Hats_94_F": "Шляпа элегантная",
    "Hats_95_F": "Кепка 'Bigness'",
    "Hats_96_F": "Шапка 'Елочка'",
    "Hats_97_F": "Шапка с омелой'",
    "Hats_98_F": "Шапка с длинным бубоном",
    "Hats_99_F": "Шапка 'Новогодняя с ушами'",
    "Hats_100_F": "Рожки с красным бубоном",
    "Hats_101_F": "Кепка строгая",
    "Hats_102_F": "Кепка камуфляжная со вставкой",
    "Hats_103_F": "Панама камуфляжная",
    "Hats_104_F": "Шляпа камуфляжная ковбойская",
    "Hats_105_F": "Берет камуфляжный",
    "Hats_106_F": "Военная камуфляжная кепка",
    "Hats_107_F": "Камуфляжная шапка",
    "Hats_108_F": "Кепка H&L с козырьком вперед",
    "Hats_109_F": "Кепка H&L с козырьком назад",
    "Hats_110_F": "Шлем пилота самолёта",
    "Hats_111_F": "Каска камуфляжная с застёжкой",
    "Hats_112_F": "Фуражка военная",
    "Hats_113_F": "Пилотка",
    "Hats_114_F": "Шлем-маска",
    "Hats_115_F": "Шлем с 2 линзами №1",
    "Hats_116_F": "Шлем с 2 линзами №2",
    "Hats_117_F": "Шлем с 4 линзами №1",
    "Hats_118_F": "Шлем с 4 линзами №2",
    "Hats_119_F": "Шапка длинная",
    "Hats_120_F": "-",
    "Hats_121_F": "Кепка 'EMS'",
    "Hats_122_F": "Закрытый полицейский шлем",
    "Hats_123_F": "Открытый полицейский шлем",
    "Hats_124_F": "Закрытый полупрозрачный полицейский шлем",
    "Hats_125_F": "Открытый полупрозрачный полицейский шлем",
    "Hats_126_F": "Открытый прозрачный мотоциклетный шлем",
    "Hats_127_F": "Закрытый прозрачный мотоциклетный шлем",
    "Hats_128_F": "Шлем от скафандра",
    "Hats_129_F": "Кепка 'Burger Shot' с козырьком вперед",
    "Hats_130_F": "Кепка 'Burger Shot' с козырьком назад",
    "Hats_131_F": "Панама 'TacoBomb'",
    "Hats_132_F": "Шлем от скафандра №2",
    "Hats_133_F": "Шлем от скафандра №3",
    "Hats_134_F": "Кепка 'Diamond' с козырьком вперед",
    "Hats_135_F": "Кепка 'Diamond' с козырьком назад",
    "Hats_136_F": "Шлем 'LSFD'",
    "Hats_137_F": "Шлем 'LSFD' с очками",
    "Hats_138_F": "Кепка 'BugStars' с козырьком вперед",
    "Hats_139_F": "Кепка 'BugStats' с козырьком назад",
    "Hats_140_F": "Защитный шлем с очками",
    "Hats_141_F": "Кепка уличная",
    "Hats_142_F": "Шлем 'Gruppe Sechs'",
    "Hats_143_F": "Шлем строителя",
    "Hats_144_F": "Цилиндр аристократа",
    "Hats_145_F": "Закрытый прибор ночного видения",
    "Hats_146_F": "Открытый прибор ночного видения",
    "Hair_0_F": "Под ноль",
    "Hair_1_F": "Короткая с челкой направо",
    "Hair_2_F": "Короткая с челкой налево",
    "Hair_3_F": "Косички",
    "Hair_4_F": "Короткая с хвостиком",
    "Hair_5_F": "Кудряшка Сью",
    "Hair_6_F": "Дреды",
    "Hair_7_F": "Каре",
    "Hair_8_F": "Ястреб",
    "Hair_9_F": "Back to USSA",
    "Hair_10_F": "Лонг боб",
    "Hair_11_F": "Уложенный пучок",
    "Hair_12_F": "Пикси",
    "Hair_13_F": "Подбритые виски",
    "Hair_14_F": "Гейша",
    "Hair_15_F": "Волнистый боб",
    "Hair_16_F": "Красотка",
    "Hair_17_F": "Пучок",
    "Hair_18_F": "Флэппер боб",
    "Hair_19_F": "Тугой узел",
    "Hair_20_F": "Одуванчик",
    "Hair_21_F": "Взрыв",
    "Hair_22_F": "Узел с дредами",
    "Hair_23_F": "Деревенская",
    "Hair_24_F": "Прибор ночного видения",
    "Hair_25_F": "Сколотые косички",
    "Hair_26_F": "Косички-листья",
    "Hair_27_F": "Косички-зигзаги",
    "Hair_28_F": "Хвостики с челкой",
    "Hair_29_F": "Косички-волны",
    "Hair_30_F": "Косички-завитушки",
    "Hair_31_F": "Челка-валик",
    "Hair_32_F": "Растрепанный зачес назад",
    "Hair_33_F": "Подстриженный зачес назад",
    "Hair_34_F": "Подстриженный зачес набок",
    "Hair_35_F": "Шипастый ирокез",
    "Hair_36_F": "Бандана и косички",
    "Hair_37_F": "Челси",
    "Hair_38_F": "Стиляга",
    "Hair_39_F": "Короткая с челкой направо",
    "Hair_40_F": "Короткая с челкой налево",
    "Hair_41_F": "Косички",
    "Hair_42_F": "Короткая с хвостиком",
    "Hair_43_F": "Кудряшка Сью",
    "Hair_44_F": "Дреды",
    "Hair_45_F": "Каре",
    "Hair_46_F": "Ястреб",
    "Hair_47_F": "Back to USSA",
    "Hair_48_F": "Лонг боб",
    "Hair_49_F": "Уложенный пучок",
    "Hair_50_F": "Пикси",
    "Hair_51_F": "Подбритые виски",
    "Hair_52_F": "Гейша",
    "Hair_53_F": "Волнистый боб",
    "Hair_54_F": "Красотка",
    "Hair_55_F": "Пучок",
    "Hair_56_F": "Флэппер боб",
    "Hair_57_F": "Тугой узел",
    "Hair_58_F": "Одуванчик",
    "Hair_59_F": "Взрыв",
    "Hair_60_F": "Узел с дредами",
    "Hair_61_F": "Деревенская",
    "Hair_62_F": "Сколотые косички",
    "Hair_63_F": "Косички-листья",
    "Hair_64_F": "Косички-зигзаги",
    "Hair_65_F": "Хвостики с челкой",
    "Hair_66_F": "Косички-волны",
    "Hair_67_F": "Косички-завитки",
    "Hair_68_F": "Челка-валик",
    "Hair_69_F": "Растрепанный зачес назад",
    "Hair_70_F": "Подстриженный зачес назад",
    "Hair_71_F": "Подстриженный зачес набок",
    "Hair_72_F": "Шипастый ирокез",
    "Hair_73_F": "Бандана и косички",
    "Hair_74_F": "Челси",
    "Hair_75_F": "Стиляга",
    "Hair_76_F": "Уложенная",
    "Hair_77_F": "Челка на бок",
    "Hair_78_F": "Модная",
    "Bracelets_0_F": "Золотой браслет-змейка",
    "Bracelets_1_F": "Бриллиантовый браслет",
    "Bracelets_2_F": "Золотой плоский браслет",
    "Bracelets_3_F": "Золотой браслет 'Le Chien'",
    "Bracelets_4_F": "Золотой браслет",
    "Bracelets_5_F": "Золотой ажурный браслет",
    "Bracelets_6_F": "Золотой рельефный браслет",
    "Bracelets_7_F": "Легкий браслет",
    "Bracelets_8_F": "Тяжелый браслет",
    "Bracelets_9_F": "Прямоугольный браслет",
    "Bracelets_10_F": "Браслет с черепами",
    "Bracelets_11_F": "Широкий браслет",
    "Bracelets_12_F": "Цепочки с черепами",
    "Bracelets_13_F": "Браслет с шипами",
    "Bracelets_14_F": "Браслет",
    "Watches_0_F": "-",
    "Watches_1_F": "-",
    "Watches_2_F": "Стильные",
    "Watches_3_F": "CaCA Di Lusso",
    "Watches_4_F": "Didier Sachs Mignon",
    "Watches_5_F": "iFruit Link",
    "Watches_6_F": "iFruit Tech",
    "Watches_7_F": "Le Chien Marquise",
    "Watches_8_F": "Fufu Jeunesse",
    "Watches_9_F": "Anna Rex Prestige",
    "Watches_10_F": "iFruit Snap",
    "Watches_11_F": "Серебряный браслет плетёный",
    "Watches_12_F": "Серебряный браслет с толстым плетением",
    "Watches_13_F": "Серебряный браслет со вставками",
    "Watches_14_F": "Серебряный браслет с жемчужинами",
    "Watches_15_F": "Серебряный браслет широкий",
    "Watches_16_F": "Браслет с бусинами",
    "Watches_17_F": "Браслет с шипами",
    "Watches_18_F": "Напульсник",
    "Watches_19_F": "Классические" ,
    "Watches_20_F": "Деловые",
    "Watches_21_F": "Статусные",
    "Watches_22_F": "Классические серебряные",
    "Watches_23_F": "Компактные классические",
    "Watches_24_F": "Механические",
    "Watches_25_F": "Повседневные",
    "Watches_26_F": "Крупные классические",
    "Watches_27_F": "SASS",
    "Ears_0_F": "Гарнитура №1",
    "Ears_1_F": "Гарнитура №2",
    "Ears_2_F": "Гарнитура №3",
    "Ears_3_F": "Платиновые маятники",
    "Ears_4_F": "Золотые маятники",
    "Ears_5_F": "С круглым алмазом",
    "Ears_6_F": "Ромбы",
    "Ears_7_F": "Каскады",
    "Ears_8_F": "Тотемы",
    "Ears_9_F": "Цепочки с алмазом",
    "Ears_10_F": "Цепочки с изумрудом",
    "Ears_11_F": "Солнечные капли",
    "Ears_12_F": "Серьги с алмазом",
    "Ears_13_F": "Зеленые серьги-кольца",
    "Ears_14_F": "Толстые серьги-кольца",
    "Ears_15_F": "Классические серьги-кольца",
    "Ears_16_F": "Серьги 'F'",
    "Ears_17_F": "Брендовые Серьги'",
    "Ears_18_F": "Золотые серьги",
    "Ears_19_F": "Серьги 'Карты'",
    "Ears_20_F": "Серьги 'Игральные кубики'",
    "Ears_21_F": "Серьги 'Фишка'",
	"Hats_0_M": "Защитные наушники",
	"Hats_1_M": "Бумажный колпак",
	"Hats_2_M": "Шапка гангстера",
	"Hats_3_M": "Панама свободная",
	"Hats_4_M": "Кепка LS",
	"Hats_5_M": "Шапка молодежная",
	"Hats_6_M": "Кепка ностальгия",
	"Hats_7_M": "Кепка-хулиганка",
	"Hats_8_M": "-",
	"Hats_9_M": "Кепка шахматная козырьком назад",
	"Hats_10_M": "Кепка шахматная козырьком вперёд",
	"Hats_11_M": "-",
	"Hats_12_M": "Шляпа мафиозника",
	"Hats_13_M": "Ковбойская шляпа",
	"Hats_14_M": "Бандана",
	"Hats_15_M": "Наушники Beat Off",
	"Hats_16_M": "Шлем Western MC",
	"Hats_17_M": "Открытый шлем",
	"Hats_18_M": "Закрытый мотоциклетный шлем",
	"Hats_19_M": "Лётная гарнитура",
	"Hats_20_M": "Лесная панама",
	"Hats_21_M": "Мексиканская шляпа",
	"Hats_22_M": "Новогодняя шапка",
	"Hats_23_M": "Шапка и уши эльфа",
	"Hats_24_M": "Рожки",
	"Hats_25_M": "Шляпа Крёстный отец",
	"Hats_26_M": "Котелок",
	"Hats_27_M": "Цилиндр фокусника",
	"Hats_28_M": "Шапка фрилансера",
	"Hats_29_M": "Шляпка элегантная",
	"Hats_30_M": "Шляпа Дон Жуан",
	"Hats_31_M": "Шляпа 'Патриот'",
	"Hats_32_M": "Цилиндр патриота",
	"Hats_33_M": "Цилиндр патриота высокий",
	"Hats_34_M": "Шапка патриота",
	"Hats_35_M": "Украшение начинающего патриота",
	"Hats_36_M": "Рожки патриота",
	"Hats_37_M": "Кепка с пивом",
	"Hats_38_M": "Шлем настоящего пилота",
	"Hats_39_M": "Пулестойкий шлем",
	"Hats_40_M": "Шапка 'Елочка'",
	"Hats_41_M": "Шапка Вишенка на торте",
	"Hats_42_M": "Шапка Эльфа",
	"Hats_43_M": "Шапка домашняя",
	"Hats_44_M": "Кепка 'Naughty'",
	"Hats_45_M": "Кепка 'Naughty' с козырьком назад",
	"Hats_46_M": "Полицейская фуражка",
	"Hats_47_M": "Затемненный шлеп пилота",
	"Hats_48_M": "Внедорожный глянцевый шлем",
	"Hats_49_M": "Внедорожный матовый шлем",
	"Hats_50_M": "Байкерский матовый шлем",
	"Hats_51_M": "Мотоциклетный матовый зеркальный шлем",
	"Hats_52_M": "Мотоциклетный глянцевый шлем",
	"Hats_53_M": "Байкерский глянцевый зеркальный шлем",
	"Hats_54_M": "Кепка FAM",
	"Hats_55_M": "Кепка 'Broker'",
	"Hats_56_M": "Кепка 'Cagnetica'",
	"Hats_57_M": "Астронавт",
	"Hats_58_M": "Кепка с тканевой вставкой",
	"Hats_59_M": "Открытый шлем с защитой",
	"Hats_60_M": "Военная кепка с козырьком", 
	"Hats_61_M": "Шляпа с ленточкой",
	"Hats_62_M": "Мотоциклетный шлем закрытый",
	"Hats_63_M": "Военная кепка зимняя",
	"Hats_64_M": "Шляпа в клеточку",
	"Hats_65_M": "Кепка 'SecuroServ'",
	"Hats_66_M": "Кепка 'SecuroServ' с козырьком назад",
	"Hats_67_M": "Открытый глянцевый мотоциклетный шлем",
	"Hats_68_M": "Открытый матовый мотоциклетный шлем",
	"Hats_69_M": "Открытый зеркальный мотоциклетный шлем",
	"Hats_70_M": "Открытый мотоциклетный шлем",
	"Hats_71_M": "Открытый матовый зеркальный шлем",
	"Hats_72_M": "Открытый мотоциклетный шлем",
	"Hats_73_M": "Закрытый ретро-шлем",
	"Hats_74_M": "Открытый ретро-шлем",
	"Hats_75_M": "Шлем Faggio",
	"Hats_76_M": "Кепка 'ATOMIC'",
	"Hats_77_M": "Кепка 'На стиле'",
	"Hats_78_M": "Закрытый шлем ДК",
	"Hats_79_M": "Открытый шлем ДК",
	"Hats_80_M": "Золотой закрытый шлем ДК",
	"Hats_81_M": "Золотой открытый шлем ДК",
	"Hats_82_M": "Закрытый мотоциклетный шлем",
	"Hats_83_M": "Шапка джинсовая",
	"Hats_84_M": "Каска с шипами",
	"Hats_85_M": "Байкерский полушлем",
	"Hats_86_M": "Байкерский полушлем с козырьком",
	"Hats_87_M": "Байкерский полушлем с длинными шипами",
	"Hats_88_M": "Байкерский полушлем с шипами",
	"Hats_89_M": "Каска боевая",
	"Hats_90_M": "Хромированная каска",
	"Hats_91_M": "Неоновый закрытый шлем",
	"Hats_92_M": "Неоновый открытый шлем",
	"Hats_93_M": "Каскадерский шлем",
	"Hats_94_M": "Панама лёгкая",
	"Hats_95_M": "Шляпа с широкой вставкой",
	"Hats_96_M": "Кепка 'Bigness'",
	"Hats_97_M": "Шапка 'Елочка'",
	"Hats_98_M": "Шапка с омелой",
	"Hats_99_M": "Шапка 'Новогодняя' с длинным бубоном",
	"Hats_100_M": "Шапка 'Новогодняя с ушами'",
	"Hats_101_M": "Рожки",
    	"Hats_102_M": "Кепка обыкновенная",
    	"Hats_103_M": "Кепка камуфляжная",
    	"Hats_104_M": "Панама камуфляжная ",
    	"Hats_105_M": "Шляпа ковбойская камуфляжная",
    	"Hats_106_M": "Берет камуфляжная",
    	"Hats_107_M": "Военная командирская кепка",
    	"Hats_108_M": "Камуфляжная шапка с козырьком",
    	"Hats_109_M": "Кепка H&L с козырьком вперед",
    	"Hats_110_M": "Кепка H&L с козырьком назад",
    	"Hats_111_M": "Шлем летчика",
    	"Hats_112_M": "Военная каска",
    	"Hats_113_M": "Генеральская фуражка",
    	"Hats_114_M": "Пилотка",
    	"Hats_115_M": "Шлем-маска",
    	"Hats_116_M": "Шлем с 2 линзами №1",
    	"Hats_117_M": "Шлем с 2 линзами №2",
    	"Hats_118_M": "Шлем с 4 линзами №1",
    	"Hats_119_M": "Шлем с 4 линзами №2",
    	"Hats_120_M": "Шапка длинная",
    	"Hats_121_M": "-",
    	"Hats_122_M": "Кепка EMS",
    	"Hats_123_M": "Закрытый полицейский шлем",
    	"Hats_124_M": "Открытый полицейский шлем",
    	"Hats_125_M": "Закрытый полупрозрачный полицейский шлем",
    	"Hats_126_M": "Открытый полупрозрачный полицейский шлем",
    	"Hats_127_M": "Открытый прозрачный мотоциклетный шлем",
    	"Hats_128_M": "Закрытый прозрачный мотоциклетный шлем",
    	"Hats_129_M": "Шлем от скафандра",
    	"Hats_130_M": "Кепка 'Burger Shot', с козырьком вперед",
    	"Hats_131_M": "Кепка 'Burger Shot', с козырьком назад",
    	"Hats_132_M": "Панама 'TacoBomb'",
    	"Hats_133_M": "Шлем от скафандра космонавта",
    	"Hats_134_M": "Шлем от скафандра подводника",
    	"Hats_135_M": " Кепка 'Diamond', с козырьком вперед",
    	"Hats_136_M": " Кепка 'Diamond', с козырьком назад",
    	"Hats_137_M": "Шлем 'LSFD'",
    	"Hats_138_M": "Шлем 'LSFD', с очками",
    	"Hats_139_M": "Кепка 'BugStars', с козырьком вперед",
    	"Hats_140_M": "Кепка 'BugStats', с козырьком назад",
    	"Hats_141_M": "Защитный шлем с очками",
    	"Hats_142_M": "Кепка сочная",
    	"Hats_143_M": "Кепка сочная с козырьком назад",
    	"Hats_144_M": "Шлем 'Gruppe Sechs'",
    	"Hats_145_M": "Шлем строителя",
    	"Hats_146_M": "Цилиндр аристократа",
    	"Hats_147_M": "Закрытый прибор ночного видения",
    	"Hats_148_M": "Открытый прибор ночного видения",
	"Hair_0_M": "Лысый",
	"Hair_1_M": "Короткая стрижка",
	"Hair_2_M": "Канадка",
	"Hair_3_M": "Андеркат",
	"Hair_4_M": "Классическая",
	"Hair_5_M": "Агент 007",
	"Hair_6_M": "Стильный кок",
	"Hair_7_M": "Классическая",
	"Hair_8_M": "Дреды назад",
	"Hair_9_M": "Самец",
	"Hair_10_M": "Обыденность",
	"Hair_11_M": "Высокий квифф",
	"Hair_12_M": "Полубокс",
	"Hair_13_M": "Боб мужской",
	"Hair_14_M": "Дреды",
	"Hair_15_M": "Иисус",
	"Hair_16_M": "Брутальные кудри",
	"Hair_17_M": "Градуированное каре",
	"Hair_18_M": "Интеллигент",
	"Hair_19_M": "Обычная",
	"Hair_20_M": "Романтичный шик",
	"Hair_21_M": "Итальянский мачо",
	"Hair_22_M": "Деревенская",
	"Hair_23_M": "Прибор ночного видения",
	"Hair_24_M": "Дреды-ми",
	"Hair_25_M": "Джа-дреды",
	"Hair_26_M": "Опасные дреды",
	"Hair_27_M": "Ля-дреды",
	"Hair_28_M": "Безопасные дреды",
	"Hair_29_M": "До-дреды",
	"Hair_30_M": "Высокая площадка",
	"Hair_31_M": "Стиль",
	"Hair_32_M": "Квифф",
	"Hair_33_M": "Андеркат на бок",
	"Hair_34_M": "Ирокез",
	"Hair_35_M": "Растрепанный",
	"Hair_36_M": "Удлиненная",
	"Hair_37_M": "Милитари",
	"Hair_38_M": "Канадка",
	"Hair_39_M": "Андеркат",
	"Hair_40_M": "Классическая",
	"Hair_41_M": "Агент 007",
	"Hair_42_M": "Стильный кок",
	"Hair_43_M": "Настоящий классик",
	"Hair_44_M": "Дреды назад",
	"Hair_45_M": "Самец",
	"Hair_46_M": "Обыденность",
	"Hair_47_M": "Высокий квифф",
	"Hair_48_M": "Полубокс",
	"Hair_49_M": "Боб мужской",
	"Hair_50_M": "Дреды",
	"Hair_51_M": "Иисус",
	"Hair_52_M": "Брутальные кудри",
	"Hair_53_M": "Градуированное каре",
	"Hair_54_M": "Интеллигент",
	"Hair_55_M": "Обычная",
	"Hair_56_M": "Романтичный шик",
	"Hair_57_M": "Итальянский мачо",
	"Hair_58_M": "Деревенская",
	"Hair_59_M": "Дреды-ми",
	"Hair_60_M": "Джа-дреды",
	"Hair_61_M": "Опасные дреды",
	"Hair_62_M": "Ля-дреды",
	"Hair_63_M": "Безопасные дреды",
	"Hair_64_M": "До-дреды",
	"Hair_65_M": "Высокая площадка",
	"Hair_66_M": "Стиль",
	"Hair_67_M": "Квифф",
	"Hair_68_M": "Андеркат на бок",
	"Hair_69_M": "Ирокез",
	"Hair_70_M": "Растрепанный",
	"Hair_71_M": "Удлиненная",
	"Hair_72_M": "Площадка",
	"Hair_73_M": "Армейская",
	"Hair_74_M": "Элвис",
	"Shoes_0_M": "Ботинки высокие шахматные",
    	"Shoes_1_M": "Кеды черные низкие",
    	"Shoes_2_M": "Ботинки низкие шахматные",
    	"Shoes_3_M": "Мокасины кожаные",
    	"Shoes_4_M": "Кеды синие высокие",
    	"Shoes_5_M": "Вьетнамки с узкой вставкой",
    	"Shoes_6_M": "Черные тапочки с носками",
    	"Shoes_7_M": "Спортивные кроссовки",
    	"Shoes_8_M": "Кроссовки для активного отдыха",
    	"Shoes_9_M": "Кроссовки Nike",
    	"Shoes_10_M": "Туфли деловые",
    	"Shoes_11_M": "Туфли шахматные",
    	"Shoes_12_M": "Ботинки зимние",
    	"Shoes_13_M": "-",
    	"Shoes_14_M": "Ботинки прошитые",
    	"Shoes_15_M": "Высокие ботинки",
    	"Shoes_16_M": "Вьетнамки походные",
    	"Shoes_17_M": "Ботинки эльфа",
    	"Shoes_18_M": "Туфли двухцветные",
    	"Shoes_19_M": "Туфли для чечетки",
    	"Shoes_20_M": "Туфли с носками",
    	"Shoes_21_M": "Туфли бархатные",
    	"Shoes_22_M": "Кеды высокие",
    	"Shoes_23_M": "Кроссовки двухцветные",
    	"Shoes_24_M": "Берцы",
    	"Shoes_25_M": "Берцы армейские",
    	"Shoes_26_M": "Кеды высокие",
    	"Shoes_27_M": "Берцы работника",
    	"Shoes_28_M": "Высокие кроссовки с шипами",
    	"Shoes_29_M": "Кроссовки тканевые",
    	"Shoes_30_M": "Мокасины двухцветные",
    	"Shoes_31_M": "Кроссовки беговые",
    	"Shoes_32_M": "Кроссовки высокие двухцветные",
    	"Shoes_33_M": "-",
    	"Shoes_34_M": "-",
    	"Shoes_35_M": "Берцы со шнурками",
    	"Shoes_36_M": "Мокасины стильные",
    	"Shoes_37_M": "Ковбойские сапоги ",
    	"Shoes_38_M": "Ковбойские туфли",
    	"Shoes_39_M": "Сапоги",
    	"Shoes_40_M": "Туфли деловые с носками",
    	"Shoes_41_M": "Лоферы",
    	"Shoes_42_M": "Кроссовки прогулочные",
    	"Shoes_43_M": "Ботинки модные",
    	"Shoes_44_M": "Ковбойские сапоги с застежкой",
    	"Shoes_45_M": "Ковбойские туфли с застежкой",
    	"Shoes_46_M": "Кеды уличные",
    	"Shoes_47_M": "Кеды уличные",
    	"Shoes_48_M": "Кеды высокие черные",
    	"Shoes_49_M": "Кеды высокие желтые",
    	"Shoes_50_M": "Ботинки байкера высокие",
	"Shoes_51_M": "Броги",
    	"Shoes_52_M": "Ботинки фермера",
    	"Shoes_53_M": "Панки",
    	"Shoes_54_M": "Оксфорды",
    	"Shoes_55_M": "Кроссовки с неоновыми полосками",
    	"Shoes_56_M": "Ботинки утеплённые ",
    	"Shoes_57_M": "Кроссовки с воротничком",
    	"Shoes_58_M": "Неоновые тапки",
    	"Shoes_59_M": "Камуфляжные кроссовки",
    	"Shoes_60_M": "Ботинки зимние высокие",
    	"Shoes_61_M": "Утеплённые кроссовки",
    	"Shoes_62_M": "Берцы утеплённые",
    	"Shoes_63_M": "Кроссовки военные",
    	"Shoes_64_M": "Кроссовки на липучках ",
    	"Shoes_65_M": "Ботинки зимние удлиненные",
    	"Shoes_66_M": "Ботинки зимние укороченные ",
    	"Shoes_67_M": "Ласты",
    	"Shoes_68_M": "Тапочки 'Матрица'",
    	"Shoes_69_M": "Резиновые ботинки",
    	"Shoes_70_M": "Рыбацкие ботинки высокие",
    	"Shoes_71_M": "Рыбацкие ботинки укороченные",
    	"Shoes_72_M": "Зимние ботинки 'Дутики'",
    	"Shoes_73_M": "Зимние кроссовки 'Дутики'",
    	"Shoes_74_M": "Кроссовки с липучками с воротничком",
    	"Shoes_75_M": "Кроссовки демисезонные с воротничком",
    	"Shoes_76_M": "Кроссовки демисезонные",
    	"Shoes_77_M": "Светящиеся кроссовки",
    	"Shoes_78_M": "Обувь от костюма 'Робот'",
    	"Shoes_79_M": "Байкерские ботинки",
    	"Shoes_80_M": "Укороченные байкерские ботинки",
    	"Shoes_81_M": "Зимние утеплённые ботинки со шнурками",
    	"Shoes_82_M": "Короткие зимние утеплённые ботинки со шнурками",
    	"Shoes_83_M": "Носки 'Следочки'",
    	"Shoes_84_M": "Рыцарские ботинки высокие",
    	"Shoes_85_M": "Рыцарские ботинки укороченные ",
    	"Shoes_86_M": "Рыцарские ботинки",
    	"Shoes_87_M": "Клоунские сапоги",
    	"Shoes_88_M": "Сапожки 'Снегурочка'",
    	"Shoes_89_M": "Зимние кроссовки",
    	"Shoes_90_M": "Ботинки зимние лыжные",
    	"Shoes_91_M": "Ботинки супер-героя",
    	"Shoes_92_M": "Мокасины с рисунком",
    	"Shoes_93_M": "Кроссовки мужские зимние",
		"Shoes_95_M": "Мокасины прогулочные",
		"Shoes_96_M": "Мертвые Найки",
		"Shoes_97_M": "Уличные кроссовки",
		"Shoes_98_M": "Баскетбольные кроссовки",
		"Shoes_99_M": "Спортивные кроссовки",
		"Shoes_100_M": "Спортивные Найки",
		"Shoes_101_M": "Найки для зала",
		"Shoes_102_M": "Кожаные туфли",
		"Shoes_103_M": "Модные кроссовки",
		"Shoes_104_M": "Повседневные кроссовки",
    	"Shoes_95_M": "Мокасины прогулочные",
	"Shoes_0_F": "Туфли на высоком каблуке",
    	"Shoes_1_F": "Спортивные кеды",
    	"Shoes_2_F": "Зимние ботинки",
   	 "Shoes_3_F": "Кеды",
    "Shoes_4_F": "Кожаные кеды",
    "Shoes_5_F": "Сланцы",
    "Shoes_6_F": "Туфли с острым носом",
    "Shoes_7_F": "Ботинки на каблуке",
    "Shoes_8_F": "Туфли на каблуке с открытым носом",
    "Shoes_9_F": "Женские берцы",
    "Shoes_10_F": "АирКроссы", 
    "Shoes_11_F": "Кроссовки для бега",
    "Shoes_12_F": "-",
    "Shoes_13_F": "Балетки",
    "Shoes_14_F": "Кокетка",
    "Shoes_15_F": "Сандали",
    "Shoes_16_F": "Вьетнамки летние",
    "Shoes_17_F": "Ботинки эльфа",
    "Shoes_18_F": "Туфли офисные",
    "Shoes_19_F": "Туфли клубные",
    "Shoes_20_F": "Туфли леопардовые",
    "Shoes_21_F": "Высокие сапоги",
    "Shoes_22_F": "Туфли с мехом на каблуке",
    "Shoes_23_F": "Туфли ночные",
    "Shoes_24_F": "Берцы строгие",
    "Shoes_25_F": "Берцы армейские",
    "Shoes_26_F": "Берцы сварочные",
    "Shoes_27_F": "Кроссовки лёгкие",
    "Shoes_28_F": "Кроссовки лёгкие с символикой",
    "Shoes_29_F": "Классические ботинки",
    "Shoes_30_F": "Туфли на каблуке с застежкой",
    "Shoes_31_F": "Кроссовки на липучках",
    "Shoes_32_F": "Кроссовки модные",
    "Shoes_33_F": "Кеды с утеплителем",
    "Shoes_34_F": "-",
    "Shoes_35_F": "-",
    "Shoes_36_F": "Берцы кожаные",
    "Shoes_37_F": "Мокасины с цепочкой",
    "Shoes_38_F": "Сапоги ковбойские",
    "Shoes_39_F": "Туфли ковбойские",
    "Shoes_40_F": "Сапоги замшевые",
    "Shoes_41_F": "Туфли на тонких шпильках с липучкой",
    "Shoes_42_F": "Туфли на тонких шпильках",
    "Shoes_43_F": "Туфли с высоким каблуком",
    "Shoes_44_F": "Туфли пчёлки",
    "Shoes_45_F": "Сапоги Дикий запад ",
    "Shoes_46_F": "Туфли Дикий запад",
    "Shoes_47_F": "Кроссовки для борьбы",
    "Shoes_48_F": "Защитные ботинки для борьбы",
    "Shoes_49_F": "Кеды для бега",
    "Shoes_50_F": "Кеды для пробежки",
    "Shoes_51_F": "Сапоги конные",
    "Shoes_52_F": "Ботинки фермера",
    "Shoes_53_F": "Сапоги фермера",
    "Shoes_54_F": "Сапоги на молнии",
    "Shoes_55_F": "Туфли на молнии",
    "Shoes_56_F": "Ковбойские сапоги черные",
    "Shoes_57_F": "Ковбойские туфли черные",
    "Shoes_58_F": "Кроссовки с неоновыми полосками",
    "Shoes_59_F": "Деревенские туфли",
    "Shoes_60_F": "Стильные кеды",
    "Shoes_61_F": "Светящиеся тапочки",
    "Shoes_62_F": "Камуфляжные кроссовки",
    "Shoes_63_F": "Берцы зимние",
    "Shoes_64_F": "Кроссовки зимние",
    "Shoes_65_F": "Военные берцы",
    "Shoes_66_F": "Военные кроссовки",
    "Shoes_67_F": "Кроссовки с воротничком",
    "Shoes_68_F": "Ботинки зимние",
    "Shoes_69_F": "Ботинки меховые",
    "Shoes_70_F": "Ласты",
    "Shoes_71_F": "Тапочки 'Матрица'",
    "Shoes_72_F": "Ботинки ковбойские",
    "Shoes_73_F": "Защитные ботинки высокие",
    "Shoes_74_F": "Защитные ботинки",
    "Shoes_75_F": "Защитные туфли высокие",
    "Shoes_76_F": "Защитные туфли",
    "Shoes_77_F": "Высокие ботинки",
    "Shoes_78_F": "Беговые кроссовки",
    "Shoes_79_F": "Кроссовки походные с воротничком",
    "Shoes_80_F": "Кроссовки походные",
    "Shoes_81_F": "Светящиеся кроссовки",
    "Shoes_82_F": "Кеды беговые",
    "Shoes_83_F": "Огненные сапоги",
    "Shoes_84_F": "Огненные ботинки",
    "Shoes_85_F": "Заводские сапоги",
    "Shoes_86_F": "Заводские ботинки",
    "Shoes_87_F": "Носочки",
    "Shoes_88_F": "Ботинки рыцарские высокие",
    "Shoes_89_F": "Деревенские рыцарские",
    "Shoes_90_F": "Деревенские ботинки",
    "Shoes_91_F": "Ботинки клоунские",
    "Shoes_92_F": "Ботинки снегурочки",
    "Shoes_93_F": "Кроссовки беговые с воротничком",
    "Shoes_94_F": "Ботинки от костюма 'Астронавта'",
    "Shoes_95_F": "Ботинки супер-героя",
    "Shoes_96_F": "Баскетбольные кроссовки с воротничком",
    "Shoes_97_F": "Баскетбольные кроссовки",
    "Shoes_98_F": "Новогодние кеды",
    "Shoes_99_F": "Легкие кеды",
    "Accessories_0_F": "-",
    "Accessories_1_F": "Серьги золотые",
    "Accessories_2_F": "Серьги золотые №2",
    "Accessories_3_F": "Черная повязка на руку",
    "Accessories_4_F": "Черно-белые браслеты",
    "Accessories_5_F": "Повязка на руку",
    "Accessories_6_F": "Кулон золотой",
    "Accessories_7_F": "Цепочка с сердечком",
    "Accessories_8_F": "-",
    "Accessories_9_F": "Шарф белый",
    "Accessories_10_F": "Браслеты на руку",
    "Accessories_11_F": "Цепочка с кулонами",
    "Accessories_12_F": "Жемчужное ожерелье и браслет",
    "Accessories_13_F": "Шарф в горошек",
    "Accessories_14_F": "Жемчужный браслет",
    "Accessories_15_F": "Шарф красный",
    "Accessories_16_F": "Фильтр от противогаза",
    "Accessories_17_F": "Шарф цветной",
    "Accessories_18_F": "Шарф цветной №2",
    "Accessories_19_F": "Бабочка",
    "Accessories_20_F": "Галстук",
    "Accessories_21_F": "Галстук №2",
    "Accessories_22_F": "Галстук №3",
    "Accessories_23_F": "Бабочка №2",
    "Accessories_24_F": "Шланг от противогаза",
    "Accessories_25_F": "Наручники",
    "Accessories_26_F": "Галстук №4",
    "Accessories_27_F": "Галстук №5",
    "Accessories_28_F": "Галстук №6",
    "Accessories_29_F": "Цепочка",
    "Accessories_30_F": "Цепочка с черепом",
    "Accessories_31_F": "Цепочка с черепом №2",
    "Accessories_32_F": "Цепочка с подвеской №1",
    "Accessories_33_F": "Цепочка с подвеской №2",
    "Accessories_34_F": "Цепочка с подвеской №3",
    "Accessories_35_F": "Цепочка с подвеской №5",
    "Accessories_36_F": "Цепочка с подвеской №6",
    "Accessories_37_F": "Цепочка с черепом №3",
    "Accessories_38_F": "Цепочка с маской",
    "Accessories_39_F": "Цепочка с подвеской №7",
    "Accessories_40_F": "Цепочка с подвеской №8",
    "Accessories_41_F": "Цепочка с подвеской №9",
    "Accessories_42_F": "Цепочка с подвеской №10",
    "Accessories_43_F": "-",
    "Accessories_44_F": "-",
    "Accessories_45_F": "-",
    "Accessories_46_F": "-",
    "Accessories_47_F": "-",
    "Accessories_48_F": "-",
    "Accessories_49_F": "-",
    "Accessories_50_F": "-",
	"Accessories_51_F": "-",
	"Accessories_52_F": "-",
	"Accessories_53_F": "Цепочка №2",
	"Accessories_54_F": "Цепочка №3",
	"Accessories_55_F": "Цепочка №4",
	"Accessories_56_F": "Цепочка №5",
	"Accessories_57_F": "Цепочка №6",
	"Accessories_58_F": "Цепочка №7",
	"Accessories_59_F": "Цепочка №8",
	"Accessories_60_F": "Цепочка №9",
	"Accessories_61_F": "Цепочка №10",
	"Accessories_62_F": "Цепочка №11",
	"Accessories_63_F": "-",
	"Accessories_64_F": "Цепочка №12",
	"Accessories_65_F": "Цепочка №13",
	"Accessories_66_F": "Цепочка №14",
	"Accessories_67_F": "Цепочка №15",
	"Accessories_68_F": "Цепочка №16",
	"Accessories_69_F": "Цепочка №17",
	"Accessories_70_F": "Цепочка №18",
	"Accessories_71_F": "Цепочка №19",
	"Accessories_72_F": "Цепочка №20",
	"Accessories_73_F": "Цепочка №21",
	"Accessories_74_F": "-",
	"Accessories_75_F": "-",
	"Accessories_76_F": "-",
	"Accessories_77_F": "-",
	"Accessories_78_F": "-",
	"Accessories_79_F": "-",
	"Accessories_80_F": "-",
	"Accessories_81_F": "Цепочка с подвеской №11",
	"Accessories_82_F": "Цепочка с подвеской №12",
	"Accessories_83_F": "Шарф №6",
	"Accessories_84_F": "Конский хвост",
	"Accessories_85_F": "Наушники",
	"Accessories_86_F": "Галстук и значок",
	"Accessories_87_F": "Галстук №7",
	"Accessories_88_F": "Рюкзак",
	"Accessories_89_F": "Цепочка с подвеской №13",
	"Accessories_90_F": "Цепочка с подвеской №14",
	"Accessories_91_F": "Цепочка с подвеской №15",
	"Accessories_92_F": "Цепочка с подвеской №16",
	"Accessories_93_F": "Конский хвост №2",
	"Accessories_94_F": "Наушники №2",
	"Accessories_95_F": "Бейдж и значок 'IAA'",
	"Accessories_96_F": "Фонендоскоп",
	"Accessories_97_F": "Бейдж 'Paramedic'",
	"Accessories_98_F": "Бейдж 'IAA'",
	"Accessories_99_F": "Цепочка с подвеской №17",
	"Accessories_100_F": "Цепочка с подвеской №18",
	"Accessories_101_F": "Цепочка с подвеской №19",
	"Accessories_102_F": "Дополнительные карманы",
	"Accessories_103_F": "Цепочка-колокольчик",
	"Accessories_104_F": "Цепочка-колокольчик №2",
	"Glasses_0_M": "-",
	"Glasses_1_M": "Полароид",
	"Glasses_2_M": "Ванс",
	"Glasses_3_M": "Кошачий взгляд",
	"Glasses_4_M": "Панто стильные",
	"Glasses_5_M": "Дерзкие броулайнеры",
	"Glasses_6_M": "-",
	"Glasses_7_M": "Темный вайфарер",
	"Glasses_8_M": "Авиатор",
	"Glasses_9_M": "Устремленный",
	"Glasses_10_M": "Современный",
	"Glasses_11_M": "Закрытые глаза",
	"Glasses_12_M": "Модный авиатор",
	"Glasses_13_M": "Модник",
	"Glasses_14_M": "-",
	"Glasses_15_M": "Спортивные с жёлтыми линзами",
	"Glasses_16_M": "Спортивные",
	"Glasses_17_M": "Серьезный броулайнер",
	"Glasses_18_M": "Тишейд",
	"Glasses_19_M": "Очки без линз",
	"Glasses_20_M": "Квадратные без затемнения",
	"Glasses_21_M": "Звезды",
	"Glasses_22_M": "Патриотичные",
	"Glasses_23_M": "Стрелковые 'Shooters' ",
	"Glasses_24_M": "Винтажные",
	"Glasses_25_M": "Urban Ski",
	"Glasses_26_M": "Маска аквалангиста",
	"Glasses_27_M": "Серферские",
	"Glasses_28_M": "Хипстерские",
	"Glasses_29_M": "Роскошные очки",
	"Accessories_0_M": "-",
	"Accessories_1_M": "-",
	"Accessories_2_M": "-",
	"Accessories_3_M": "-",
	"Accessories_4_M": "-",
	"Accessories_5_M": "-",
	"Accessories_6_M": "-",
	"Accessories_7_M": "-",
	"Accessories_8_M": "-",
	"Accessories_9_M": "-",
	"Accessories_10_M": "Галстук",
	"Accessories_11_M": "Бабочка",
	"Accessories_12_M": "Галстук №2",
	"Accessories_13_M": "-",
	"Accessories_14_M": "-",
	"Accessories_15_M": "-",
	"Accessories_16_M": "Цепочка",
	"Accessories_17_M": "Цепочка №2",
	"Accessories_18_M": "Галстук №3",
	"Accessories_19_M": "Галстук №4",
	"Accessories_20_M": "Галстук №5",
	"Accessories_21_M": "Галстук №6",
	"Accessories_22_M": "Бабочка №2",
	"Accessories_23_M": "Галстук №7",
	"Accessories_24_M": "Галстук №8",
	"Accessories_25_M": "Галстук №9",
	"Accessories_26_M": "Галстук №10",
	"Accessories_27_M": "Галстук №11",
	"Accessories_28_M": "Галстук №12",
	"Accessories_29_M": "Галстук №13",
	"Accessories_30_M": "Шарф",
	"Accessories_31_M": "Шарф №2",
	"Accessories_32_M": "Бабочка №3",
	"Accessories_33_M": "Шланг от противогаза",
	"Accessories_34_M": "Шарф №3",
	"Accessories_35_M": "Шарф №4",
	"Accessories_36_M": "Бабочка №4",
	"Accessories_37_M": "Галстук №14",
	"Accessories_38_M": "Галстук №15",
	"Accessories_39_M": "Галстук №16",
	"Accessories_40_M": "Шланг от противогаза",
	"Accessories_41_M": "Наручники",
	"Accessories_42_M": "Цепочка с подвеской",
	"Accessories_43_M": "Цепочка с подвеской 'Череп'",
	"Accessories_44_M": "Цепочка с подвеской 'Маска'",
	"Accessories_45_M": "Цепочка с подвеской №2",
	"Accessories_46_M": "Цепочка с подвеской №3",
	"Accessories_47_M": "Цепочка с подвеской №4",
	"Accessories_48_M": "Цепочка с подвеской №5",
	"Accessories_49_M": "Цепочка с подвеской №6",
	"Accessories_50_M": "Цепочка с подвеской 'Череп' №2",
	"Accessories_51_M": "Цепочка с подвеской 'Маска' №2",
	"Accessories_52_M": "Цепочка с подвеской №7",
	"Accessories_53_M": "Цепочка с подвеской №8",
	"Accessories_54_M": "Цепочка с подвеской №9",
	"Accessories_55_M": "Цепочка с подвеской №10",
	"Accessories_56_M": "-",
	"Accessories_57_M": "-",
	"Accessories_58_M": "-",
	"Accessories_59_M": "-",
	"Accessories_60_M": "-",
	"Accessories_61_M": "-",
	"Accessories_62_M": "-",
	"Accessories_63_M": "-",
	"Accessories_64_M": "-",
	"Accessories_65_M": "-",
	"Accessories_66_M": "-",
	"Accessories_67_M": "-",
	"Accessories_68_M": "-",
	"Accessories_69_M": "-",
	"Accessories_70_M": "-",
	"Accessories_71_M": "-",
	"Accessories_72_M": "-",
	"Accessories_73_M": "-",
	"Accessories_74_M": "Цепочка №3",
	"Accessories_75_M": "Цепочка №4",
	"Accessories_76_M": "Цепочка №5",
	"Accessories_77_M": "Цепочка №6",
	"Accessories_78_M": "Цепочка №7",
	"Accessories_79_M": "Цепочка №8",
	"Accessories_80_M": "Цепочка №9",
	"Accessories_81_M": "Цепочка №10",
	"Accessories_82_M": "Цепочка №11",
	"Accessories_83_M": "Цепочка №12",
	"Accessories_84_M": "-",
	"Accessories_85_M": "Цепочка №13",
	"Accessories_86_M": "Цепочка №14",
	"Accessories_87_M": "Цепочка №15",
	"Accessories_88_M": "Цепочка №16",
	"Accessories_89_M": "Цепочка №17",
	"Accessories_90_M": "Цепочка №18",
	"Accessories_91_M": "Цепочка №19",
	"Accessories_92_M": "Цепочка №20",
	"Accessories_93_M": "Цепочка №21",
	"Accessories_94_M": "Цепочка №22",
	"Accessories_95_M": "-",
	"Accessories_96_M": "-",
	"Accessories_97_M": "-",
	"Accessories_98_M": "-",
	"Accessories_99_M": "-",
	"Accessories_100_M": "-",
	"Accessories_101_M": "-",
	"Accessories_102_M": "-",
	"Accessories_103_M": "-",
	"Accessories_104_M": "-",
	"Accessories_105_M": "-",
	"Accessories_106_M": "-",
	"Accessories_107_M": "-",
	"Accessories_108_M": "-",
	"Accessories_109_M": "-",
	"Accessories_110_M": "Цепочка с подвеской №11",
	"Accessories_111_M": "Цепочка с подвеской №12",
	"Accessories_112_M": "Шарф №5",
	"Accessories_113_M": "Конский хвост",
	"Accessories_114_M": "Наушники",
	"Accessories_115_M": "Галстук №17",
	"Accessories_116_M": "Галстук №18",
	"Accessories_117_M": "Галстук №19",
	"Accessories_118_M": "Бабочка №5",
	"Accessories_119_M": "Цепочка с подвеской №13",
	"Accessories_120_M": "Цепочка с подвеской №14",
	"Accessories_121_M": "Цепочка с подвеской №15",
	"Accessories_122_M": "Цепочка с подвеской №16",
	"Accessories_123_M": "Конский хвост №2",
	"Accessories_124_M": "Наушники №2",
	"Accessories_125_M": "Бейджик и значок 'IAA'",
	"Accessories_126_M": "Фонендоскоп",
	"Accessories_127_M": "Бейджик 'Paramedic'",
	"Accessories_128_M": "Бейджик 'IAA'",
	"Accessories_129_M": "Цепочка с подвеской №17",
	"Accessories_130_M": "Цепочка с подвеской №18",
	"Accessories_131_M": "Цепочка с подвеской №19",
	"Accessories_132_M": "Галстук вокруг шеи",
	"Accessories_133_M": "Дополнительные карманы",
	"Accessories_134_M": "Цепочка-колокольчик",
	"Accessories_135_M": "Цепочка-колокольчик №2",
	"Bracelets_0_M": "Легкий браслет",
	"Bracelets_1_M": "Тяжелый браслет",
	"Bracelets_2_M": "Прямоугольный браслет",
	"Bracelets_3_M": "Браслет с черепами",
	"Bracelets_4_M": "Широкий браслет",
	"Bracelets_5_M": "Цепочки с черепами",
	"Bracelets_6_M": "Браслет с шипами",
	"Bracelets_7_M": "Браслет",
	"Tops_0_F": "Майка",
    "Tops_1_F": "Джинсовка с карманами",
    "Tops_2_F": "Футболка с оголенным плечом",
    "Tops_3_F": "Кофта с замком",
    "Tops_4_F": "Майка без плеч",
    "Tops_5_F": "Майка без пуза",
    "Tops_6_F": "Пиджак с двумя пуговицами",
    "Tops_7_F": "Деловой пиджак",
    "Tops_8_F": "Укороченная куртка из шерсти",
    "Tops_9_F": "Рубашка школьная",
    "Tops_10_F": "Олимпийка с полосами",
    "Tops_11_F": "Спортивная майка",
    "Tops_12_F": "Майка в клеточку",
    "Tops_13_F": "Корсет без плеч",
    "Tops_14_F": "Поло 'Крокодильчик'",
    "Tops_15_F": "-",
    "Tops_16_F": "Майка для дома",
    "Tops_17_F": "Рубашка пляжная",
    "Tops_18_F": "Купальник",
    "Tops_19_F": "Новогодняя футболка",
    "Tops_20_F": "Деловой пиджак с двумя пуговицами с вставкой",
    "Tops_21_F": "Платье деревенское",
    "Tops_22_F": "Корсет королевский",
    "Tops_23_F": "Футболка строгая",
    "Tops_24_F": "Деловой пиджак с двумя пуговицами офисный",
    "Tops_25_F": "Открытый пиджак офисный",
    "Tops_26_F": "Блузка фараонка",
    "Tops_27_F": "Рубашка с карманами",
    "Tops_28_F": "Жилетка парадная",
    "Tops_29_F": "Рубашка с отворотами",
    "Tops_30_F": "Футболка стянутая",
	"Tops_31_F": "Джинсовая куртка двухцветная",
    "Tops_32_F": "Майка Леопард",
    "Tops_33_F": "Топ белый",
    "Tops_34_F": "Открытый пиджак камуфляж",
    "Tops_35_F": "Укороченная кожаная куртка ",
    "Tops_36_F": "Футболка с черепом",
    "Tops_37_F": "Платье с цветами",
    "Tops_38_F": "Футболка с открытым плечом свежая",
    "Tops_39_F": "Часть от костюма каскадера",
    "Tops_40_F": "Футболка с открытым плечом летняя",
    "Tops_41_F": "Куртка пилота",
    "Tops_42_F": "Кофта боевая",
    "Tops_43_F": "Кофта военная",
    "Tops_44_F": "Новогодняя кофта",
    "Tops_45_F": "Кофта розовая",
    "Tops_46_F": "Куртка армейская",
    "Tops_47_F": "Куртка пилота №2",
    "Tops_48_F": "Рубашка полицейского",
    "Tops_49_F": "Футболка свежая",
    "Tops_50_F": "Куртка спортивная",
    "Tops_51_F": "Пиджак фокусника",
    "Tops_52_F": "Пиджак мятый открытый",
    "Tops_53_F": "Пиджак мятый закрытый",
    "Tops_54_F": "Закрытая куртка спортивная",
    "Tops_55_F": "Закрытая куртка кожаная",
    "Tops_56_F": "Рубашка повседневная",
    "Tops_57_F": "Открытый пиджак",
    "Tops_58_F": "Закрытый пиджак",
    "Tops_59_F": "Короткая куртка",
    "Tops_60_F": "Куртка по пояс",
    "Tops_61_F": "Куртка свежая",
    "Tops_62_F": "Куртка с капюшоном",
    "Tops_63_F": "Куртка уличная",
    "Tops_64_F": "Пальто замшевое",
    "Tops_65_F": "Куртка с мехом короткая",
    "Tops_66_F": "Пиджак нарядный",
     "Tops_67_F": "Футболка с открытым плечом нежная",
    "Tops_68_F": "Футболка с золотым узором",
    "Tops_69_F": "Кожаная куртка 'Косуха'",
    "Tops_70_F": "Закрытое пальто с ремнем",
    "Tops_71_F": "Свитер в ромбик",
    "Tops_72_F": "Бомбер спортивный ",
    "Tops_73_F": "Футболка с короткими рукавами",
    "Tops_74_F": "Топ укороченный ",
    "Tops_75_F": "Свитер с длинными рукавами",
    "Tops_76_F": "Длинная футболка с полосками",
    "Tops_77_F": "Куртка удлинённая ",
    "Tops_78_F": "Худи с карманами ",
    "Tops_79_F": "Свитер с укороченными рукавами",
    "Tops_80_F": "Бомбер прогулочный ",
    "Tops_81_F": "Бомбер спортивный с карманами ",
    "Tops_82_F": "-",
    "Tops_83_F": "Пижамная рубашка с кармашком ",
    "Tops_84_F": "Поло 'Andreas' №1",
    "Tops_85_F": "Поло 'Andreas' №2",
    "Tops_86_F": "Рубашка с коротким рукавом",
    "Tops_87_F": "Кофта 'FB'",
    "Tops_88_F": "Футболка с короткими рукавами",
    "Tops_89_F": "Мотоциклетная куртка",
    "Tops_90_F": "Открытый пиджак с пуговицами",
    "Tops_91_F": "Закрытый пиджак с пуговицами",
    "Tops_92_F": "Открытый пиджак с кармашками ",
    "Tops_93_F": "Закрытый пиджак с кармашками",
    "Tops_94_F": "Открытый пиджак с узорами",
    "Tops_95_F": "Закрытый пиджак с узорами",
    "Tops_96_F": "Рубашка с цветами",
    "Tops_97_F": "Курточка в клеточку открытая ",
    "Tops_98_F": "Кимоно укороченное",
    "Tops_99_F": "Пижамный халат укороченный ",
    "Tops_100_F": "Футболка-безрукавка ",
    "Tops_101_F": "Купальник на бретельках",
    "Tops_102_F": "Закрытое пальто с поясом",
    "Tops_103_F": "Свитер, заправленный в штаны",
    "Tops_104_F": "Закрытое пальто с пуговицами ",
    "Tops_105_F": "Блузка летняя свободная",
    "Tops_106_F": "Спортивная уличная куртка",
    "Tops_107_F": "Длинное пальто с пуговицами ",
    "Tops_108_F": "Закрытая новогодняя куртка",
    "Tops_109_F": "Пижамная рубашка",
    "Tops_110_F": "Кожаная куртка байкерши",
    "Tops_111_F": "Корсет с кружевами",
    "Tops_112_F": "Платье с узорами",
    "Tops_113_F": "Платье с полосами",
    "Tops_114_F": "Платье 'Из России с любовью'",
    "Tops_115_F": "Платье с зелеными полосками",
    "Tops_116_F": "Платье с узорами белое",
    "Tops_117_F": "Футболка без рукавов заправленная",
    "Tops_118_F": "Футболка без рукавов",
    "Tops_119_F": "Поло с воротником",
    "Tops_120_F": "Рубашка с глубоким декольте",
    "Tops_121_F": "Закрытая рубашка",
    "Tops_122_F": "Закрытая куртка",
    "Tops_123_F": "Кофта в полосочку",
    "Tops_124_F": "Блузка с бантом",
    "Tops_125_F": "Уличная свободная футболка со вставками",
    "Tops_126_F": "Уличная свободная футболка",
    "Tops_127_F": "Куртка 'SecuroServ'",
    "Tops_128_F": "Футболка 'Liberty' ",
    "Tops_129_F": "Футболка 'Liberty' заправленная",
    "Tops_130_F": "Рубашка двухцветная ",
    "Tops_131_F": "Толстовка 'Liberty'",
    "Tops_132_F": "Рубашка с птицами",
    "Tops_133_F": "Открытая куртка",
    "Tops_134_F": "Жилетка",
    "Tops_135_F": "Закрытая куртка с ремнем",
    "Tops_136_F": "Свитшот заправленный",
    "Tops_137_F": "Закрытое пальто",
    "Tops_138_F": "Олимпийка синяя",
    "Tops_139_F": "Длинное открытое пальто",
    "Tops_140_F": "Бомбер двухцветный",
    "Tops_141_F": "Футболка свежая",
    "Tops_142_F": "Рубашка в полоску",
    "Tops_143_F": "Закрытый пиджак с вырезом",
    "Tops_144_F": "Гоночный бомбер",
    "Tops_145_F": "Гоночный бомбер со вставкой",
    "Tops_146_F": "Каскадерская рубашка",
    "Tops_147_F": "Спортивная кофта",
    "Tops_148_F": "Кожаная куртка",
    "Tops_149_F": "Свитшот 'Nagasaki'",
    "Tops_150_F": "Закрытая куртка армейская",
    "Tops_151_F": "Куртка с флагом",
    "Tops_152_F": "Каскадерская рубашка ковбойская",
    "Tops_153_F": "Куртка механика",
    "Tops_154_F": "Жилетка байкерская",
    "Tops_155_F": "Закрытая жилетка байкерская",
    "Tops_156_F": "Закрытая жилетка с пуговицами",
    "Tops_157_F": "Открытая жилетка байкерская",
    "Tops_158_F": "Закрытая кожаная куртка",
    "Tops_159_F": "Закрытая кожаная безрукавка",
    "Tops_160_F": "Открытая куртка бандитка",
    "Tops_161_F": "Длинная футболка со вставкой",
    "Tops_162_F": "Мотоциклетная кожаная куртка",
    "Tops_163_F": "Открытая куртка с карманами",
    "Tops_164_F": "Открытый пуховик",
    "Tops_165_F": "Закрытая кофта",
    "Tops_166_F": "Открытая джинсовая куртка",
    "Tops_167_F": "Открытая джинсовая жилетка",
    "Tops_168_F": "Топ-безрукавка",
    "Tops_169_F": "Топик",
    "Tops_170_F": "Деревенский топ",
    "Tops_171_F": "Завязанная джинсовая рубашка",
    "Tops_172_F": "Толстовка глянцевая",
    "Tops_173_F": "Кожаная бестия",
    "Tops_174_F": "Байкерская джинсовка",
    "Tops_175_F": "Байкерская джинсовая жилетка",
    "Tops_176_F": "Байкерская кожаная куртка",
    "Tops_177_F": "Байкерская кожаная жилетка",
    "Tops_178_F": "Жилетка 'STFU'",
    "Tops_179_F": "Жилетка закрытая",
    "Tops_180_F": "Неоновая куртка",
    "Tops_181_F": "Открытая жилетка байкерская",
    "Tops_182_F": "Закрытая жилетка байкерская",
    "Tops_183_F": "Открытая кожаная куртка",
    "Tops_184_F": "Толстовка матовая",
    "Tops_185_F": "Рубашка в полоску",
    "Tops_186_F": "Длинная закрытая куртка",
    "Tops_187_F": "Длинная открытая куртка",
    "Tops_188_F": "Часть от пуленепробиваемого костюма",
    "Tops_189_F": "Закрытая длинная куртка",
    "Tops_190_F": "Закрытая куртка с шнурками",
	"Tops_191_F": "Открытая куртка со шнурками",
	"Tops_192_F": "Свитшот в полоску",
	"Tops_193_F": "Открытая камуфляжная куртка",
	"Tops_194_F": "Открытое длинное пальто",
	"Tops_195_F": "Футболка 'Guffy'",
	"Tops_196_F": "Новогодний свитшот №1",
	"Tops_197_F": "Новогодний свитшот №2",
	"Tops_198_F": "Игровой свитшот №1",
	"Tops_199_F": "Игровой свитшот №2",
	"Tops_200_F": "Кофта с ёлками №1",
	"Tops_201_F": "Кофта с ёлками №2",
	"Tops_202_F": "Яркая кофта",
	"Tops_203_F": "Светящаяся кофта",
	"Tops_204_F": "Кофта с капюшоном",
	"Tops_205_F": "Яркая кофта с капюшоном",
	"Tops_206_F": "Длинное пальто с капюшоном",
	"Tops_207_F": "Безрукавка",
	"Tops_208_F": "Футболка 'Bigness' №1",
	"Tops_209_F": "Футболка 'Bigness' №2",
	"Tops_210_F": "Камуфляжная безрукавка №1",
	"Tops_211_F": "Камуфляжная безрукавка с капюшоном",
	"Tops_212_F": "Камуфляжная футболка №1",
	"Tops_213_F": "Камуфляжная куртка закрытая",
	"Tops_214_F": "Камуфляжная куртка с капюшоном №1",
	"Tops_215_F": "Камуфляжная куртка с капюшоном №2",
	"Tops_216_F": "Открытая камуфляжная куртка",
	"Tops_217_F": "Камуфляжный жилет с черным передом №1",
	"Tops_218_F": "Синий Камуфляжный жилет",
	"Tops_219_F": "Камуфляжная открытая куртка",
	"Tops_220_F": "Камуфляжная безрукавка №3",
	"Tops_221_F": "-",
	"Tops_222_F": "Камуфляжный топ",
	"Tops_223_F": "-",
	"Tops_224_F": "Камуфляжная футболка №2",
	"Tops_225_F": "Камуфляжная футболка №3",
	"Tops_226_F": "Камуфляжная футболка №4",
	"Tops_227_F": "Закрытая  модная куртка",
	"Tops_228_F": "Закрытая  модная куртка с капюшоном",
	"Tops_229_F": "-",
	"Tops_230_F": "Военная камуфляжная кофта",
    "Tops_231_F": "Военная Камуфляжная куртка с шевронами",
    "Tops_232_F": "Камуфляжная куртка с короткими рукавами",
    "Tops_233_F": "-",
    "Tops_234_F": "Закрытая куртка №10",
    "Tops_235_F": "Свитшот 'Class Of'",
    "Tops_236_F": "Футболка 'Многоточие'",
    "Tops_237_F": "Спортивная куртка 'Формула 1'",
    "Tops_238_F": "Куртка пилота с карманами",
    "Tops_239_F": "Куртка спортивная",
    "Tops_240_F": "Куртка спортивная расстегнутая",
    "Tops_241_F": "-",
    "Tops_242_F": "Куртка длинная на пуговицах",
    "Tops_243_F": "Куртка длинная на пуговицах расстегнутая",
    "Tops_244_F": "Рубашка с короткими рукавами",
    "Tops_245_F": "Рубашка с короткими рукавами",
    "Tops_246_F": "Поло в полосы",
    "Tops_247_F": "-",
    "Tops_248_F": "-",
    "Tops_249_F": "Поло обычное",
    "Tops_250_F": "Поло обычное",
    "Tops_251_F": "Куртка №7",
    "Tops_252_F": "Закрытая куртка пуховая",
    "Tops_253_F": "Свитшот с узором",
    "Tops_254_F": "-",
    "Tops_255_F": "-",
    "Tops_256_F": "Рабочая куртка",
    "Tops_257_F": "-",
    "Tops_258_F": "Рубашка под талию",
    "Tops_259_F": "Анорак 'Remy' без капюшона",
    "Tops_260_F": "-",
    "Tops_261_F": "Анорак 'Remy' с капюшона ",
    "Tops_262_F": "Легкая куртка",
    "Tops_263_F": "Мотоциклетная куртка гоночная",
    "Tops_264_F": "Разноцветный свитшот",
    "Tops_265_F": "Куртка с пантерами",
    "Tops_266_F": "Куртка 'Blogueurs'",
    "Tops_267_F": "Кофта с пуговицами",
    "Tops_268_F": "Свитшот с рисунками",
    "Tops_269_F": "Рубашка с узором",
    "Tops_270_F": "Открытая куртка с пантерами",
    "Tops_271_F": "Кофта 'Blogueurs' без капюшона",
    "Tops_272_F": "Кофта 'Blogueurs' с капюшона",
    "Tops_273_F": "Закрытая куртка с ремешком",
    "Tops_274_F": "Куртка в кружок",
    "Tops_275_F": "Открытая куртка в кружок",
    "Tops_276_F": "Закрытая куртка с мишенью",
    "Tops_277_F": "Открытая куртка с кармашками",
    "Tops_278_F": "Разноцветная открытая куртка",
    "Tops_279_F": "-",
    "Tops_280_F": "Футболка с вырезом и укороченным рукавом",
    "Tops_281_F": "Футболка приталенная с вырезом",
    "Tops_282_F": "Мотоциклетная куртка №6",
    "Tops_283_F": "Майка с глубоким вырезом",
    "Tops_284_F": "-",
    "Tops_285_F": "Куртка с горлышком ",
    "Tops_286_F": "Футболка с вырезом приталенная ",
    "Tops_287_F": "-",
    "Tops_288_F": "Куртка киборга ",
    "Tops_289_F": "Куртка рыцаря ",
    "Tops_290_F": "Часть костюма пришельца'",
    "Tops_291_F": "Куртка робота",
    "Tops_292_F": "Кофта 'Benedict' №1",
    "Tops_293_F": "Кофта 'Benedict' №2",
    "Tops_294_F": "Свитшот 'Burger-Shot'",
    "Tops_295_F": "Длинная футболка 'Burger'",
    "Tops_296_F": "Кофта астронавта",
    "Tops_297_F": "Куртка с кровью",
    "Tops_298_F": "Куртка пилота ",
    "Tops_299_F": "Куртка супергероя",
    "Tops_300_F": "Курточка робота",
    "Tops_301_F": "Вязаная кофта 'Burger-Shot'",
    "Tops_302_F": "Часть от костюма 'Выживший'",
    "Tops_303_F": "Жилетка приталенная ",
    "Tops_303_F": "Часть от костюма супер-героя",
    "Tops_304_F": "Рабочий открытый пиджак",
    "Tops_305_F": "Рабочий закрытый пиджак",
    "Tops_306_F": "Куртка 'Bigness' №1",
    "Tops_307_F": "Куртка 'Bigness' №2",
    "Tops_308_F": "Куртка 'Bigness' №3",
    "Tops_309_F": "Бомбер прогулочный ",
    "Tops_310_F": "Рубашка 'Космос'",
    "Tops_311_F": "Разноцветная куртка 'Diamond' №1",
    "Tops_312_F": "Разноцветная куртка 'Diamond' №2",
    "Tops_313_F": "Разноцветная куртка 'Diamond' №3",
    "Tops_314_F": "Разноцветная куртка 'Diamond' №4",
    "Tops_315_F": "Куртка с мехом 'Sacapra'",
    "Tops_316_F": "Кофта 'The Diamond' №1",
    "Tops_317_F": "Кофта 'The Diamond' №2",
	"Tops_318_F": "Свитшот 'Broker'",
    "Tops_319_F": "Свитшот 'Bigness'",
    "Tops_320_F": "Куртка 'Broker'",
    "Tops_321_F": "Футболка с рисунком",
    "Tops_322_F": "Платье бальное",
    "Tops_323_F": "Платье 'Broker'",
    "Tops_324_F": "Футболка с самолетами",
    "Tops_325_F": "Куртка пожарного №1",
    "Tops_326_F": "Куртка пожарного №2",
    "Tops_327_F": "Рубашка деловая длинная",
    "Tops_328_F": "Рубашка деловая длинная №2",
    "Tops_329_F": "Рубашка деловая короткая",
    "Tops_330_F": "Рубашка деловая короткая №2",
    "Tops_331_F": "Форма полицейского №1",
    "Tops_332_F": "Рубашка офисная",
    "Tops_333_F": "Рубашка офисная №2",
    "Tops_334_F": "Жилетка офисная",
    "Tops_335_F": "Футболка с шариками",
    "Tops_336_F": "Куртка с защищенными рукавами",
    "Tops_337_F": "Футболка 'Pixtro'",
    "Tops_338_F": "Футболка домашняя",
    "Tops_339_F": "Пиджак деловой",
    "Tops_340_F": "Пиджак офисный",
    "Tops_341_F": "Свитшот 'HO-HO-HO'",
    "Tops_342_F": "Жилетка тактическая",
    "Tops_343_F": "Кофта военная",
    "Tops_344_F": "Бомбер",
    "Tops_345_F": "Кофта с капюшоном",
    "Tops_346_F": "Кофта 'Бандитка'",
	"Tops_370_F": "Паршивая футболка",
	"Tops_373_F": "Футболка Phillip Plein",
	"Torsos_0_F": "Перчатки №1",
	"Torsos_1_F": "Перчатки №2",
	"Torsos_2_F": "Перчатки №3",
	"Torsos_3_F": "Перчатки №4",
	"Torsos_4_F": "Перчатки №5",
	"Torsos_5_F": "Перчатки №6",
	"Torsos_6_F": "Перчатки №7",
	"Torsos_7_F": "Перчатки №8",
	"Torsos_8_F": "Перчатки №9",
	"Torsos_9_F": "Перчатки №10",
	"Torsos_10_F": "Перчатки №11",
	"Torsos_11_F": "Перчатки №12",
	"Torsos_12_F": "Перчатки №13",
	"Torsos_13_F": "Перчатки №14",
	"Torsos_14_F": "Перчатки №15",
	"Torsos_15_F": "Перчатки №16",
	"Torsos_16_F": "Длинные перчатки №1",
	"Torsos_17_F": "Мотоциклетные перчатки №1",
	"Torsos_18_F": "Мотоциклетные перчатки №2",
	"Torsos_19_F": "Перчатки с открытыми пальцами №1",
	"Torsos_20_F": "Полузакрытые перчатки №1",
	"Torsos_21_F": "Полузакрытые перчатки №2",
	"Torsos_22_F": "Полузакрытые перчатки №3",
	"Torsos_23_F": "Полузакрытые перчатки №4",
	"Torsos_24_F": "Полузакрытые перчатки №5",
	"Torsos_25_F": "Полузакрытые перчатки №6",
	"Torsos_26_F": "Полузакрытые перчатки №7",
	"Torsos_27_F": "Полузакрытые перчатки №8",
	"Torsos_28_F": "Полузакрытые перчатки №9",
	"Torsos_29_F": "Полузакрытые перчатки №10",
	"Torsos_30_F": "Полузакрытые перчатки №11",
	"Torsos_31_F": "Полузакрытые перчатки №12",
	"Torsos_32_F": "Полузакрытые перчатки №13",
	"Torsos_33_F": "Закрытые перчатки №1",
	"Torsos_34_F": "Закрытые перчатки №2",
	"Torsos_35_F": "Закрытые перчатки №3",
	"Torsos_36_F": "Закрытые перчатки №4",
	"Torsos_37_F": "Закрытые перчатки №5",
	"Torsos_38_F": "Закрытые перчатки №6",
	"Torsos_39_F": "Закрытые перчатки №7",
	"Torsos_40_F": "Закрытые перчатки №8",
	"Torsos_41_F": "Закрытые перчатки №9",
	"Torsos_42_F": "Закрытые перчатки №10",
	"Torsos_43_F": "Закрытые перчатки №11",
	"Torsos_44_F": "Закрытые перчатки №12",
	"Torsos_45_F": "Закрытые перчатки №13",
	"Torsos_46_F": "Закрытые перчатки №14",
	"Torsos_47_F": "Закрытые перчатки №15",
	"Torsos_48_F": "Закрытые перчатки №16",
	"Torsos_49_F": "Закрытые перчатки №17",
	"Torsos_50_F": "Закрытые перчатки №18",
	"Torsos_51_F": "Закрытые перчатки №19",
	"Torsos_52_F": "Закрытые перчатки №20",
	"Torsos_53_F": "Закрытые перчатки №21",
	"Torsos_54_F": "Закрытые перчатки №22",
	"Torsos_55_F": "Закрытые перчатки №23",
	"Torsos_56_F": "Закрытые перчатки №24",
	"Torsos_57_F": "Закрытые перчатки №25",
	"Torsos_58_F": "Закрытые перчатки №26",
	"Torsos_59_F": "Перчатки с открытыми пальцами №2",
	"Torsos_60_F": "Перчатки с открытыми пальцами №3",
	"Torsos_61_F": "Перчатки с открытыми пальцами №4",
	"Torsos_62_F": "Перчатки с открытыми пальцами №5",
	"Torsos_63_F": "Перчатки с открытыми пальцами №6",
	"Torsos_64_F": "Перчатки с открытыми пальцами №7",
	"Torsos_65_F": "Перчатки с открытыми пальцами №8",
	"Torsos_66_F": "Перчатки с открытыми пальцами №9",
	"Torsos_67_F": "Перчатки с открытыми пальцами №10",
	"Torsos_68_F": "Перчатки с открытыми пальцами №11",
	"Torsos_69_F": "Перчатки с открытыми пальцами №12",
	"Torsos_70_F": "Перчатки с открытыми пальцами №13",
	"Torsos_71_F": "Перчатки с открытыми пальцами №14",
	"Torsos_72_F": "Рабочие перчатки №1",
	"Torsos_73_F": "Рабочие перчатки №2",
	"Torsos_74_F": "Рабочие перчатки №3",
	"Torsos_75_F": "Рабочие перчатки №4",
	"Torsos_76_F": "Рабочие перчатки №5",
	"Torsos_77_F": "Рабочие перчатки №6",
	"Torsos_78_F": "Рабочие перчатки №7",
	"Torsos_79_F": "Рабочие перчатки №8",
	"Torsos_80_F": "Рабочие перчатки №9",
	"Torsos_81_F": "Рабочие перчатки №10",
	"Torsos_82_F": "Рабочие перчатки №11",
	"Torsos_83_F": "Рабочие перчатки №12",
	"Torsos_84_F": "Рабочие перчатки №13",
	"Torsos_85_F": "Обычные перчатки №1",
	"Torsos_86_F": "Обычные перчатки №2",
	"Torsos_87_F": "Обычные перчатки №3",
	"Torsos_88_F": "Обычные перчатки №4",
	"Torsos_89_F": "Обычные перчатки №5",
	"Torsos_90_F": "Обычные перчатки №6",
	"Torsos_91_F": "Обычные перчатки №7",
	"Torsos_92_F": "Обычные перчатки №8",
	"Torsos_93_F": "Обычные перчатки №9",
	"Torsos_94_F": "Обычные перчатки №10",
	"Torsos_95_F": "Обычные перчатки №11",
	"Torsos_96_F": "Обычные перчатки №12",
	"Torsos_97_F": "Обычные перчатки №13",
	"Torsos_98_F": "Латексные перчатки №1",
	"Torsos_99_F": "Латексные перчатки №2",
	"Torsos_100_F": "Латексные перчатки №3",
	"Torsos_101_F": "Латексные перчатки №4",
	"Torsos_102_F": "Латексные перчатки №5",
	"Torsos_103_F": "Латексные перчатки №6",
	"Torsos_104_F": "Латексные перчатки №7",
	"Torsos_105_F": "Латексные перчатки №8",
	"Torsos_106_F": "Латексные перчатки №9",
	"Torsos_107_F": "Латексные перчатки №10",
	"Torsos_108_F": "Латексные перчатки №11",
	"Torsos_109_F": "Латексные перчатки №12",
	"Torsos_110_F": "Латексные перчатки №13",
	"Torsos_111_F": "Защитные перчатки",
	"Torsos_112_F": "Перчатки от костюма 'Зверь' №1",
	"Torsos_113_F": "Перчатки от костюма 'Зверь' №2",
	"Torsos_114_F": "Тканевые перчатки №1",
	"Torsos_115_F": "Тканевые перчатки №2",
	"Torsos_116_F": "Тканевые перчатки №3",
	"Torsos_117_F": "Тканевые перчатки №4",
	"Torsos_118_F": "Тканевые перчатки №5",
	"Torsos_119_F": "Тканевые перчатки №6",
	"Torsos_120_F": "Тканевые перчатки №7",
	"Torsos_121_F": "Тканевые перчатки №8",
	"Torsos_122_F": "Тканевые перчатки №9",
	"Torsos_123_F": "Тканевые перчатки №10",
	"Torsos_124_F": "Тканевые перчатки №11",
	"Torsos_125_F": "Тканевые перчатки №12",
	"Torsos_126_F": "Тканевые перчатки №13",
	"Torsos_127_F": "Мотоциклетные №3",
	"Torsos_128_F": "Мотоциклетные №4",
	"Torsos_129_F": "-",
	"Torsos_130_F": "-",
	"Torsos_131_F": "-",
	"Torsos_132_F": "Полузакрытые перчатки №14",
	"Torsos_133_F": "Латексные перчатки №14",
	"Torsos_134_F": "Тканевые перчатки №14",
	"Torsos_135_F": "Перчатки с открытыми пальцами №15",
	"Torsos_136_F": "Рабочие перчатки №14",
	"Torsos_137_F": "Обычные перчатки №14",
	"Torsos_138_F": "Латексные перчатки №15",
	"Torsos_139_F": "Полузакрытые перчатки №15",
	"Torsos_140_F": "Закрытые перчатки №27",
	"Torsos_141_F": "Тканевые перчатки №15",
	"Torsos_142_F": "Перчатки с открытыми пальцами №15",
	"Torsos_143_F": "Рабочие перчатки №15",
	"Torsos_144_F": "Обычные перчатки №15",
	"Torsos_145_F": "Латексные перчатки №16",
	"Torsos_146_F": "Полузакрытые перчатки №16",
	"Torsos_147_F": "Закрытые перчатки №28",
	"Torsos_148_F": "Тканевые перчатки №16",
	"Torsos_149_F": "Перчатки с открытыми пальцами №16",
	"Torsos_150_F": "Рабочие перчатки №16",
	"Torsos_151_F": "Обычные перчатки №16",
	"Torsos_152_F": "Латексные перчатки №17",
	"Torsos_153_F": "-",
	"Torsos_154_F": "Полузакрытые перчатки №17",
	"Torsos_155_F": "Закрытые перчатки №29",
	"Torsos_156_F": "Тканевые перчатки №17",
	"Torsos_157_F": "Перчатки с открытыми пальцами №17",
	"Torsos_158_F": "Рабочие перчатки №17",
	"Torsos_159_F": "Обычные перчатки №17",
	"Torsos_160_F": "Латексные перчатки №18",
	"Torsos_161_F": "-",
	"Torsos_162_F": "Полузакрытые перчатки №18",
	"Torsos_163_F": "Закрытые перчатки №30",
	"Torsos_164_F": "Тканевые перчатки №18",
	"Torsos_165_F": "Перчатки с открытыми пальцами №18",
	"Torsos_166_F": "Рабочие перчатки №18",
	"Torsos_167_F": "Обычные перчатки №18",
	"Torsos_168_F": "Латексные перчатки №19",
	"Torsos_169_F": "Военные перчатки №1",
	"Torsos_170_F": "Военные перчатки №2",
	"Torsos_171_F": "Военные перчатки №3",
	"Torsos_172_F": "Военные перчатки №4",
	"Torsos_173_F": "Военные перчатки №5",
	"Torsos_174_F": "Военные перчатки №6",
	"Torsos_175_F": "Военные перчатки №7",
	"Torsos_176_F": "Военные перчатки №8",
	"Torsos_177_F": "Военные перчатки №9",
	"Torsos_178_F": "Военные перчатки №10",
	"Torsos_179_F": "Военные перчатки №11",
	"Torsos_180_F": "Военные перчатки №12",
	"Torsos_181_F": "Военные перчатки №13",
	"Torsos_182_F": "Военные перчатки №14",
	"Torsos_183_F": "Военные перчатки №15",
	"Torsos_184_F": "Военные перчатки №16",
	"Torsos_185_F": "Военные перчатки №17",
	"Torsos_186_F": "Военные перчатки №18",
	"Torsos_187_F": "Военные перчатки №19",
	"Torsos_188_F": "Военные перчатки №20",
	"Torsos_189_F": "Военные перчатки №21",
	"Torsos_190_F": "Военные перчатки №22",
	"Torsos_191_F": "Военные перчатки №23",
	"Torsos_192_F": "Военные перчатки №24",
	"Torsos_193_F": "Военные перчатки №25",
	"Torsos_194_F": "Военные перчатки №26",
	"Torsos_195_F": "Военные перчатки №27",
	"Torsos_196_F": "Военные перчатки №28",
	"Torsos_197_F": "Военные перчатки №29",
	"Torsos_198_F": "Военные перчатки №30",
	"Torsos_199_F": "Военные перчатки №31",
	"Torsos_200_F": "Военные перчатки №32",
	"Torsos_201_F": "Военные перчатки №33",
	"Torsos_202_F": "Военные перчатки №34",
	"Torsos_203_F": "Военные перчатки №35",
	"Torsos_204_F": "Военные перчатки №36",
	"Torsos_205_F": "Руки от костюма 'Монстр'",
	"Torsos_206_F": "Перчатки от костюма 'Космонавта' №1",
	"Torsos_207_F": "Перчатки от костюма 'Космонавта' №2",
	"Torsos_208_F": "Перчатки от костюма 'Космонавта' №3",
	"Torsos_209_F": "Перчатки от костюма супер-героя",
	"Torsos_0_M": "Перчатки №1",
	"Torsos_1_M": "Перчатки №2",
	"Torsos_2_M": "Перчатки №3",
	"Torsos_3_M": "Перчатки №4",
	"Torsos_4_M": "Перчатки №5",
	"Torsos_5_M": "Перчатки №6",
	"Torsos_6_M": "Перчатки №7",
	"Torsos_7_M": "Перчатки №8",
	"Torsos_8_M": "Перчатки №9",
	"Torsos_9_M": "Перчатки №10",
	"Torsos_10_M": "Перчатки №11",
	"Torsos_11_M": "Перчатки №12",
	"Torsos_12_M": "Перчатки №13",
	"Torsos_13_M": "Перчатки №14",
	"Torsos_14_M": "Перчатки №15",
	"Torsos_15_M": "Перчатки №16",
	"Torsos_16_M": "Мотоциклетные перчатки №1",
	"Torsos_17_M": "Мотоциклетные перчатки №2",
	"Torsos_18_M": "Перчатки с открытыми пальцами №1",
	"Torsos_19_M": "Полузакрытые перчатки №1",
	"Torsos_20_M": "Полузакрытые перчатки №2",
	"Torsos_21_M": "Полузакрытые перчатки №3",
	"Torsos_22_M": "Полузакрытые перчатки №4",
	"Torsos_23_M": "Полузакрытые перчатки №5",
	"Torsos_24_M": "Полузакрытые перчатки №6",
	"Torsos_25_M": "Полузакрытые перчатки №7",
	"Torsos_26_M": "Полузакрытые перчатки №8",
	"Torsos_27_M": "Полузакрытые перчатки №9",
	"Torsos_28_M": "Полузакрытые перчатки №10",
	"Torsos_29_M": "Полузакрытые перчатки №11",
	"Torsos_30_M": "Закрытые перчатки №1",
	"Torsos_31_M": "Закрытые перчатки №2",
	"Torsos_32_M": "Закрытые перчатки №3",
	"Torsos_33_M": "Закрытые перчатки №4",
	"Torsos_34_M": "Закрытые перчатки №5",
	"Torsos_35_M": "Закрытые перчатки №6",
	"Torsos_36_M": "Закрытые перчатки №7",
	"Torsos_37_M": "Закрытые перчатки №8",
	"Torsos_38_M": "Закрытые перчатки №9",
	"Torsos_39_M": "Закрытые перчатки №10",
	"Torsos_40_M": "Закрытые перчатки №11",
	"Torsos_41_M": "Закрытые перчатки №12",
	"Torsos_42_M": "Закрытые перчатки №13",
	"Torsos_43_M": "Закрытые перчатки №14",
	"Torsos_44_M": "Закрытые перчатки №15",
	"Torsos_45_M": "Закрытые перчатки №16",
	"Torsos_46_M": "Закрытые перчатки №17",
	"Torsos_47_M": "Закрытые перчатки №18",
	"Torsos_48_M": "Закрытые перчатки №19",
	"Torsos_49_M": "Закрытые перчатки №20",
	"Torsos_50_M": "Закрытые перчатки №21",
	"Torsos_51_M": "Перчатки с открытыми пальцами №2",
	"Torsos_52_M": "Перчатки с открытыми пальцами №3",
	"Torsos_53_M": "Перчатки с открытыми пальцами №4",
	"Torsos_54_M": "Перчатки с открытыми пальцами №5",
	"Torsos_55_M": "Перчатки с открытыми пальцами №6",
	"Torsos_56_M": "Перчатки с открытыми пальцами №7",
	"Torsos_57_M": "Перчатки с открытыми пальцами №8",
	"Torsos_58_M": "Перчатки с открытыми пальцами №9",
	"Torsos_59_M": "Перчатки с открытыми пальцами №10",
	"Torsos_60_M": "Перчатки с открытыми пальцами №11",
	"Torsos_61_M": "Перчатки с открытыми пальцами №12",
	"Torsos_62_M": "Перчатки с открытыми пальцами №13",
	"Torsos_63_M": "Рабочие перчатки №1",
	"Torsos_64_M": "Рабочие перчатки №2",
	"Torsos_65_M": "Рабочие перчатки №3",
	"Torsos_66_M": "Рабочие перчатки №4",
	"Torsos_67_M": "Рабочие перчатки №5",
	"Torsos_68_M": "Рабочие перчатки №6",
	"Torsos_69_M": "Рабочие перчатки №7",
	"Torsos_70_M": "Рабочие перчатки №8",
	"Torsos_71_M": "Рабочие перчатки №9",
	"Torsos_72_M": "Рабочие перчатки №10",
	"Torsos_73_M": "Рабочие перчатки №11",
	"Torsos_74_M": "Обычные перчатки №1",
	"Torsos_75_M": "Обычные перчатки №2",
	"Torsos_76_M": "Обычные перчатки №3",
	"Torsos_77_M": "Обычные перчатки №4",
	"Torsos_78_M": "Обычные перчатки №5",
	"Torsos_79_M": "Обычные перчатки №6",
	"Torsos_80_M": "Обычные перчатки №7",
	"Torsos_81_M": "Обычные перчатки №8",
	"Torsos_82_M": "Обычные перчатки №9",
	"Torsos_83_M": "Обычные перчатки №10",
	"Torsos_84_M": "Обычные перчатки №11",
	"Torsos_85_M": "Латексные перчатки №1",
	"Torsos_86_M": "Латексные перчатки №2",
	"Torsos_87_M": "Латексные перчатки №3",
	"Torsos_88_M": "Латексные перчатки №4",
	"Torsos_89_M": "Латексные перчатки №5",
	"Torsos_90_M": "Латексные перчатки №6",
	"Torsos_91_M": "Латексные перчатки №7",
	"Torsos_92_M": "Латексные перчатки №8",
	"Torsos_93_M": "Латексные перчатки №9",
	"Torsos_94_M": "Латексные перчатки №10",
	"Torsos_95_M": "Латексные перчатки №11",
	"Torsos_96_M": "Защитные перчатки",
	"Torsos_97_M": "Перчатки от костюма 'Зверь' №1",
	"Torsos_98_M": "Перчатки от костюма 'Зверь' №2",
	"Torsos_99_M": "Тканевые перчатки №1",
	"Torsos_100_M": "Тканевые перчатки №2",
	"Torsos_101_M": "Тканевые перчатки №3",
	"Torsos_102_M": "Тканевые перчатки №4",
	"Torsos_103_M": "Тканевые перчатки №5",
	"Torsos_104_M": "Тканевые перчатки №6",
	"Torsos_105_M": "Тканевые перчатки №7",
	"Torsos_106_M": "Тканевые перчатки №8",
	"Torsos_107_M": "Тканевые перчатки №9",
	"Torsos_108_M": "Тканевые перчатки №10",
	"Torsos_109_M": "Тканевые перчатки №11",
	"Torsos_110_M": "Мотоциклетные №3",
	"Torsos_111_M": "Мотоциклетные №4",
	"Torsos_112_M": "-",
	"Torsos_113_M": "-",
	"Torsos_114_M": "-",
	"Torsos_115_M": "Полузакрытые перчатки №12",
	"Torsos_116_M": "Закрытые перчатки №22",
	"Torsos_117_M": "Закрытые перчатки №23",
	"Torsos_118_M": "Перчатки с открытыми пальцами №14",
	"Torsos_119_M": "Рабочие перчатки №12",
	"Torsos_120_M": "Обычные перчатки №12",
	"Torsos_121_M": "Латексные перчатки №12",
	"Torsos_122_M": "Полузакрытые перчатки №13",
	"Torsos_123_M": "Закрытые перчатки №24",
	"Torsos_124_M": "Закрытые перчатки №25",
	"Torsos_125_M": "Перчатки с открытыми пальцами №15",
	"Torsos_126_M": "Рабочие перчатки №13",
	"Torsos_127_M": "Обычные перчатки №13",
	"Torsos_128_M": "Латексные перчатки №13",
	"Torsos_129_M": "Полузакрытые перчатки №14",
	"Torsos_130_M": "Закрытые перчатки №26",
	"Torsos_131_M": "Закрытые перчатки №27",
	"Torsos_132_M": "Перчатки с открытыми пальцами №16",
	"Torsos_133_M": "Рабочие перчатки №14",
	"Torsos_134_M": "Обычные перчатки №14",
	"Torsos_135_M": "Латексные перчатки №14",
	"Torsos_136_M": "Военные перчатки №1",
	"Torsos_137_M": "Военные перчатки №2",
	"Torsos_138_M": "Военные перчатки №3",
	"Torsos_139_M": "Военные перчатки №4",
	"Torsos_140_M": "Военные перчатки №5",
	"Torsos_141_M": "Военные перчатки №6",
	"Torsos_142_M": "Военные перчатки №7",
	"Torsos_143_M": "Военные перчатки №8",
	"Torsos_144_M": "Военные перчатки №9",
	"Torsos_145_M": "Военные перчатки №10",
	"Torsos_146_M": "Военные перчатки №11",
	"Torsos_147_M": "Военные перчатки №12",
	"Torsos_148_M": "Военные перчатки №13",
	"Torsos_149_M": "Военные перчатки №14",
	"Torsos_150_M": "Военные перчатки №15",
	"Torsos_151_M": "Военные перчатки №16",
	"Torsos_152_M": "Военные перчатки №17",
	"Torsos_153_M": "Военные перчатки №18",
	"Torsos_154_M": "Военные перчатки №19",
	"Torsos_155_M": "Военные перчатки №20",
	"Torsos_156_M": "Военные перчатки №21",
	"Torsos_157_M": "Военные перчатки №22",
	"Torsos_158_M": "Военные перчатки №23",
	"Torsos_159_M": "Военные перчатки №24",
	"Torsos_160_M": "Военные перчатки №25",
	"Torsos_161_M": "Военные перчатки №26",
	"Torsos_162_M": "Военные перчатки №27",
	"Torsos_163_M": "Военные перчатки №28",
	"Torsos_164_M": "Руки от костюма 'Монстр'",
	"Torsos_165_M": "Перчатки от костюма 'Космонавта' №1",
	"Torsos_166_M": "Перчатки от костюма 'Космонавта' №1",
	"Torsos_167_M": "Перчатки от костюма 'Космонавта' №1",
	"Torsos_168_M": "Перчатки от костюма супер-героя",
	"Bags_0": "-",
	"Bags_1": "Классический рюкзак",
	"Bags_2": "Классический рюкзак",
	"Bags_3": "Классический рюкзак",
	"Bags_4": "Классический рюкзак",
	"Bags_5": "Классический рюкзак",
	"Bags_6": "Классический рюкзак",
	"Bags_7": "Классический рюкзак",
	"Bags_8": "Классический рюкзак",
	"Bags_9": "-",
	"Bags_10": "Рюкзак патриота",
	"Bags_11": "Рюкзак патриота",
	"Bags_12": "Рюкзак патриота",
	"Bags_13": "Рюкзак патриота",
	"Bags_14": "Рюкзак патриота",
	"Bags_15": "Рюкзак патриота",
	"Bags_16": "Рюкзак патриота",
	"Bags_17": "Рюкзак патриота",
	"Bags_18": "Рюкзак патриота",
	"Bags_19": "Классический рюкзак",
	"Bags_20": "-",
	"Bags_21": "Рюкзак с флагом Финляндии",
	"Bags_22": "Рюкзак с флагом Финляндии",
	"Bags_23": "Рюкзак с флагом Финляндии",
	"Bags_24": "Рюкзак с флагом Финляндии",
	"Bags_25": "Рюкзак с флагом Финляндии",
	"Bags_26": "Рюкзак с флагом Финляндии",
	"Bags_27": "Рюкзак с флагом Финляндии",
	"Bags_28": "Рюкзак с флагом Финляндии",
	"Bags_29": "Рюкзак с флагом Финляндии",
	"Bags_30": "-",
	"Bags_31": "Рюкзак однотонный из хлопка",
	"Bags_32": "Рюкзак однотонный из хлопка",
	"Bags_33": "Рюкзак однотонный из хлопка",
	"Bags_34": "Рюкзак однотонный из хлопка",
	"Bags_35": "Рюкзак однотонный из хлопка",
	"Bags_36": "Рюкзак однотонный из хлопка",
	"Bags_37": "Рюкзак однотонный из хлопка",
	"Bags_38": "Рюкзак однотонный из хлопка",
	"Bags_39": "Рюкзак однотонный из хлопка",
	"Bags_40": "Сумка из хлопка",
	"Bags_41": "Закрытая сумка из хлопка",
	"Bags_42": "-",
	"Bags_43": "-",
	"Bags_44": "Кожаная сумка",
	"Bags_45": "Закрытая кожаная сумка",
	"Bags_46": "-",
	"Bags_47": "-",
	"Bags_48": "Классический рюкзак",
	"Bags_49": "Рюкзак патриота",
	"Bags_50": "Рюкзак с флагом Финляндии",
	"Bags_51": "Рюкзак однотонный из хлопка",
	"Bags_52": "Рюкзак двухцветный из хлопка",
	"Bags_53": "Рюкзак двухцветный из хлопка",
	"Bags_54": "Рюкзак двухцветный из хлопка",
	"Bags_55": "Рюкзак двухцветный из хлопка",
	"Bags_56": "Рюкзак двухцветный из хлопка",
	"Bags_57": "Рюкзак двухцветный из хлопка",
	"Bags_58": "Рюкзак двухцветный из хлопка",
	"Bags_59": "Рюкзак двухцветный из хлопка",
	"Bags_60": "Рюкзак двухцветный из хлопка",
	"Bags_61": "Рюкзак двухцветный из хлопка",
	"Bags_62": "Классический рюкзак",
	"Bags_63": "Классический рюкзак",
	"Bags_64": "Рюкзак патриота",
	"Bags_65": "Рюкзак с флагом Финляндии",
	"Bags_66": "Рюкзак однотонный из хлопка",
	"Bags_67": "Рюкзак патриота",
	"Bags_68": "Классический рюкзак",
	"Bags_69": "Рюкзак двухцветный из хлопка",
	"Bags_70": "Рюкзак с флагом Индии",
	"Bags_71": "Рюкзак с флагом Индии",
	"Bags_72": "Рюкзак с флагом Индии",
	"Bags_73": "Рюкзак с флагом Индии",
	"Bags_74": "Рюкзак с флагом Индии",
	"Bags_75": "Рюкзак с флагом Индии",
	"Bags_76": "Рюкзак с флагом Индии",
	"Bags_77": "Рюкзак с флагом Индии",
	"Bags_78": "Рюкзак с флагом Индии",
	"Bags_79": "Рюкзак с флагом Индии",
	"Bags_80": "Рюкзак с флагом Индии",
	"Bags_81": "Кожаная сумка",
	"Bags_82": "Закрытая кожаная сумка",
	"Bags_83": "-",
	"Bags_84": "-",
	"Undershirts_0_F": "Футболка №1",
	"Undershirts_1_F": "Футболка №2",
	"Undershirts_2_F": "-",
	"Undershirts_3_F": "-",
	"Undershirts_4_F": "-",
	"Undershirts_5_F": "Футболка №3",
	"Undershirts_6_F": "Футболка №4",
	"Undershirts_7_F": "-",
	"Undershirts_8_F": "-",
	"Undershirts_9_F": "-",
	"Undershirts_10_F": "-",
	"Undershirts_11_F": "Верх от купальника",
	"Undershirts_12_F": "Футболка с вырезом",
	"Undershirts_13_F": "Корсет №1",
	"Undershirts_14_F": "-",
	"Undershirts_15_F": "-",
	"Undershirts_16_F": "Футболка без лямок",
	"Undershirts_17_F": "-",
	"Undershirts_18_F": "Часть от новогоднего костюма №1",
	"Undershirts_19_F": "Часть от новогоднего костюма №2",
	"Undershirts_20_F": "Футболка №5",
	"Undershirts_21_F": "Футболка №6",
	"Undershirts_22_F": "Корсет №2",
	"Undershirts_23_F": "Блузка №1",
	"Undershirts_24_F": "Рубашка №1",
	"Undershirts_25_F": "Топик №1",
	"Undershirts_26_F": "Футболка №7",
	"Undershirts_27_F": "Топик №2",
	"Undershirts_28_F": "-",
	"Undershirts_29_F": "Футболка №8",
	"Undershirts_30_F": "Футболка №9",
	"Undershirts_31_F": "Футболка №10",
	"Undershirts_32_F": "Жилетка №1",
	"Undershirts_33_F": "Жилетка №2",
	"Undershirts_34_F": "-",
	"Undershirts_35_F": "-",
	"Undershirts_36_F": "Рабочий жилет",
	"Undershirts_37_F": "Рубашка №2",
	"Undershirts_38_F": "Рубашка №3",
	"Undershirts_39_F": "Рубашка №4",
	"Undershirts_40_F": "Рубашка с жилеткой №1",
	"Undershirts_41_F": "Рубашка с жилеткой №2",
	"Undershirts_42_F": "-",
	"Undershirts_43_F": "-",
	"Undershirts_44_F": "Майка №1",
	"Undershirts_45_F": "Футболка с золотым узором",
	"Undershirts_46_F": "Футболка с темно-золотым узором №1",
	"Undershirts_47_F": "Футболка №11",
	"Undershirts_48_F": "Футболка №12",
	"Undershirts_49_F": "Футболка №13",
	"Undershirts_50_F": "Футболка с темно-золотым узором №2",
	"Undershirts_51_F": "Майка №2",
	"Undershirts_52_F": "Майка №3",
	"Undershirts_53_F": "Футболка №14",
	"Undershirts_54_F": "Футболка №15",
	"Undershirts_55_F": "Футболка №16",
	"Undershirts_56_F": "Футболка №17",
	"Undershirts_57_F": "Майка №4",
	"Undershirts_58_F": "Майка №5",
	"Undershirts_59_F": "Майка №6",
	"Undershirts_60_F": "Топик №3",
	"Undershirts_61_F": "Футболка №18",
	"Undershirts_62_F": "Футболка №19",
	"Undershirts_63_F": "Футболка №20",
	"Undershirts_64_F": "Рубашка с расстегнутыми пуговицами",
	"Undershirts_65_F": "Рубашка №5",
	"Undershirts_66_F": "Кофта №1",
	"Undershirts_67_F": "Кофта №2",
	"Undershirts_68_F": "Корсет №3",
	"Undershirts_69_F": "Футболка №21",
	"Undershirts_70_F": "Футболка №22",
	"Undershirts_71_F": "Футболка №23",
	"Undershirts_72_F": "Футболка №24",
	"Undershirts_73_F": "Футболка №25",
	"Undershirts_74_F": "Футболка №26",
	"Undershirts_75_F": "Рубашка №6",
	"Undershirts_76_F": "Кофта №3",
	"Undershirts_77_F": "Кофта №4",
	"Undershirts_78_F": "Футболка №27",
	"Undershirts_79_F": "Майка №7",
	"Undershirts_80_F": "Майка №8",
	"Undershirts_81_F": "-",
	"Undershirts_82_F": "Футболка 'Nagasaki' №1",
	"Undershirts_83_F": "Футболка 'Nagasaki' №2",
	"Undershirts_84_F": "Футболка 'Nagasaki' №3",
	"Undershirts_85_F": "Футболка 'Nagasaki' №4",
	"Undershirts_86_F": "Футболка №28",
	"Undershirts_87_F": "Футболка №29",
	"Undershirts_88_F": "Футболка №30",
	"Undershirts_89_F": "Футболка №31",
	"Undershirts_90_F": "Футболка №32",
	"Undershirts_91_F": "Футболка №33",
	"Undershirts_92_F": "Футболка №34",
	"Undershirts_93_F": "Футболка №35",
	"Undershirts_94_F": "Футболка №36",
	"Undershirts_95_F": "Футболка №37",
	"Undershirts_96_F": "-",
	"Undershirts_97_F": "Футболка №38",
	"Undershirts_98_F": "Футболка №39",
	"Undershirts_99_F": "-",
	"Undershirts_100_F": "Футболка №40",
	"Undershirts_101_F": "Рубашка №7",
	"Undershirts_102_F": "Пиджак с рубашкой",
	"Undershirts_103_F": "Пиджак с кофтой",
	"Undershirts_104_F": "Рубашка №8",
	"Undershirts_105_F": "Пуленепробиваемый костюм",
	"Undershirts_106_F": "Футболка 'Bigness' №1",
	"Undershirts_107_F": "Футболка 'Bigness' №2",
	"Undershirts_108_F": "Футболка 'Bigness' №3",
	"Undershirts_109_F": "Футболка 'Bigness' №4",
	"Undershirts_110_F": "Футболка 'Bigness' №5",
	"Undershirts_111_F": "Футболка 'Bigness' №6",
	"Undershirts_112_F": "Футболка 'Bigness' №7",
	"Undershirts_113_F": "Футболка 'Bigness' №8",
	"Undershirts_114_F": "Футболка 'Bigness' №9",
	"Undershirts_115_F": "Футболка 'Bigness' №10",
	"Undershirts_116_F": "Футболка 'Bigness' №11",
	"Undershirts_117_F": "Футболка 'Bigness' №12",
	"Undershirts_118_F": "Пиксельная футболка №1",
	"Undershirts_119_F": "Пиксельная футболка №2",
	"Undershirts_120_F": "Пиксельная футболка №3",
	"Undershirts_121_F": "Пиксельная футболка №4",
	"Undershirts_122_F": "Пиксельная футболка №5",
	"Undershirts_123_F": "Пиксельная футболка №6",
	"Undershirts_124_F": "Пиксельная футболка №7",
	"Undershirts_125_F": "Пиксельная футболка №8",
	"Undershirts_126_F": "Пиксельная футболка №9",
	"Undershirts_127_F": "Пиксельная футболка №10",
	"Undershirts_128_F": "Пиксельная футболка №11",
	"Undershirts_129_F": "Пиксельная футболка №12",
	"Undershirts_130_F": "Пиксельная футболка №13",
	"Undershirts_131_F": "Пиксельная футболка №14",
	"Undershirts_132_F": "Пиксельная футболка №15",
	"Undershirts_133_F": "Пиксельная футболка №16",
	"Undershirts_134_F": "Пиксельная футболка №17",
	"Undershirts_135_F": "Пиксельная футболка №18",
	"Undershirts_136_F": "Пиксельная футболка №19",
	"Undershirts_137_F": "Пиксельная футболка №20",
	"Undershirts_138_F": "Пиксельная футболка №21",
	"Undershirts_139_F": "Пиксельная футболка №22",
	"Undershirts_140_F": "Пиксельная футболка №23",
	"Undershirts_141_F": "Пиксельная футболка №24",
	"Undershirts_142_F": "Футболка №41",
	"Undershirts_143_F": "Футболка №42",
	"Undershirts_144_F": "Футболка №43",
	"Undershirts_145_F": "Футболка №44",
	"Undershirts_146_F": "Футболка №45",
	"Undershirts_147_F": "Футболка №46",
	"Undershirts_148_F": "-",
	"Undershirts_149_F": "Поло №1",
	"Undershirts_150_F": "Поло №2",
	"Undershirts_151_F": "Майка №9",
	"Undershirts_152_F": "-",
	"Undershirts_153_F": "-",
	"Undershirts_154_F": "Жилет пилота №1",
	"Undershirts_155_F": "Мотоциклетный жилет №1",
	"Undershirts_156_F": "Мотоциклетный жилет №2",
	"Undershirts_157_F": "Мотоциклетный жилет №3",
	"Undershirts_158_F": "Мотоциклетный жилет №4",
	"Undershirts_159_F": "-",
	"Undershirts_160_F": "-",
	"Undershirts_161_F": "Бронежилет",
	"Undershirts_162_F": "Футболка №47",
	"Undershirts_163_F": "Футболка №48",
	"Undershirts_164_F": "Футболка №49",
	"Undershirts_165_F": "Футболка №50",
	"Undershirts_166_F": "Футболка №51",
	"Undershirts_167_F": "Футболка №52",
	"Undershirts_168_F": "Футболка №53",
	"Undershirts_169_F": "Футболка №54",
	"Undershirts_170_F": "Футболка №55",
	"Undershirts_171_F": "Футболка №56",
	"Undershirts_172_F": "Футболка №57",
	"Undershirts_173_F": "Футболка №58",
	"Undershirts_174_F": "-",
	"Undershirts_175_F": "Топик №4",
	"Undershirts_176_F": "Топик №5",
	"Undershirts_177_F": "-",
	"Undershirts_178_F": "Футболка №59",
	"Undershirts_179_F": "Футболка №60",
	"Undershirts_180_F": "Футболка №61",
	"Undershirts_181_F": "Футболка №62",
	"Undershirts_182_F": "Футболка №63",
	"Undershirts_183_F": "Футболка №64",
	"Undershirts_184_F": "-",
	"Undershirts_185_F": "Рубашка №9",
	"Undershirts_186_F": "Часть от костюма супер-героя",
	"Undershirts_187_F": "Часть от костюма водолаза",
	"Undershirts_188_F": "Жилет №1",
	"Undershirts_189_F": "-",
	"Undershirts_190_F": "-",
	"Undershirts_191_F": "-",
	"Undershirts_192_F": "Мотоциклетный жилет №5",
	"Undershirts_193_F": "Рубашка №10",
	"Undershirts_194_F": "Рубашка №11",
	"Undershirts_195_F": "Бронежилет с рубашкой №1",
	"Undershirts_196_F": "Бронежилет с рубашкой №2",
	"Undershirts_197_F": "Бронежилет с рубашкой №3",
	"Undershirts_198_F": "Бронежилет с рубашкой №4",
	"Undershirts_199_F": "Жилет №2",
	"Undershirts_200_F": "Жилет №3",
	"Ears_0_M": "Гарнитура №1",
	"Ears_1_M": "Гарнитура №2",
	"Ears_2_M": "Гарнитура №3",
	"Ears_3_M": "-",
	"Ears_4_M": "Кольцо №1",
	"Ears_5_M": "Кольцо №2",
	"Ears_6_M": "-",
	"Ears_7_M": "С круглым алмазом №1",
	"Ears_8_M": "С круглым алмазом №2",
	"Ears_9_M": "-",
	"Ears_10_M": "Ромбы №1",
	"Ears_11_M": "Ромбы №2",
	"Ears_12_M": "-",
	"Ears_13_M": "Серьга №1",
	"Ears_14_M": "Серьга №2",
	"Ears_15_M": "-",
	"Ears_16_M": "Серьга №3",
	"Ears_17_M": "Серьга №4",
	"Ears_18_M": "-",
	"Ears_19_M": "Серьга №5",
	"Ears_20_M": "Серьга №6",
	"Ears_21_M": "-",
	"Ears_22_M": "Серьга №7",
	"Ears_23_M": "Серьга №8",
	"Ears_24_M": "-",
	"Ears_25_M": "Серьга 'Череп' №1",
	"Ears_26_M": "Серьга 'Череп' №2",
	"Ears_27_M": "-",
	"Ears_28_M": "Серьга №9",
	"Ears_29_M": "Серьга №10",
	"Ears_30_M": "-",
	"Ears_31_M": "Серьга №11",
	"Ears_32_M": "Серьга №12",
	"Ears_33_M": "-",
	"Ears_34_M": "-",
	"Ears_35_M": "Серьга №13",
	"Ears_36_M": "Серьга №14",
	"Ears_37_M": "Серьга №15",
	"Ears_38_M": "Серьга 'Карты'",
	"Ears_39_M": "Серьга 'Кости'",
	"Ears_40_M": "Серьга 'Фишка'",
	"Watches_0_M": "Q&Q VR8",
	"Watches_1_M": "Skmei 1068-5",
	"Watches_2_M": "-",
	"Watches_3_M": "Timex T49",
	"Watches_4_M": "Skagen SKW64",
	"Watches_5_M": "Esprit ES1",
	"Watches_6_M": "Casio GBA-800DG",
	"Watches_7_M": "Orient FER27",
	"Watches_8_M": "Tissot T117",
	"Watches_9_M": "Certina C035",
	"Watches_10_M": "Skmei 1471-3",
	"Watches_11_M": "Pierre Lannier",
	"Watches_12_M": "Jacques Lemans 1-207",
	"Watches_13_M": "Amvox",
	"Watches_14_M": "Master",
	"Watches_15_M": "Aquaracer",
	"Watches_16_M": "Hawk",
	"Watches_17_M": "Carrera",
	"Watches_18_M": "Marine",
	"Watches_19_M": "Sport" ,
	"Watches_20_M": "Defy",
	"Watches_21_M": "Diver",
	"Watches_22_M": "Серебряный браслет №1",
            "Watches_23_M": "Серебряный браслет №2",
            "Watches_24_M": "Серебряный браслет №3",
            "Watches_24_M": "Серебряный браслет №4",
           "Watches_26_M": "Серебряный браслет №5",
           "Watches_27_M": "Браслет с бусинами",
           "Watches_28_M": "Браслет с шипами",
           "Watches_29_M": "Напульсник",
	"Watches_30_M": "Классические",
	"Watches_31_M": "Деловые",
	"Watches_32_M": "Деловые статусные",
	"Watches_33_M": "Классические свежие",
	"Watches_34_M": "Компактные классические",
	"Watches_35_M": "Механические",
	"Watches_36_M": "Повседневные",
	"Watches_37_M": "Крупные классические",
	"Watches_38_M": "SASS",
	"Watches_39_M": "SASS №2",
	"Undershirts_0_M": "Футболка №1",
	"Undershirts_1_M": "Футболка №2",
	"Undershirts_2_M": "Футболка №3",
	"Undershirts_3_M": "Рубашка с жилеткой №1",
	"Undershirts_4_M": "Рубашка с жилеткой №2",
	"Undershirts_5_M": "Футболка №4",
	"Undershirts_6_M": "Рубашка №1",
	"Undershirts_7_M": "Рубашка №2",
	"Undershirts_8_M": "Футболка №5",
	"Undershirts_9_M": "Поло №1",
	"Undershirts_10_M": "Рубашка №3",
	"Undershirts_11_M": "Рубашка №4",
	"Undershirts_12_M": "Рубашка №5",
	"Undershirts_13_M": "Рубашка №6",
	"Undershirts_14_M": "Футболка №6",
	"Undershirts_15_M": "-",
	"Undershirts_16_M": "Футболка №7",
	"Undershirts_17_M": "Футболка №8",
	"Undershirts_18_M": "Футболка №9",
	"Undershirts_19_M": "Новогодняя футболка №1",
	"Undershirts_20_M": "Новогодняя футболка №2",
	"Undershirts_21_M": "Рубашка №7",
	"Undershirts_22_M": "Рубашка №8",
	"Undershirts_23_M": "Футболка №10",
	"Undershirts_24_M": "Футболка №11",
	"Undershirts_25_M": "Рубашка с жилеткой №3",
	"Undershirts_26_M": "Рубашка с жилеткой №4",
	"Undershirts_27_M": "Рубашка №9",
	"Undershirts_28_M": "Рубашка №10",
	"Undershirts_29_M": "Рубашка №11",
	"Undershirts_30_M": "Рубашка №12",
	"Undershirts_31_M": "Рубашка №13",
	"Undershirts_32_M": "Рубашка №14",
	"Undershirts_33_M": "Рубашка с жилеткой №5",
	"Undershirts_34_M": "Рубашка с жилеткой №6",
	"Undershirts_35_M": "Рубашка с жилеткой №7",
	"Undershirts_36_M": "Рубашка с жилеткой №8",
	"Undershirts_37_M": "Футболка №12",
	"Undershirts_38_M": "Футболка №13",
	"Undershirts_39_M": "Футболка №14",
	"Undershirts_40_M": "Футболка №15",
	"Undershirts_41_M": "Футболка №16",
	"Undershirts_42_M": "Поло №2",
	"Undershirts_43_M": "Рубашка №15",
	"Undershirts_44_M": "Футболка №17",
	"Undershirts_45_M": "Рубашка №16",
	"Undershirts_46_M": "Рубашка №17",
	"Undershirts_47_M": "Футболка №18",
	"Undershirts_48_M": "Футболка №19",
	"Undershirts_49_M": "Рубашка №18",
	"Undershirts_50_M": "Рубашка №19",
	"Undershirts_51_M": "Рубашка с жилеткой 'Патриот' №1",
	"Undershirts_52_M": "Рубашка с жилеткой 'Патриот' №2",
	"Undershirts_53_M": "Футболка №20",
	"Undershirts_54_M": "Футболка №21",
	"Undershirts_55_M": "Жилет №1",
	"Undershirts_56_M": "Жилет №2",
	"Undershirts_57_M": "-",
	"Undershirts_58_M": "-",
	"Undershirts_59_M": "Рабочий жилет",
	"Undershirts_60_M": "Рубашка №20",
	"Undershirts_61_M": "-",
	"Undershirts_62_M": "-",
	"Undershirts_63_M": "Рубашка №21",
	"Undershirts_64_M": "Рубашка №22",
	"Undershirts_65_M": "Футболка с золотым узором №1",
	"Undershirts_66_M": "Футболка с золотым узором №2",
	"Undershirts_67_M": "Футболка №22",
	"Undershirts_68_M": "Футболка №23",
	"Undershirts_69_M": "Рубашка №23",
	"Undershirts_70_M": "Рубашка №24",
	"Undershirts_71_M": "Футболка №24",
	"Undershirts_72_M": "Футболка №25",
	"Undershirts_73_M": "Рубашка №25",
	"Undershirts_74_M": "Футболка №26",
	"Undershirts_75_M": "Футболка №27",
	"Undershirts_76_M": "Футболка №28",
	"Undershirts_77_M": "Футболка №29",
	"Undershirts_78_M": "-",
	"Undershirts_79_M": "Футболка 'Nagasaki' №1",
	"Undershirts_80_M": "Футболка 'Nagasaki' №2",
	"Undershirts_81_M": "Футболка №30",
	"Undershirts_82_M": "Модная футболка №1",
	"Undershirts_83_M": "Футболка №31",
	"Undershirts_84_M": "Модная футболка №2",
	"Undershirts_85_M": "Футболка №32",
	"Undershirts_86_M": "Модная футболка №3",
	"Undershirts_87_M": "Футболка №33",
	"Undershirts_88_M": "Футболка №34",
	"Undershirts_89_M": "Футболка №35",
	"Undershirts_90_M": "Рубашка №26",
	"Undershirts_91_M": "Рубашка с жилетом",
	"Undershirts_92_M": "Футболка с жилетом",
	"Undershirts_93_M": "Рубашка №27",
	"Undershirts_94_M": "Рубашка №28",
	"Undershirts_95_M": "Рубашка №29",
	"Undershirts_96_M": "Рубашка №30",
	"Undershirts_97_M": "Пуленепробиваемый костюм",
	"Undershirts_98_M": "Камуфляжная футболка №1",
	"Undershirts_99_M": "Камуфляжная футболка №2",
	"Undershirts_100_M": "Камуфляжная футболка №3",
	"Undershirts_101_M": "Камуфляжная футболка №4",
	"Undershirts_102_M": "Камуфляжная футболка №5",
	"Undershirts_103_M": "Футболка №36",
	"Undershirts_104_M": "Футболка №37",
	"Undershirts_105_M": "Футболка №38",
	"Undershirts_106_M": "Футболка №39",
	"Undershirts_107_M": "Футболка №40",
	"Undershirts_108_M": "-",
	"Undershirts_109_M": "Поло №3",
	"Undershirts_110_M": "Поло №4",
	"Undershirts_111_M": "Футболка №41",
	"Undershirts_112_M": "Футболка №42",
	"Undershirts_113_M": "Футболка №43",
	"Undershirts_114_M": "Футболка №44",
	"Undershirts_115_M": "Футболка №45",
	"Undershirts_116_M": "Футболка №46",
	"Undershirts_117_M": "Камуфляжная футболка №6",
	"Undershirts_118_M": "Камуфляжная футболка №7",
	"Undershirts_119_M": "Камуфляжная футболка №8",
	"Undershirts_120_M": "Камуфляжная футболка №9",
	"Undershirts_121_M": "Камуфляжная футболка №10",
	"Undershirts_122_M": "-",
	"Undershirts_123_M": "-",
	"Undershirts_124_M": "-",
	"Undershirts_125_M": "Жилет пилота №1",
	"Undershirts_126_M": "Мотоциклетный жилет №1",
	"Undershirts_127_M": "Мотоциклетный жилет №2",
	"Undershirts_128_M": "Мотоциклетный жилет №3",
	"Undershirts_129_M": "-",
	"Undershirts_130_M": "-",
	"Undershirts_131_M": "Бронежилет №1",
	"Undershirts_132_M": "Футболка №47",
	"Undershirts_133_M": "Футболка №48",
	"Undershirts_134_M": "Футболка №49",
	"Undershirts_135_M": "Футболка №50",
	"Undershirts_136_M": "Футболка №51",
	"Undershirts_137_M": "-",
	"Undershirts_138_M": "Футболка №52",
	"Undershirts_139_M": "Футболка №53",
	"Undershirts_140_M": "Футболка №54",
	"Undershirts_141_M": "Футболка №55",
	"Undershirts_142_M": "Футболка №56",
	"Undershirts_143_M": "-",
	"Undershirts_144_M": "Рубашка №31",
	"Undershirts_145_M": "Часть от костюма супер-героя",
	"Undershirts_146_M": "Рубашка с жилеткой №10",
	"Undershirts_147_M": "Рубашка с жилеткой №11",
	"Undershirts_148_M": "Рубашка с жилеткой №12",
	"Undershirts_149_M": "Рубашка с жилеткой №13",
	"Undershirts_150_M": "Рубашка №32",
	"Undershirts_151_M": "Часть от костюма водолаза",
	"Undershirts_152_M": "Жилет №3",
	"Undershirts_153_M": "-",
	"Undershirts_154_M": "-",
	"Undershirts_155_M": "-",
	"Undershirts_156_M": "Мотоциклетный жилет №5",
	"Undershirts_157_M": "Рубашка №33",
	"Undershirts_158_M": "Рубашка №34",
	"Undershirts_159_M": "Рубашка с бронежилетом №1",
	"Undershirts_160_M": "Рубашка с бронежилетом №2",
	"Undershirts_161_M": "Рубашка с бронежилетом №3",
	"Undershirts_162_M": "Рубашка с бронежилетом №4",
	"Undershirts_163_M": "Жилет №4",
	"Undershirts_164_M": "Бронежилет №2",
	"Legs_0_M": "Джинсы печеньки",
    "Legs_1_M": "Джинсы рэперские",
    "Legs_2_M": "Шорты шахматные",
    "Legs_3_M": "Спортивные штаны",
    "Legs_4_M": "Зауженные джинсы",
    "Legs_5_M": "Большие спортивные штаны",
    "Legs_6_M": "Шорты с карманами",
    "Legs_7_M": "Классические штаны",
    "Legs_8_M": "Классические штаны свежие",
    "Legs_9_M": "Штаны с боковыми карманами",
    "Legs_10_M": "Классические брюки",
    "Legs_11_M": "-",
    "Legs_12_M": "Шорты однотонные",
    "Legs_13_M": "Классические брюки свежие",
    "Legs_14_M": "Пляжные шорты короткие",
    "Legs_15_M": "Шорты с боковыми карманами",
    "Legs_16_M": "Шорты пляжные",
    "Legs_17_M": "Шорты прогулочные",
    "Legs_18_M": "Пляжные шорты стильные",
    "Legs_19_M": "Классические брюки утончённые",
    "Legs_20_M": "Классические брюки свежие",
    "Legs_21_M": "Трусы",
    "Legs_22_M": "Классические брюки элегантные",
    "Legs_23_M": "Классические брюки статусные",
    "Legs_24_M": "Зауженные брюки",
    "Legs_25_M": "Классические брюки строгие",
    "Legs_26_M": "Зауженные штаны свежие",
    "Legs_27_M": "Свободные штаны",
    "Legs_28_M": "Зауженные брюки строгие",
    "Legs_29_M": "Штаны в полоску",
    "Legs_30_M": "Штаны пилота",
    "Legs_31_M": "Короткие штаны",
    "Legs_32_M": "Кальсоны",
    "Legs_33_M": "Джоггеры",
    "Legs_34_M": "Боевые штаны",
    "Legs_35_M": "Классические брюки современные",
    "Legs_36_M": "Рабочие штаны",
    "Legs_37_M": "Классические брюки фирменные",
    "Legs_38_M": "Классические штаны элегантные",
    "Legs_39_M": "Классические брюки свежие",
    "Legs_40_M": "Спортивные штаны уличные",
    "Legs_41_M": "Штаны пилота",
    "Legs_42_M": "Шорты свободные",
    "Legs_43_M": "Свободные джинсы",
    "Legs_44_M": "-",
    "Legs_45_M": "Зауженные штаны стильные",
    "Legs_46_M": "Штаны пилота",
    "Legs_47_M": "Рабочие штаны свежие",
    "Legs_48_M": "Классические брюки универсальные",
    "Legs_49_M": "Зауженные брюки",
    "Legs_50_M": "Классические брюки с изюминкой",
	"Legs_51_M": "Брюки с узором",
    "Legs_52_M": "Зауженные брюки строгие",
    "Legs_53_M": "Зауженные брюки с узором",
    "Legs_54_M": "Шорты пляжные",
    "Legs_55_M": "Свободные спортивные штаны",
    "Legs_56_M": "Полотенце",
    "Legs_57_M": "Бриджи",
    "Legs_58_M": "Штаны пижамные",
    "Legs_59_M": "Джоггеры свободные",
    "Legs_60_M": "Штаны в клеточку пижамные",
    "Legs_61_M": "Трусы семейные",
    "Legs_62_M": "Бриджи для каратэ",
    "Legs_63_M": "Классические джинсы",
    "Legs_64_M": "Свободные спортивные штаны 'Абибас'",
    "Legs_65_M": "Штаны от пижамы",
    "Legs_66_M": "Мотоциклетные штаны с вырезкой",
    "Legs_67_M": "Мотоциклетные штаны с наколенниками",
    "Legs_68_M": "Каскадерские штаны с флагом",
    "Legs_69_M": "Штаны с черепами",
    "Legs_70_M": "Каскадерские штаны ковбойские",
    "Legs_71_M": "Кожаные штаны в обтяжку",
    "Legs_72_M": "Кожаные штаны короткие",
    "Legs_73_M": "Мотоциклетные штаны защитные",
    "Legs_74_M": "Мотоциклетные штаны защитные короткие",
    "Legs_75_M": "Джинсы рваные",
    "Legs_76_M": "Рваные джинсы",
    "Legs_77_M": "Неоновые штаны",
    "Legs_78_M": "Зауженные спортивные штаны",
    "Legs_79_M": "Зауженные спортивные штаны матовые",
    "Legs_80_M": "Бриджи тканевые",
    "Legs_81_M": "Бриджи латексные",
    "Legs_82_M": "Зауженные джинсы тканевые",
    "Legs_83_M": "Зауженные джинсы латексные",
    "Legs_84_M": "Часть пуленепробиваемого костюма",
    "Legs_85_M": "Неоновые Леггинсы",
    "Legs_86_M": "Камуфляжные штаны",
    "Legs_87_M": "Камуфляжные штаны джоггеры",
    "Legs_88_M": "Камуфляжные шорты",
    "Legs_89_M": "Камуфляжные штаны военные",
    "Legs_90_M": "Классические джинсы с вставками",
    "Legs_91_M": "Спортивные штаны с манжетом",
    "Legs_92_M": "Штаны пилота с ремешками",
    "Legs_93_M": "Штаны пилота с подтяжками",
    "Legs_94_M": "Лосины утеплённые",
    "Legs_95_M": "Лосины 'Матрица'",
    "Legs_96_M": "Классические штаны однотонные",
    "Legs_97_M": "Рабочие штаны  джоггеры",
    "Legs_98_M": "Рабочие штаны широкие",
    "Legs_99_M": "Свободные спортивные штаны легкие",
    "Legs_100_M": "Свободные штаны с цветами",
	"Legs_101_M": "Болоневые штаны",
	"Legs_102_M": "Свободные штаны с цепями",
	"Legs_103_M": "Бриджи с цепями",
	"Legs_104_M": "Классические свободные штаны",
	"Legs_105_M": "Классические стильные брюки",
	"Legs_106_M": "Лосины футуристичные",
	"Legs_107_M": "Героические бриджи",
	"Legs_108_M": "Часть от костюма 'Монстр'",
	"Legs_109_M": "Штаны космонавта ",
	"Legs_110_M": "Штаны космонавта Хана Соло",
	"Legs_111_M": "Штаны маньяка",
	"Legs_112_M": "Штаны  бананового космонавта",
	"Legs_113_M": "Штаны эко космонавта",
	"Legs_114_M": "Штаны от костюма 'Робот'",
	"Legs_115_M": "Обтягивающие штаны",
	"Legs_116_M": "Модные узкие штаны",
	"Legs_117_M": "Шорты пляжные",
	"Legs_118_M": "Модные свободные штаны ",
	"Legs_119_M": "Нижняя часть халата императора",
	"Legs_120_M": "Штаны пожарного",
	"Legs_121_M": "Штаны полицейского",
	"Legs_122_M": "Свободные серые штаны",
	"Legs_123_M": "Зауженные штаны",
	"Legs_124_M": "Гоночные штаны",
	"Legs_125_M": "Штаны с накладкой",
	"Legs_131_M": "Узкие штаны",
	"Legs_133_M": "Тёмные джинсы",
	"Legs_134_M": "Свежие брюки",
	"Legs_136_M": "Рэперские шорты",
	"Legs_137_M": "Смешные трусы",
	"Legs_0_F": "Зауженные джинсы",
	"Legs_1_F": "Свободные джинсы",
	"Legs_2_F": "Спортивные штаны",
	"Legs_3_F": "Свободные брюки",
	"Legs_4_F": "Зауженные джинсы с подворотами",
	"Legs_5_F": "Шорты домино",
	"Legs_6_F": "Классические  офисные брюки ",
	"Legs_7_F": "Юбка до колена",
	"Legs_8_F": "Мини-юбка",
	"Legs_9_F": "Разноцветная мини-юбка",
	"Legs_10_F": "Спортивные шорты",
	"Legs_11_F": "Спортивные брюки с подворотами",
	"Legs_12_F": "Юбка для тенниса",
	"Legs_13_F": "-",
	"Legs_14_F": "Синие шорты",
	"Legs_15_F": "Трусы элегантные",
	"Legs_16_F": "Шорты пляжные",
	"Legs_17_F": "Трусы свежие",
	"Legs_18_F": "Деловая юбка",
	"Legs_19_F": "Кружевные шортики",
	"Legs_20_F": "Чулки свадебные",
	"Legs_21_F": "Трусы нежные",
	"Legs_22_F": "Чулки соблазнительные",
	"Legs_23_F": "Деловые брюки",
	"Legs_24_F": "Разноцветная юбка",
    "Legs_25_F": "Джинсовые шорты",
    "Legs_26_F": "Леопардовая юбка",
    "Legs_27_F": "Леггинсы кожаные",
    "Legs_28_F": "Юбка в полоску",
    "Legs_29_F": "Штаны парашютиста",
    "Legs_30_F": "Бриджи уличные",
    "Legs_31_F": "Зауженные леггинсы",
    "Legs_32_F": "Джоггеры",
    "Legs_33_F": "Штаны для езды на мотоцикле",
    "Legs_34_F": "Черные деловые брюки",
    "Legs_35_F": "Рабочие штаны",
    "Legs_36_F": "Классическая юбка",
    "Legs_37_F": "Классические брюки стильные",
    "Legs_38_F": "Свободные штаны",
    "Legs_39_F": "Свободные штаны свежие",
    "Legs_40_F": "Спортивные штаны",
    "Legs_41_F": "Свободные брюки",
    "Legs_42_F": "Штаны пилота",
    "Legs_43_F": "Джинсы с разрезами",
    "Legs_44_F": "Джинсы с разрезами по бокам",
    "Legs_45_F": "Рабочие штаны универсальные",
    "Legs_46_F": "-",
    "Legs_47_F": "Классические брюки элегантные",
    "Legs_48_F": "Штаны пилота",
    "Legs_49_F": "Рабочие штаны практичные",
    "Legs_50_F": "Свободные деловые брюки",
    "Legs_51_F": "Зауженные деловые брюки",
    "Legs_52_F": "Классические брюки на выход",
    "Legs_53_F": "Классические брюки с узором",
    "Legs_54_F": "Зауженные Леггинсы свежие",
    "Legs_55_F": "Зауженные Леггинсы с узором",
    "Legs_56_F": "Трусы удивительные",
    "Legs_57_F": "Полотенце",
    "Legs_58_F": "Свободные спортивные штаны",
    "Legs_59_F": "Свободные бриджи",
    "Legs_60_F": "Штаны в клеточку",
    "Legs_61_F": "Рабочие штаны",
    "Legs_62_F": "Шёлковые трусики",
    "Legs_63_F": "Чулки с трусиками",
    "Legs_64_F": "Зауженные деловые штаны",
    "Legs_65_F": "Широкие деловые штаны",
    "Legs_66_F": "Спортивные штаны уличные",
    "Legs_67_F": "Штаны пижамные",
    "Legs_68_F": "Спортивные штаны с манжетой",
    "Legs_69_F": "Мотоциклетные штаны",
    "Legs_70_F": "Штаны каскадера",
    "Legs_71_F": "Свободные штаны",
    "Legs_72_F": "Штаны со звездами",
    "Legs_73_F": "Зауженные джинсы с узором",
	"Legs_74_F": "Зауженные рваные джинсы",
	"Legs_75_F": "Зауженные джинсы универсальные",
	"Legs_76_F": "Зауженные джинсы обтягивающие",
	"Legs_77_F": "Зауженные джинсы облегающие",
	"Legs_78_F": "Джинсовые шорты с чулками",
	"Legs_79_F": "Неоновые штаны",
	"Legs_80_F": "Зауженные спортивные штаны джоггеры",
	"Legs_81_F": "Зауженные спортивные штаны латексные",
	"Legs_82_F": "Спортивные бриджи",
	"Legs_83_F": "Спортивные бриджи латексные",
	"Legs_84_F": "Зауженные джинсы ручной работы",
	"Legs_85_F": "Зауженные джинсы ручной работы латексные",
	"Legs_86_F": "Часть пуленепробиваемого костюма",
	"Legs_87_F": "Камуфляжные Леггинсы",
	"Legs_88_F": "Неоновые Леггинсы",
	"Legs_89_F": "Камуфляжные штаны",
	"Legs_90_F": "Камуфляжные зауженные штаны",
	"Legs_91_F": "Камуфляжные шорты",
	"Legs_92_F": "Камуфляжные свободные штаны",
	"Legs_93_F": "Свободные джинсы",
	"Legs_94_F": "Спортивные штаны с манжетой стильные",
	"Legs_95_F": "Штаны пилота с ремешками",
	"Legs_96_F": "Штаны пилота с подтяжками",
	"Legs_97_F": "Спортивные Леггинсы",
	"Legs_98_F": "Леггинсы 'Матрица'",
	"Legs_99_F": "Свободные штаны строгие",
	"Legs_100_F": "Зауженные рабочие штаны",
	"Legs_101_F": "Свободные рабочие штаны",
	"Legs_102_F": "Зауженные штаны с подвязкой",
	"Legs_103_F": "Свободные спортивные штаны легкие",
	"Legs_104_F": "Свободные штаны с цветами",
	"Legs_105_F": "Балоневые штаны",
	"Legs_106_F": "Зауженные штаны с молниями",
	"Legs_107_F": "Шорты короткие",
	"Legs_108_F": "Юбка хамелеон",
	"Legs_109_F": "Байкерские свободные штаны",
	"Legs_110_F": "Байкерские свободные бриджи",
	"Legs_111_F": "Свободные штаны морские",
	"Legs_112_F": "Зауженные джинсы уличные",
	"Legs_113_F": "Леггинсы обтягивающие",
	"Legs_114_F": "Бриджи пилота",
	"Legs_115_F": "Часть от костюма 'Монстр'",
	"Legs_116_F": "Штаны космонавта с наколенниками",
	"Legs_117_F": "Штаны космонавта обрезанные",
	"Legs_118_F": "Спортивные штаны с кровью",
	"Legs_119_F": "Штаны космонавта 'Банановые'",
	"Legs_120_F": "Штаны космонавта в обтяжку",
	"Legs_121_F": "Штаны 'Робот'",
	"Legs_122_F": "Классические бриджи",
	"Legs_123_F": "Шорты пляжные",
	"Legs_124_F": "Классические брюки с узорами",
	"Legs_125_F": "Полотенце с узорами",
	"Legs_126_F": "Штаны пожарного",
	"Legs_127_F": "Штаны полицейского",
	"Legs_128_F": "Свободные штаны с карманами",
	"Legs_129_F": "Зауженные штаны с карманами",
	"Legs_130_F": "Гоночные штаны",
	"Legs_131_F": "Штаны с накладкой",
	"Tops_0_M": "Футболка",
	"Tops_1_M": "Футболка стильная",
	"Tops_2_M": "Футболка домино",
	"Tops_3_M": "Куртка начинающего",
	"Tops_4_M": "Пиджак деловой",
	"Tops_5_M": "Майка №1",
	"Tops_6_M": "Кожаная куртка с белыми вставками",
	"Tops_7_M": "Кофта спортивная",
	"Tops_8_M": "Футболка курьера",
	"Tops_9_M": "Поло в полоску",
	"Tops_10_M": "Пиджак закрытый",
	"Tops_11_M": "Жилетка стриптизера",
	"Tops_12_M": "Рубашка с длинными рукавами",
	"Tops_13_M": "Рубашка с закатанными рукавами",
	"Tops_14_M": "Рубашка в клетку",
	"Tops_15_M": "-",
	"Tops_16_M": "Футболка №4",
	"Tops_17_M": "Футболка №5",
	"Tops_18_M": "Новогодняя футболка",
	"Tops_19_M": "Пиджак №3",
	"Tops_20_M": "Пиджак крестного отца",
	"Tops_21_M": "Жилет стриптизера",
	"Tops_22_M": "Футболка №6",
	"Tops_23_M": "Пиджак №5",
	"Tops_24_M": "Пиджак №6",
	"Tops_25_M": "Жилетка №3",
	"Tops_26_M": "Рубашка №4",
	"Tops_27_M": "Пиджак №7",
	"Tops_28_M": "Пиджак №8",
	"Tops_29_M": "Пиджак №9",
	"Tops_30_M": "Пиджак №10",
	"Tops_31_M": "Пиджак №11",
	"Tops_32_M": "Пиджак №12",
	"Tops_33_M": "Футболка №7",
	"Tops_34_M": "Футболка №8",
	"Tops_35_M": "Пиджак №13",
	"Tops_36_M": "Майка №2",
	"Tops_37_M": "Куртка №2",
	"Tops_38_M": "Футболка №9",
	"Tops_39_M": "Поло №2",
	"Tops_40_M": "Жилетка №4",
	"Tops_41_M": "Рубашка №5",
	"Tops_42_M": "Рубашка №6",
	"Tops_43_M": "Рубашка №7",
	"Tops_44_M": "Футболка №10",
	"Tops_45_M": "Жилетка 'Патриот'",
	"Tops_46_M": "Пиджак 'Патриот'",
	"Tops_47_M": "Футболка уличная",
    "Tops_48_M": "Куртка пилота №1",
    "Tops_49_M": "Защитная кофта №1",
    "Tops_50_M": "Кофта №2",
    "Tops_51_M": "Новогодний свитшот",
    "Tops_52_M": "Свитшот №1",
    "Tops_53_M": "Защитная кофта №2",
    "Tops_54_M": "Куртка пилота №2",
    "Tops_55_M": "Полицейская рубашка",
    "Tops_56_M": "Грязная футболка №1",
    "Tops_57_M": "Куртка №1",
    "Tops_58_M": "Пиджак №14",
    "Tops_59_M": "Грязный пиджак №1",
    "Tops_60_M": "Грязный пиджак №2",
    "Tops_61_M": "Куртка №2",
    "Tops_62_M": "Куртка №3",
    "Tops_63_M": "Рубашка №8",
    "Tops_64_M": "Кожаная куртка №2",
    "Tops_65_M": "Куртка №4",
    "Tops_66_M": "Укороченная куртка",
    "Tops_67_M": "Свитшот №2",
    "Tops_68_M": "Куртка №5",
    "Tops_69_M": "Куртка №6",
    "Tops_70_M": "Кожаная куртка с мехом №1",
    "Tops_71_M": "Футболка №12",
    "Tops_72_M": "Длинное пальто №1",
    "Tops_73_M": "Футболка с золотым узором",
    "Tops_74_M": "Модная куртка №1",
    "Tops_75_M": "Модная куртка №2",
    "Tops_76_M": "Пальто №1",
    "Tops_77_M": "Длинное пальто №2",
    "Tops_78_M": "Свитшот №3",
    "Tops_79_M": "Кофта №3",
    "Tops_80_M": "Футболка №13",
    "Tops_81_M": "Футболка №14",
    "Tops_82_M": "Поло №3",
    "Tops_83_M": "Футболка №15",
    "Tops_84_M": "Кофта 'Corkers'",
    "Tops_85_M": "Куртка №7",
    "Tops_86_M": "Кофта №4",
    "Tops_87_M": "Кофта 'Double' №1",
    "Tops_88_M": "Кофта 'Double' №2",
    "Tops_89_M": "Свитшот №4",
    "Tops_90_M": "Кофта №5",
    "Tops_91_M": "-",
    "Tops_92_M": "Рубашка №9",
    "Tops_93_M": "Поло 'Andreas' №1",
    "Tops_94_M": "Поло 'Andreas' №2",
    "Tops_95_M": "Рубашка №10",
    "Tops_96_M": "Кофта 'FB'",
    "Tops_97_M": "Футболка новичка",
    "Tops_98_M": "Грязная футболка №2",
    "Tops_99_M": "Пиджак №15",
    "Tops_100_M": "Пиджак №16",
    "Tops_101_M": "Пиджак №17",
	"Tops_102_M": "Пиджак в клеточку",
    "Tops_103_M": "Пиджак с узором лесной",
    "Tops_104_M": "Пиджак с узором лесной с рукавами",
    "Tops_105_M": "Рубашка с цветами",
    "Tops_106_M": "Куртка пуховая",
    "Tops_107_M": "Халат в японском стиле",
    "Tops_108_M": "Пиджак Кумитэ",
    "Tops_109_M": "Кофта ручной пошив",
    "Tops_110_M": "Куртка с поясом",
    "Tops_111_M": "Толстовка",
    "Tops_112_M": "Пальто c пуговицами",
    "Tops_113_M": "Олимпийка с логотипом",
    "Tops_114_M": "Халат",
    "Tops_115_M": "Пальто удлиненное",
    "Tops_116_M": "Новогодняя куртка",
    "Tops_117_M": "Рубашка клетчатая",
    "Tops_118_M": "Мотоциклетная куртка со вставками",
    "Tops_119_M": "Деловой пиджак",
    "Tops_120_M": "Деловой жилет ",
    "Tops_121_M": "Кофта растаманка",
    "Tops_122_M": "Куртка модная",
    "Tops_123_M": "Поло с молнией",
    "Tops_124_M": "Куртка пацанка",
    "Tops_125_M": "Куртка 'Homo'",
    "Tops_126_M": "Рубашка 'Гангстер'",
    "Tops_127_M": "Рубашка стритрейсер",
    "Tops_128_M": "Футболка для бейсбол",
    "Tops_129_M": "Куртка 'SecuroServ'",
    "Tops_130_M": "Куртка 'SecuroServ' расстегнута",
    "Tops_131_M": "Поло 'Liberty'",
    "Tops_132_M": "Короткое поло 'Liberty'",
    "Tops_133_M": "Рубашка заправленная",
    "Tops_134_M": "Кофта 'Liberty'",
    "Tops_135_M": "Рубашка с птицами",
    "Tops_136_M": "Куртка пуховик с ремнем",
    "Tops_137_M": "Джемпер",
    "Tops_138_M": "Куртка потертая кожаная",
    "Tops_139_M": "Водолазка",
    "Tops_140_M": "Пальто шерстяное",
    "Tops_141_M": "Олимпийка с полосами",
    "Tops_142_M": "Пальто расстегнутое",
    "Tops_143_M": "Кофта для бейсбола",
    "Tops_144_M": "Ночная рубашка",
    "Tops_145_M": "Халат  №2",
    "Tops_146_M": "Футболка",
    "Tops_147_M": "Мотоциклетная куртка 'Glamour'",
    "Tops_148_M": "Мотоциклетная куртка 'Muratti'",
    "Tops_149_M": "Каскадерская рубашка 'Clober'",
    "Tops_150_M": "Кофта с рисунком",
    "Tops_151_M": "Куртка механика 'CowBoy'",
    "Tops_152_M": "Свитшот 'Nagasaki'",
    "Tops_153_M": "Бомбер 'Молодость'",
    "Tops_154_M": "Куртка 'Страны'",
    "Tops_155_M": "Каскадерская рубашка 'Экстрим'",
	"Tops_156_M": "Куртка механика №2",
	"Tops_157_M": "Жилет №1",
	"Tops_158_M": "Жилет №2",
	"Tops_159_M": "Жилет №3",
	"Tops_160_M": "Жилет №4",
	"Tops_161_M": "Байкерская куртка №1",
	"Tops_162_M": "Байкерский жилет",
	"Tops_163_M": "Куртка джинсовая",
	"Tops_164_M": "Футболка длинная",
	"Tops_165_M": "Камуфляжная куртка",
	"Tops_166_M": "Кожаная куртка со вставками",
	"Tops_167_M": "Открытый пуховик",
	"Tops_168_M": "Кофта лесная",
	"Tops_169_M": "Джинсовая куртка №1",
	"Tops_170_M": "Джинсовая жилетка №2",
	"Tops_171_M": "Кофта №11",
	"Tops_172_M": "Джинсовая куртка №2",
	"Tops_173_M": "Джинсовая жилетка №2",
	"Tops_174_M": "Байкерская куртка №2",
	"Tops_175_M": "Байкерский жилет",
	"Tops_176_M": "Жилетка 'STFU'",
	"Tops_177_M": "Жилетка №6",
	"Tops_178_M": "Неоновая куртка",
	"Tops_179_M": "Жилетка №7",
	"Tops_180_M": "Жилетка №8",
	"Tops_181_M": "Кожаная куртка №4",
	"Tops_182_M": "Кофта №12",
	"Tops_183_M": "Пиджак в полоску",
	"Tops_184_M": "Длинная куртка с капюшоном",
	"Tops_185_M": "Длинная куртка №2",
	"Tops_186_M": "Часть от пуленепробиваемого костюма",
	"Tops_187_M": "Пальто",
	"Tops_188_M": "Куртка №18",
	"Tops_189_M": "Куртка №19",
	"Tops_190_M": "Свитшот в полоску",
	"Tops_191_M": "Камуфляжная куртка",
	"Tops_192_M": "Длинное пальто №3",
	"Tops_193_M": "Футболка 'Guffy'",
	"Tops_194_M": "Новогодний свитшот №1",
	"Tops_195_M": "Новогодний свитшот №2",
	"Tops_196_M": "Игровой свитшот №1",
	"Tops_197_M": "Игровой свитшот №2",
	"Tops_198_M": "Кофта с ёлками №1",
	"Tops_199_M": "Кофта с ёлками №2",
	"Tops_200_M": "Яркая кофта №1",
	"Tops_201_M": "Светящаяся кофта",
	"Tops_202_M": "Кофта №13",
	"Tops_203_M": "Яркая кофта №2",
	"Tops_204_M": "Длинное пальто №4",
	"Tops_205_M": "Безрукавка",
    "Tops_206_M": "Камуфляжная безрукавка №1",
    "Tops_207_M": "Камуфляжная безрукавка №2",
    "Tops_208_M": "Камуфляжная футболка №1",
    "Tops_209_M": "Камуфляжная куртка №1",
    "Tops_210_M": "Камуфляжная куртка с капюшоном №1",
    "Tops_211_M": "Камуфляжная куртка с капюшоном №2",
    "Tops_212_M": "Открытая камуфляжная куртка",
    "Tops_213_M": "Камуфляжный жилет №1",
    "Tops_214_M": "Камуфляжный жилет №2",
    "Tops_215_M": "Камуфляжная открытая куртка",
    "Tops_216_M": "Камуфляжная безрукавка №3",
    "Tops_217_M": "Куртка №20",
    "Tops_218_M": "Куртка №21",
    "Tops_219_M": "Камуфляжная безрукавка №4",
    "Tops_220_M": "Камуфляжная кофта №1",
    "Tops_221_M": "Камуфляжная куртка №2",
    "Tops_222_M": "Камуфляжная куртка с короткими рукавами",
    "Tops_223_M": "Жилет №5",
    "Tops_224_M": "Куртка №22",
    "Tops_225_M": "Футболка 'Class Of'",
    "Tops_226_M": "Футболка №19",
    "Tops_227_M": "Спортивная куртка №1",
    "Tops_228_M": "Куртка пилота №3",
    "Tops_229_M": "Куртка №23",
    "Tops_230_M": "Куртка №24",
    "Tops_231_M": "Куртка пилота №4",
    "Tops_232_M": "Куртка №25",
    "Tops_233_M": "Куртка №26",
    "Tops_234_M": "Рубашка №16",
    "Tops_235_M": "Поло в полосы №1",
    "Tops_236_M": "Поло в полосы №2",
    "Tops_237_M": "Футболка №20",
    "Tops_238_M": "Футболка №21",
    "Tops_239_M": "Камуфляжная футболка №2",
    "Tops_240_M": "Кожаная куртка с мехом №2",
    "Tops_241_M": "Поло №5",
    "Tops_242_M": "Поло №6",
    "Tops_243_M": "Водолазная кофта",
    "Tops_244_M": "Куртка №27",
    "Tops_245_M": "Свитшот с узором",
    "Tops_246_M": "Кофта 'Матрица'",
    "Tops_247_M": "Жилетка №9",
    "Tops_248_M": "Мотоциклетная куртка №4",
    "Tops_249_M": "Кофта №15",
    "Tops_250_M": "Рубашка №17",
	"Tops_251_M": "Военная куртка №1",
	"Tops_252_M": "-",
	"Tops_253_M": "Военная куртка №2",
	"Tops_254_M": "Мотоциклетная куртка №5",
	"Tops_255_M": "Разноцветный свитшот",
	"Tops_256_M": "Куртка с пантерами",
	"Tops_257_M": "Куртка 'Blogueurs'",
	"Tops_258_M": "Кофта с пуговицами",
	"Tops_259_M": "Свитшот с рисунками",
	"Tops_260_M": "Рубашка с узором",
	"Tops_261_M": "Открытая куртка с пантерами",
	"Tops_262_M": "Кофта 'Blogueurs' №1",
	"Tops_263_M": "Кофта 'Blogueurs' №2",
	"Tops_264_M": "Куртка кожаная",
	"Tops_265_M": "Куртка в кружок",
	"Tops_266_M": "Открытая куртка в кружок",
	"Tops_267_M": "Куртка №29",
	"Tops_268_M": "Куртка №30",
	"Tops_269_M": "Разноцветная открытая куртка",
	"Tops_270_M": "Мотоциклетная куртка №6",
	"Tops_271_M": "Футболка №22",
	"Tops_272_M": "Спортивная куртка №2",
	"Tops_273_M": "Футболка №23",
	"Tops_274_M": "-",
	"Tops_275_M": "Куртка для выживания №1",
	"Tops_276_M": "Куртка для выживания №2",
	"Tops_277_M": "Часть от костюма 'Монстр'",
	"Tops_278_M": "Часть от костюма 'Астронавт' №1",
	"Tops_279_M": "Кофта 'Benedict' №1",
	"Tops_280_M": "Кофта 'Benedict' №2",
	"Tops_281_M": "Свитшот 'Burger Shot'",
	"Tops_282_M": "Длинная футболка 'Burger'",
	"Tops_283_M": "Часть от костюма 'Астронавт' №2",
	"Tops_284_M": "Куртка с кровью",
	"Tops_285_M": "Куртка пилота №5",
	"Tops_286_M": "Часть от костюма 'Астронавт' №3",
	"Tops_287_M": "Часть от костюма 'Робот'",
	"Tops_288_M": "Вязаная кофта 'Burger Shot'",
	"Tops_289_M": "Часть от костюма 'Выживший'",
	"Tops_290_M": "Жилетка №10",
	"Tops_291_M": "Часть от костюма супер-героя",
	"Tops_292_M": "Пиджак с узором открытый",
	"Tops_293_M": "Пиджак с узором закрытый",
	"Tops_294_M": "Пиджак №21",
	"Tops_295_M": "Пиджак №22",
	"Tops_296_M": "Куртка 'Bigness' №1",
	"Tops_297_M": "Куртка 'Bigness' №2",
	"Tops_298_M": "Куртка 'Bigness' №3",
	"Tops_299_M": "Рубашка с НЛО",
	"Tops_300_M": "Разноцветная куртка 'Diamond' №1",
	"Tops_301_M": "Разноцветная куртка 'Diamond' №2",
	"Tops_302_M": "Разноцветная куртка 'Diamond' №3",
	"Tops_303_M": "Разноцветная куртка 'Diamond' №4",
	"Tops_304_M": "Куртка с мехом 'Sacapra'",
	"Tops_305_M": "Кофта 'The Diamond' №1",
	"Tops_306_M": "Кофта 'The Diamond' №2",
	"Tops_307_M": "Свитшот 'Broker'",
	"Tops_308_M": "Свитшот 'Bigness'",
	"Tops_309_M": "Куртка 'Broker'",
	"Tops_310_M": "Халат",
	"Tops_311_M": "Пиджак №23",
	"Tops_312_M": "Пиджак №24",
	"Tops_313_M": "Футболка с рисунком №2",
	"Tops_314_M": "Форма пожарного №1",
	"Tops_315_M": "Форма пожарного №2",
	"Tops_316_M": "Рубашка №18",
	"Tops_317_M": "Рубашка №19",
	"Tops_318_M": "Рубашка №20",
	"Tops_319_M": "Рубашка №21",
	"Tops_320_M": "Форма полицейского",
	"Tops_321_M": "Рубашка №22",
	"Tops_322_M": "Рубашка №23",
	"Tops_323_M": "Футболка с шариками",
	"Tops_324_M": "Куртка с защищенными рукавами",
	"Tops_325_M": "Футболка 'Pixtro'",
	"Tops_326_M": "Свитшот 'HO-HO-HO'",
	"Tops_327_M": "Жилетка №11",
	"Tops_328_M": "Куртка №31",
	"Tops_329_M": "Кофта №16",
	"Tops_352_M": "Футболка с принтом",
	"Tops_353_M": "Худи с принтом",
	"Tops_355_M": "Цветная майка",
	"Tops_356_M": "Футболка фаната",
	"Tops_358_M": "Модная футболка",
	"Tops_359_M": "Прикольная футболка",
	"Tops_360_M": "Необычная футболка",
	"Tops_361_M": "Верх Phillip Plein",
	"Tops_363_M": "Куртка LV",
	"Tops_368_M": "Полицейское худи",
	"Tops_377_M": "Футболка Armani",
	"Tops_378_M": "Футболка Fillip Plein",
	"Tops_382_M": "Футболка BAPE",
	"Tops_379_M": "Футболка CK",
	"Tops_380_M": "Яркая кофта", 
	"Tops_374_F":"Брендовая майка",
	"Tops_375_F":"Худи из Франции",
	"Tops_376_F":"Куртка North Face",
	"Tops_377_F":"Спортивный топ Nike",
	"Tops_382_F":"Кружевной топ",
	"Tops_383_F":"Вечерний топ",
	"Tops_384_F":"Верх комбенизона",
	"Tops_385_F":"Свитер",
	"Tops_385_M":"Кожаная куртка",
	"Tops_386_F":"Укороченная куртка",
	"Tops_388_F":"Короткий топ",
	"Tops_389_F":"Радужный бомбер",
	"Tops_390_F":"Спортивный топ Adidas",
	"Tops_391_F":"Модный лифчик",
	"Tops_393_F":"Уличное платье",
	"Tops_394_F":"Пышное платье",
	"Tops_407_F":"Пышное платье",
	"Tops_395_F":"Укороченная блузка",
	"Tops_396_F":"Верх латексного костюма",
	"Tops_397_F":"Пляжный топ",
	"Tops_398_F":"Оверсайз футболка",
	"Tops_399_F":"Прикольная футболка",
	"Tops_401_F":"Ретро футболка",
	"Tops_402_F":"Кожаная куртка",
	"Tops_394_F":"Пышное платье",
	"Legs_139_F":"Необычные легинсы",
	"Legs_140_F":"Юбка с лосинами",
	"Legs_141_F":"Юбка с гетрами",
	"Legs_142_F":"Интимное бельё",
	"Legs_143_F":"Джинсовые шортики с чулочками",
	"Legs_144_F":"Джинсовые шортики",
	"Legs_147_F":"Короткие джинсовые шорты",
	"Legs_148_F":"Низ латексного костюма",
	"Legs_149_F":"Спортивный топ Adidas",
	"Shoes_100_F":"Кроссовки Nike",
	"Shoes_101_F":"Кроссовки Джорданы",
	"Shoes_102_F":"Спортивные кроссовки Nike",
	"Shoes_103_F":"Уличные кроссовки",
	"Shoes_106_F":"Кроссовки Triple S",
	"Shoes_108_F":"Кроссовки Adidas",
	"Shoes_105_M":"Кроссовки BAPE",
	"Shoes_106_M":"Цветные кроссовки Nike",
	"Shoes_107_M":"Неоновые кроссовки",
	"Shoes_107_F":"Неоновые кроссовки",
	"Shoes_108_M":"Беговые кроссовки",
	"Shoes_108_M":"Кроссовки BAPE",

	"Legs_139_M": "Мешковатые боевые штаны",
	
	"Tops_386_M":"Хэллоуинчиская худи",
	"Tops_387_M":"Хэллоуинчиская худи рефлектор",
	"Tops_408_F":"Камуфляжное платье с поясом",
	"Tops_409_F":"Платье с карсетом",
	"Tops_410_F":"Платье с цветами",
	"Tops_411_F":"Платье с зеброй",
	"Tops_412_F":"Хэллоуинчиские майки",
	"Tops_413_F":"Хэллоуинческие майки рефлектор",
	"Shoes_109_F":"Спортивные кроссовки",
	"Shoes_110_F":"Кроссовки рокстары",
	"Tops_388_M":"Боевая кофта",

	"Masks_196":"Хэллоуинчиские маска",
	"Masks_197":"Шляпа ведьмы",

	"Tops_403_F":"Светящаяся майка",
	"Tops_405_F":"Светящаяся латексная майка",
	"Legs_150_F":"Светящиеся латексные штаны",
}