<svg xmlns="http://www.w3.org/2000/svg" width="103.57" height="86.28" viewBox="0 0 103.57 86.28">
  <defs>
    <style>
      .cls-1 {
        fill: #bfbfbf;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="farm8" class="cls-1" d="M3737,4294l-33,6-35-5-18-20,1-22,17-21,27-10,18-1,21-1,14,13Z" transform="translate(-3648.31 -4217.16)"/>
</svg>
