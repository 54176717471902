<script>
    import image from '../../images/street1.jpg'
</script>



<div class="text">
    <p>
        <center><font size="5">Уличные гонки может провести каждый игрок, у которого имеется машина.</font></center>
        <br />
        <center>
            <strong>Как это работает?</strong><br />
            <br />

            <span style="font-weight: 400;">
                Для того чтобы начать гонку, вам необходимо нажать на клавишу "G" находясь в машине, после чего выбрать "Уличная Гонка&rdquo;.<br />
                После этого необходимо в телефоне выбрать игрока, с которым планируете провести гонку. Игрок должен находиться рядом с вами.<br />
                <br />
            </span>
            <span style="font-weight: 400;">
                Затем следует сделать ставку, минимальная 1$, максимальная 100.000$.<br />
                И, наконец, на карте необходимо поставить метку финиша. Удачи на дорогах!
            </span>
        </center>
    </p>
    <br>
        
        <div style="display: flex; justify-content: center;">
            <img src={image} alt="Интерьер" class="contentImg"/> 
        </div>
</div>