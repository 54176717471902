<script>
    import { translateText } from 'lang'
</script>
<div class="weazelnews__title">{translateText('fractions', 'Государственная волна')}</div>
<textarea class="weazelnews__textarea w-100 mt-32" placeholder="Введите текст.." ></textarea>
<div class="box-between">
    <div class="weazelnews__input_descr">
        {translateText('fractions', 'В гос. волне запрещены нецензурные выражения и оскорбления! Соблюдайте правила 5 минут между публикациями!')}
    </div>
    <div class="weazelnews__button">{translateText('fractions', 'Опубликовать')}</div>
</div>
<div class="box-flex mt-auto">
    <div class="bortovoiicon-info"></div>
    <div class="weazelnews__bold">{translateText('fractions', 'Помощь по работе с планшетом')}</div>
</div>
<div class="weazelnews__block_info">
    {translateText('fractions', 'Для написания объявлений в государственную волну воспользуйтесь пунктом “Государственная волна”, а для публикаций и редактирования объявлений воспользуйтесь пунктом “Объявления”.')}
</div>