<svg xmlns="http://www.w3.org/2000/svg" width="99.63" height="53.16" viewBox="0 0 99.63 53.16">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="shahta1" class="cls-1" d="M4659,3569l-98-5c12.57-16.16,28.04-28.81,46-37a114.7,114.7,0,0,1,13-5h17Zm-40.38-35.1" transform="translate(-4562.84 -3519)"/>
</svg>
