<svg xmlns="http://www.w3.org/2000/svg" width="400.97" height="263.97" viewBox="0 0 400.97 263.97">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="forest4" class="cls-1" d="M2458,3744l-37,7-49-4-48-20-23-20-31-10-13-12s-38,20.5-40,21-41,7-41,7l-15,32-7,59,59,50,66,21,19,44,11,24,63-26s46.5-2,49-2,58-9,58-9l22-23,47-36v-34l1-23-62-52Z" transform="translate(-2151.03 -3682.72)"/>
</svg>
