<svg xmlns="http://www.w3.org/2000/svg" width="135.5" height="64.31" viewBox="0 0 135.5 64.31">
  <defs>
    <style>
      .cls-1 {
        fill: #bfbfbf;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="farm7" class="cls-1" d="M4026,1187H3919l-17-7,3-44,15-6,38-1,38,5,19,1,11,8,5,17Z" transform="translate(-3899 -1126)"/>
</svg>
