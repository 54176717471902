<svg xmlns="http://www.w3.org/2000/svg" width="71.82" height="63.04" viewBox="0 0 71.82 63.04">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="dalnoboi" class="cls-1" d="M4010,3286l-56,21-11-16,28-25,32-16Z" transform="translate(-3941.06 -3247.62)"/>
</svg>
