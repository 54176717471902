<svg xmlns="http://www.w3.org/2000/svg" width="184.63" height="321.6" viewBox="0 0 184.63 321.6">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="oil" class="cls-1" d="M4471,6911q-10.995,100.995-22,202a72.969,72.969,0,0,0,0,33,72.145,72.145,0,0,0,8,20q6.495,24,13,48l100-29,27-28s17-28.69,18-35,11-32,11-32l-3-49-55-131-4-13Zm369,275" transform="translate(-4444.12 -6894.06)"/>
</svg>
