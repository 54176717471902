<svg xmlns="http://www.w3.org/2000/svg" width="126.28" height="127.07" viewBox="0 0 126.28 127.07">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="shahta4" class="cls-1" d="M5228,3396l-70-12-15,60,31,38-5,19,52,2,37,2,6-8-30-66-1-18Z" transform="translate(-5140.19 -3381.09)"/>
</svg>
