<div class="newdonate__info">
    <div class="newdonate__info-block">
        <div class="newdonate__info-question"/>
        <div class="newdonate__info-info">
            <div class="box-flex">
                <div class="redbucks-img"/>
                <div class="newdonate__info-title">Что такое RB?</div>
            </div>
            <div class="newdonate__info-paragraph">
            <b>RedBucks</b> - это донат-валюта проекта, получить которую можно путём пожертвования, используя внутриигровой интерфейс (кнопка "Купить" справа) или <b>личный кабинет</b> (lk.redage.net). <br> <br> Так же <b>RB</b> можно получить просто так, каждый день, просто проводя время в игре! <br> Если коротко: в месяц можно получить до <b>1200 RedBucks абсолютно бесплатно</b>.  Подробнее об этом можно узнать на нашей <b>Википедии</b> (wiki.redage.net) 
            </div>
        </div>
    </div>
    <div class="newdonate__escape">
        <div class="box-flex">
            <span class="donateicons-esc"/>
            <div class="newdonate__escape-title">ESC</div>
        </div>
        <div class="newdonate__escape-text">
            Нажми, чтобы закрыть
        </div>
    </div>
</div>