<script>
    import image from '../../images/capt1.jpg'
</script>



<div class="text">
    <p><font size="6"><center><span style="font-weight: 400;">Война за территорию (capture) и бизнес (bizwar)</center></font> криминальное ООС мероприятие, в котором члены фракции сражаются против другой фракции за какую-либо территорию или бизнес.<br><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"></span></p>
    <p><center>
        <span style="font-weight: 400;">
            <strong>Воевать за бизнес могут только мафии: </strong><br />
            La Cosa Nostra, Russian Mafia, Yakuza и Armenian Mafia.
        </span></center>
        <span style="font-weight: 400;"><br /></span>
        <center><span style="font-weight: 400;">
            <strong>Воевать за территорию могут только банды: </strong><br />
            Ballas, Familles, Bloods, Vagos и MG-13.<br />
        </span></center>
        <span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"></span>
    </p>
    <blockquote>
        <p>
            <span style="font-weight: 400;">
                Для того чтобы начать войну, необходимо прописать команду <b>/capture</b> или <b>/bizwar</b> в соответствующем месте, а чтобы принять участие как рядовой игрок, у вас во фракционном планшете в настройках должен быть
                соответствующий доступ.&nbsp;
            </span>
        </p>
        <p>
            <span style="font-weight: 400;">
                Все capture и bizwar проходят в другом мире, а это значит, что они являются ООС мероприятием, именно поэтому у вас есть возможность телепортироваться в зону один раз, с помощью команды <b>/tpcapture</b> и <b>/tpbizwar</b>.<br />
                <br />
            </span>
            <span style="font-weight: 400;">За каждую территорию или бизнес вся фракция получает 90$ в час, а&nbsp;</span>
            <span style="font-weight: 400;">при взятии всех территорий или бизнесов, лидера фракции награждают приятным бонусом за данное достижение.</span>
        </p>
        <br />
    </blockquote>

        
        <div style="display: flex; justify-content: center;">
            <img src={image} alt="Интерьер" class="contentImg"/> 
        </div>
</div>
