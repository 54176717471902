
export const typeBattle = [
    "Больше убийств",
    "Больше очков",
    "Удержание территории",
    "До последнего выжившего"
]

export const composition = [
    "Без ограничений",
    "1x1",
    "2x2",
    "3x3",
    "4x4",
    "5x5",
    "6x6",
    "7x7",
    "8x8",
    "9x9",
    "10x10"
]

export const weaponsCategory = [
    'Без ограничений',
    'Ближний бой',
    'Пистолеты',
    'Дробовики',
    'Пистолеты пулеметы',
    'Штурмовые винтовки',
    'Снайперские винтовки'
];