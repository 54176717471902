<script>
    import image from '../../images/everyday.jpg'
</script>



<div class="text">
    <p><font size="6">Ежедневные награды</font> - это БЕЗУМНО крутая система, позволяющая КАЖДОМУ игроку получать что-нибудь вкусненькое каждый день! Кейсы, машины, деньги, редбаксы - всё для тебя!</p><br>
    <p><strong><center>Что можно получить в ежедневной награде?</center></strong></p>
    <center>
    <ul>
    <li><span style="font-weight: 400;">- Донат</span></li>
    <li><span style="font-weight: 400;">- Кейсы</span></li>
    <li><span style="font-weight: 400;">- Игровая валюта</span></li>
    <li><span style="font-weight: 400;">- Транспорт</span><span style="font-weight: 400;"></span></li>
    <li><span style="font-weight: 400;">- VIP</span></li>
    <li><span style="font-weight: 400;">- Одежда</span></li>
    </ul></center>
    <p><br />
        <center>Посмотреть список наград можно через меню статистики на клавишу "I" &gt; ежедневные награды. Как только вы отыграете 6 часов в день, вам необходимо нажать кнопку "получить". Полученный предмет будет отправлен в инвентарь, либо на склад.<br /></center><strong></strong></p><br>
    <div style="display: flex; justify-content: center;">
        <img src={image} alt="Интерьер" class="contentImg"/> 
    </div>
    <center>
    <p><strong><br />Какие награды еще есть?</strong><strong><br /></strong></p>
    <blockquote>
    <p><strong><span style="font-weight: 400;">Каждый день за определенное количество часов, помимо ежедневных наград - выдаются кейсы и редбаксы (донат валюта).</span></strong></p><br>
    <ul>
    <li><strong><span style="font-weight: 400;"> Отыграв 2/4/8 часов вы получаете 1/2/4 редбакса.</span></strong></li>
    <li><strong><span style="font-weight: 400;"> Отыграв 3/5/8 часов вы получаете бесплатные кейсы, которые можно открыть через меню F9.</span></strong></li>
    <li><strong><span style="font-weight: 400;"> За 300 отыграннах часов в месяц вы получите дополнительную награду в виде 500 редбаксов.</span></strong></li>
    </ul>
    </center>
</div>

