<svg xmlns="http://www.w3.org/2000/svg" width="403.47" height="100.5" viewBox="0 0 403.47 100.5">
  <defs>
    <style>
      .cls-1 {
        fill: #bfbfbf;
        fill-opacity: 0.04;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="farm6" class="cls-1" d="M4254,1282l-187-2-32,4-15,2-103,20-59-58,22-16,53-14,50-6,57,4,51,2,92,22,75,15-3,28" transform="translate(-3856.78 -1208.88)"/>
</svg>
