<svg xmlns="http://www.w3.org/2000/svg" width="93.69" height="117.57" viewBox="0 0 93.69 117.57">
  <defs>
    <style>
      .cls-1 {
        fill: #dadada;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="theatre2" class="cls-1" d="M3925,4782c-3.25-.11-23,4-23,4h-14l-25,22-16-16-10-26,2-30,4-8,31,8-7-23v-8l24-8,14,2,8,27,11,53S3928.25,4782.11,3925,4782Z" transform="translate(-3834 -4693.81)"/>
</svg>
