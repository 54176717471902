<svg xmlns="http://www.w3.org/2000/svg" width="299.53" height="329.69" viewBox="0 0 299.53 329.69">
  <defs>
    <style>
      .cls-1 {
        fill: #bfbfbf;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="farm4" class="cls-1" d="M5186,2391q-10.995-.495-22-1-40.995,38.505-82,77-32.505-16.995-65-34l-7-23-59-50-6-51-50-59,109-93,25-12,10-1,136,151q2.505,6.495,5,13,4.005,7.5,8,15,1.005,6.495,2,13Q5188.005,2363.5,5186,2391Z" transform="translate(-4893.44 -2141.03)"/>
</svg>
