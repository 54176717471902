<svg xmlns="http://www.w3.org/2000/svg" width="115.1" height="62.81" viewBox="0 0 115.1 62.81">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="offroadrent" class="cls-1" d="M2431,5623l-38,10-48-39,112-18Z" transform="translate(-2343.31 -5573.75)"/>
</svg>
