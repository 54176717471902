<svg xmlns="http://www.w3.org/2000/svg" width="73.9" height="68.12" viewBox="0 0 73.9 68.12">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="rynok" class="cls-1" d="M2626,6001l60,50-15,13-57-47Z" transform="translate(-2612.19 -5999.41)"/>
</svg>
