<svg xmlns="http://www.w3.org/2000/svg" width="544.19" height="257.94" viewBox="0 0 544.19 257.94">
  <defs>
    <style>
      .cls-1 {
        fill: #fff;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="airport2" class="cls-1" d="M4917,3306l-66,36-3,25-415,112,18,73,289-77-71,68,8,15,149-101,2-32,57-24,71,4q7.995,1.005,16,2c-8.43-16.96-16.95-33.36-26-50C4936.58,3339.67,4927.08,3322.95,4917,3306Z" transform="translate(-4430.19 -3303.72)"/>
</svg>
