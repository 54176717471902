<svg xmlns="http://www.w3.org/2000/svg" width="73.84" height="73.66" viewBox="0 0 73.84 73.66">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="gosshahta" class="cls-1" d="M3381,4149l-53,21-15-57,16,11,12,14,8,2,11-4,4-18-2-14,10-1,3,11Z" transform="translate(-3310.19 -4100.03)"/>
</svg>
