<svg xmlns="http://www.w3.org/2000/svg" width="150.19" height="172.37" viewBox="0 0 150.19 172.37">
  <defs>
    <style>
      .cls-1 {
        fill: #dadada;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="oil3" class="cls-1" d="M4682,6696l-136,40,37,128,15-3,45-17,23-21,14-29,10-45Z" transform="translate(-4542.75 -6693.25)"/>
</svg>
