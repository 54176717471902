<svg xmlns="http://www.w3.org/2000/svg" width="276.9" height="196" viewBox="0 0 276.9 196">
  <defs>
    <style>
      .cls-1 {
        fill: #bfbfbf;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="farm5" class="cls-1" d="M5059,2148q63,65.505,126,131l147-144-5-13-2-23-11-10-22,8-23,18-18,3-22-10-46-16-32-2-48,17Z" transform="translate(-5057.69 -2086.38)"/>
</svg>
