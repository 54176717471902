<svg xmlns="http://www.w3.org/2000/svg" width="325.97" height="415.62" viewBox="0 0 325.97 415.62">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="oil2" class="cls-1" d="M4838,7186l-33,9-25,6-21,12-9,5-25,3-17-4-35-3-56,16-50,11-20-14-5-8,44-29s15.5-9.19,17-12,19-25,19-25l18-52,7-57-52-162,31-18,33-16,29-5,17-11q13.5,0.495,27,1a12.94,12.94,0,0,1,6.88.05c4.44,1.34,6.81,4.98,8.12,6.95,4.48,6.74,9.13,13.76,13,20l10,18,22,6,22-2q10.005,1.995,20,4,7.005,4.005,14,8-1.005,19.995-2,40s-8.5,22.31-9,24,1,36,1,36l10,17,3,23,11,23,2,19s-30,17.81-31,20-9,19-9,19,1,13.81,1,17,10,21,10,21l7,20-2,11Zm12-294" transform="translate(-4541 -6829)"/>
</svg>
