<script>
    import image from '../../images/azart1.jpg'
	import image2 from '../../images/azart2.jpg'
    import image3 from '../../images/azart3.jpg'
    import image4 from '../../images/azart4.jpg'
    import image5 from '../../images/azart5.jpg'
</script>


<div class="text">
    <p><strong>Азартная игра</strong> - игра с целью выигрыша денег или иных материальных ценностей, в которой выигрыш полностью или в значительной степени зависит не от навыка играющих, а от случая.</p>
    <p><strong>В данный момент на сервере имеется 6 азартных игр:</strong> Кости, рулетка, лотерея, блекджек, скачки и игровые автоматы.<br /><br /></p>
    <p><font size="6"><strong>Кости</strong></font></p>
    <div style="display: flex; flex-direction: column;">
        <img src={image} alt="Интерьер" class="contentImg"/> 
    </div>
    <blockquote>
    <p>Является одной из самых простых азартных игр на сервере. Игра в кости доступна только в казино, для того чтобы сыграть с каким-нибудь игроком, вам необходимо ввести команду /dice [ID] [Сумма].</p>
    </blockquote>
    <p><font size="6"><strong>Рулетка</strong></font></p>
    <div style="display: flex; flex-direction: column;">
    <img src={image2} alt="Интерьер" class="contentImg"/> 
</div>
    <blockquote>
    <p>Еще одна игра, в ходе которой игроки делают ставки на какой-либо цвет или число, за одну игру доступно 3 ставки. Игра в рулетку доступна только в казино.&nbsp;</p>
    </blockquote>
    <p></p>
    <center>
    <ul>
    <li aria-level="1">Если вы поставили на 1 число и ваша ставка выиграла, она умножится на x36.</li>
    <li aria-level="1">Если вы поставили на 2 числа и ваша ставка выиграла, она умножится на x18.</li>
    </ul>
    <ul>
    <li aria-level="1">Если вы поставили на 3 числа и ваша ставка выиграла, она умножится на x12.</li>
    </ul>
    <ul>
    <li aria-level="1">Если вы поставили на 4 числа и ваша ставка выиграла, она умножится на x8.</li>
    </ul>
    <ul>
    <li aria-level="1">Если вы поставили на 5 чисел и ваша ставка выиграла, она умножится на x7.</li>
    </ul>
    <ul>
    <li aria-level="1">Если вы поставили на 6 чисел и ваша ставка выиграла, она умножится на x6.</li>
    </ul>
    <ul>
    <li aria-level="1">Если вы поставили на 12 чисел и ваша ставка выиграла, она умножится на x3.</li>
    </ul>
    <ul>
    <li aria-level="1">Если вы поставили на 18 чисел и ваша ставка выиграла, она умножится на x2.<br /><br><strong></strong></li>
    </ul>
</center>
    <p><font size="6"><strong>Блэкджек</strong></font></p>
    <div style="display: flex; flex-direction: column;">
    <img src={image3} alt="Интерьер" class="contentImg"/>
    </div> 
    <blockquote>
    <p>Сплит - если у игрока на руке две карты одного достоинства, то дилер предлагает игроку сплит, то есть разбив одной руки на две. В таком случае игрок делает ставку равную предыдущей. Дилер разделяет карты на две руки, раздает на каждую по одной карте и продолжает играть с игроком так, будто он изначально внес две ставки.<br /><br />Удвоение ставки - игрок может удвоить ставку и при этом получить ровно одну карту.<br /><br />Отказ от карты - игрок уже не может отказаться от карты которую взял.</p>
    </blockquote>
    <p><font size="6"><strong>Игровые автоматы</strong></font></p>
    <div style="display: flex; flex-direction: column;">
    <img src={image4} alt="Интерьер" class="contentImg"/>
    </div> 
    <blockquote>
    <p>Необходимо выбить как минимум 2 одинаковых изображения, в идеале - все сразу. Доступны в казино.</p>
    <ul>
    <li aria-level="1">Два одинаковых изображения &mdash; x2.</li>
    <li aria-level="1">Две семёрки &mdash; x4.</li>
    <li aria-level="1">Три одинаковых изображения &mdash; x6.</li>
    <li aria-level="1">Три семёрки &mdash; x10.<br /><br /></li>
    </ul>
    </blockquote>
    <p><font size="6"><strong>Скачки</strong></font></p>
    
    <p></p>
    <p></p>
    <blockquote>
    <p>На всех лошадей распространяется множитель приза x3, главная цель игрока - выбрать правильную лошадь и ждать результатов. Доступна в казино.<br /><br /></p>
    </blockquote>
    <p><font size="6"><strong>Лотерея</strong></font></p>
    <div style="display: flex; flex-direction: column;">
    <img src={image5} alt="Интерьер" class="contentImg"/>
    </div> 
    <blockquote>
    <p>Является организованной азартной игрой, в которой все зависит только от вашей удачи. Вы можете победить в ней купив как минимум 1 билет, либо имеется возможность выкупить сразу несколько билетов в больших количествах. Купить лотерейный билет можно в магазине 24/7 за несколько тысяч $. Розыгрыш происходит раз в час, каждый пейдей. Победитель лотереи отображается в глобальный чат.</p>
    </blockquote>
    <ul>
    <li aria-level="1">
    <blockquote>Прописав команду "/lottery" вы сможете узнать сколько билетов вы уже купили.</blockquote>
    </li>
    </ul>
    
</div>