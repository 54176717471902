<svg xmlns="http://www.w3.org/2000/svg" width="236.72" height="187.216" viewBox="0 0 236.72 187.216">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="forest5" class="cls-1" d="M3965,1055l-62,3-92-11-69-4-9-51,17-20,3-39,28-14V890l21-13,33,6,30,29,29,16,35,13,17,9-18,19,2,18,17,14,16,10-4,22Z" transform="translate(-3731.06 -874.094)"/>
</svg>
