<svg xmlns="http://www.w3.org/2000/svg" width="196.78" height="168.71" viewBox="0 0 196.78 168.71">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="shahta3" class="cls-1" d="M5096,3282l-132,40-34-65,159-98,33,74-34,14-4,19Z" transform="translate(-4927.69 -3156.91)"/>
</svg>
