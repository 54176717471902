<svg xmlns="http://www.w3.org/2000/svg" width="103.91" height="73.03" viewBox="0 0 103.91 73.03">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="buspark" class="cls-1" d="M4095,5966l-84,2-7-9,1-7,12-27,20-24,65,6Z" transform="translate(-4001.06 -5898.03)"/>
</svg>
