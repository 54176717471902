<svg xmlns="http://www.w3.org/2000/svg" width="57.34" height="87.85" viewBox="0 0 57.34 87.85">
  <defs>
    <style>
      .cls-1 {
        fill: #dadada;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="landbar" class="cls-1" d="M2764,6460l7,16-1,10-9-6-10,2,30,50,6-7-4-9,11,6,3,8,5,5,5-14-4-21-16-31-19-17Z" transform="translate(-2752.22 -6450.53)"/>
</svg>
