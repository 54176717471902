<svg xmlns="http://www.w3.org/2000/svg" width="76.19" height="40.35" viewBox="0 0 76.19 40.35">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="rielt" class="cls-1" d="M3305,5348l-54,25-14-2-4-25,9-4,39-3,18,3Z" transform="translate(-3230.06 -5336.03)"/>
</svg>
