export default [
    "Безработный",
    "Электрик",
    "Почтальон",
    "Таксист",
    "Водитель автобуса",
    "Газонокосильщик",
    "Дальнобойщик",
    "Инкассатор",
    "Автомеханик",
]