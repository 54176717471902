<svg xmlns="http://www.w3.org/2000/svg" width="188.78" height="181.59" viewBox="0 0 188.78 181.59">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="arena" class="cls-1" d="M3589,6743l-74-1-34,24-11,24,6,46,14,19,18,16,43,24,20,8,11,14,27-7,24-26,20-40-9-37-29-42Z" transform="translate(-3467.06 -6738.97)"/>
</svg>
