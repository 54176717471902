<div class="policecomputer__comments">
    {#each new Array(20) as item}
        <div class="policecomputer__comments_element">
            <div class="box-between">
                <div class="box-flex">
                    <div class="policecomputer__button_circle mr-10">
                        <span class="bortovoiicon-police"></span>
                    </div>
                    <div class="mr-10">Arlando Pattinson</div>
                    <div class="gray">(2) Младший офицер</div>
                </div>
                <div class="gray">
                    12.09.2021 15:56
                </div>
            </div>
            <div class="policecomputer__comments_comment">
                Грубое общение с сотрудниками силовых структур, часто был замечен в компании людей в красной одежде. Множество раз был задержан и осужден за оружейные нападения и предумышленные убийства второй и третьей степени. 
            </div>
        </div>
    {/each}
</div>