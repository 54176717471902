<svg xmlns="http://www.w3.org/2000/svg" width="124.69" height="151.53" viewBox="0 0 124.69 151.53">
  <defs>
    <style>
      .cls-1 {
        fill: #bfbfbf;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="drugs2" class="cls-1" d="M6289,2616l-7,12-23,17h-24l-35-17-12-25-9-26-9-31,7-25,16-17,30-4,20,9,10,19,24,23,6,13v19Z" transform="translate(-6167.22 -2497.06)"/>
</svg>
