<script>
    import image from '../../images/mafii.jpg'
	import image2 from '../../images/mafii2.jpg'
</script>

<div class="text">
    <p><span style="font-weight: 400;">Для вас открывается деятельность, которую вы можете проворачивать вне закона. <strong>Похищения людей</strong>, <strong>грабежи</strong>, <strong>угоны</strong>, <strong>война за бизнесы</strong>, <strong>торговля оружием</strong>. Вы можете полноценно отыгрывать члена мафии, сурового, решительного или доброго и справедливого. Выбор лишь за вами, а путей множество.</span></p>
    <p><span style="font-weight: 400;">Всего в штате можно найти 4 мафии: <strong>Russian Mafia</strong>, <strong>Yakuza</strong>, <strong>La Cosa Nostra</strong>, <strong>Armenian Mafia</strong>. Некоторые из них могут быть в союзе а некоторые вести жесткую борьбу между собой.</span></p>
    <p><span style="font-weight: 400;">Одна из главных задач каждой мафии - <strong>контроль наибольшего количества бизнесов в штате</strong>, за один находящийся бизнес под контролем мафии, они получают <em>90</em>$. Также всем <strong>мафиям необходимо</strong>&nbsp;вести активную борьбу с государственными организациям, мешая им поставлять материалы и медикаменты, в последствии забирая их себе.</span></p>
    <p><span style="font-weight: 400;">Нелегальные предметы которые помогают в ограблениях, похищениях и других криминальных делах, бандиты смогут купить на "<strong>Чёрном рынке</strong>"</span></p>
    <p><span style="font-weight: 400;"><strong>Наборы</strong> в мафии проходят через подачу объявлений, если у вас появилось желание попасть в одну из них, то не забывайте, что вы должны полностью соответствовать её внутренним правилам.</span></p>
    <p><strong>Команды</strong>
        <br /><strong>/follow</strong> - вести за собой
        <br /><strong>/openstock</strong> - открыть склад
        <br /><strong>/closestock</strong> - закрыть склад
        <br /><strong>/bizwar</strong> - начать захват бизнеса
        <br /><strong>/tpbizwar</strong> - телепортироваться в зону бизвара</p>
    <div style="display: flex; flex-direction: column;"><img src={image} alt="Криминальные фракции" class="contentImg"/><img src={image2} alt="Криминальные фракции" class="contentImg"/></div>


</div>