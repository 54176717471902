<svg xmlns="http://www.w3.org/2000/svg" width="193" height="446.97" viewBox="0 0 193 446.97">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="sklad3" class="cls-1" d="M3956,7730l-131,1-3-349,36-34h19l2-58,124,13,6,67-22,30-20,7Z" transform="translate(-3818.97 -7287.03)"/>
</svg>
