<svg xmlns="http://www.w3.org/2000/svg" width="639.28" height="382.41" viewBox="0 0 639.28 382.41">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="sklad4" class="cls-1" d="M3845,7290v19l-37,36-46,2-7-28-43-1v14l-14,8-8-12-4-8-37-4-3,6-21,1,1-22-33-3-48,43,3,4,11,5,14,4v11l-29,3-22,5-101,93-43-79,29-32-7-10,134-134-91-76,17-18,102,89,15-11-57-53,17-22,34-24h162l91,63,16-20-51-36-2-10s97,1.76,98,1,34-4,34-4l17,15,34-2,28,7,14,13-5,156-83-7-80-7Z" transform="translate(-3375.72 -7087.03)"/>
</svg>
