<svg xmlns="http://www.w3.org/2000/svg" width="333.97" height="274.57" viewBox="0 0 333.97 274.57">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="jobgason" class="cls-1" d="M3057,5631l-159-77-55-1-8-9-8-105,19-25,28-13,149-30,30,65,53,85,42,62,8,12-8,9-21,13-47,14-9,8-11-5Z" transform="translate(-2824.03 -5368.12)"/>
</svg>
