<script>
    import image from '../../images/airdrop1.jpg'
	import image3 from '../../images/airdrop2.jpg'
</script>



<div class="text">
    <p><span style="font-weight: 400;"><font size="6"><strong>AirDrop</strong></font> - это система воздушных поставок в отдельном мире, которые сбрасываются в случайном месте. В каждой поставке находится очень много разных и ценных вещей!&nbsp;AirDrop&nbsp;создан для тебя, если ты очень силен, умеешь стреляться и продумывать крутые тактики вместе со своей командой!<br /><br /></span><span style="font-weight: 400;"></span></p>
    <blockquote>
        <p>
            <center>
                <strong>Один AirDrop может содержать в себе:<br /></strong><span style="font-weight: 400;"> Большое количество оружия, от револьверов до снайперских винтовок и редкого оружия ближнего боя.</span>
                <span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"> От 5.000 материалов.</span><span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"> От 100 аптечек.</span>
                <span style="font-weight: 400;"><br /></span><span style="font-weight: 400;"> От 20 бронежилетов.</span><span style="font-weight: 400;"><br /></span>
                <span style="font-weight: 400;">
                    Уникальные маски или случайные маски из кейсов.<br />
                    Купоны на автомобили, в том числе из Elite AutoRoom.<br />
                </span>
            </center>
        </p>
        <br />
        <p>
            <span style="font-weight: 400;">
                Всего может выпасть от 1 до 4 ящиков. Для взлома необходима военная отмычка, чем больше - тем лучше!&nbsp; Оплата осуществляется сразу на всю фракцию / семью, а это значит, что если член фракции / семьи купил
                что-либо, то принять участие смогут и все остальные члены фракции / семьи!<br />
                <br />
            </span>
            <span style="font-weight: 400;"></span>
            <span style="font-weight: 400;">
                <center>
                Заказать AirDrop можно на черном рынке.<br />
                Заказать информацию о месте и времени поставки - 25.000$<br />
                Заказать сброс поставки прямо сейчас - 100.000$<br />
                <br /></center>
                <strong>/airdrop</strong> - узнать время и место сброса поставки, доступно после покупки чего-либо.
            </span>
            <span style="font-weight: 400;"><br /></span>
            <span style="font-weight: 400;">
                <br />
                Более подробную информацию можно узнать на черном рынке у специального NPC.<br>
            </span>
            <span style="font-weight: 400;"><br /></span>
        </p>
    </blockquote>

        
        <div style="display: flex; flex-direction: column;">
            <img src={image} alt="Интерьер" class="contentImg"/> 
            <img src={image3} alt="Интерьер" class="contentImg"/>
        </div>
</div>