<svg xmlns="http://www.w3.org/2000/svg" width="180.87" height="327.66" viewBox="0 0 180.87 327.66">
  <defs>
    <style>
      .cls-1 {
        fill: #d9d9d9;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="farm1" class="cls-1" d="M5697,2578l-61,25-25,4-71-280,51-45,50,116,53,22,19,69,2,61Z" transform="translate(-5537.19 -2282.75)"/>
</svg>
