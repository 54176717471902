<svg xmlns="http://www.w3.org/2000/svg" width="262.97" height="131.4" viewBox="0 0 262.97 131.4">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="forest3" class="cls-1" d="M2926,2636l-52,22-18,8-38-9-5,9-9,2-16-13-30-8-29-1-10-10,3-10-9-8-11-18s-17-23.5-19-25-17-10-17-10l52-24,12,33,22,7,46,5,27-7,21,3,13,3,35-8,12-3,20,32v30Z" transform="translate(-2666.03 -2540.16)"/>
</svg>
