<svg xmlns="http://www.w3.org/2000/svg" width="63.87" height="67.25" viewBox="0 0 63.87 67.25">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="_bikerent" data-name="bikerent" class="cls-1" d="M3040,6648s-0.69.5-1.89,1.35c-7.8,5.6-37.11,26.65-37.11,26.65l-21-33,34-30Z" transform="translate(-2977.94 -6611.5)"/>
</svg>
