export default {
    /* Pistols */
    100: 500,
    101: 500,
    102: 500,
    103: 500,
    104: 500,
    105: 500,
    106: 500,
    107: 500,
    108: 500,
    109: 500,
    110: 500,
    111: 500,
    112: 600,
    113: 600,
    114: 600,
    /* SMG */
    115: 1500,
    116: 1500,
    117: 1500,
    118: 1500,
    119: 1500,
    120: 2000,
    121: 2000,
    122: 1500,
    123: 1500,
    124: 1800,
    125: 2500,
    /* Rifles */
    126: 3000,
    127: 3000,
    128: 3000,
    129: 3000,
    130: 3000,
    131: 3000,
    132: 4000,
    133: 4000,
    134: 4000,
    135: 4000,
    265: 4000,
    /* Sniper */
    136: 400,
    137: 500,
    138: 500,
    139: 700,
    140: 700,
    /* Shotguns */
    141: 400,
    142: 400,
    143: 400,
    144: 400,
    145: 400,
    146: 400,
    147: 400,
    148: 400,
    149: 500,
    /* NEW WEAPONS */
    150: 9999,
    151: 9999,
    152: 9999,
    153: 9999,
    154: 9999,
    155: 9999,
    156: 9999,
    157: 9999,
    158: 9999,
    159: 9999,
    160: 9999,
    161: 9999,
    162: 9999,

    //Other

    234: 300,
    235: 1248,
    236: 2250,
    244: 1250,
    225: 100,
    229: 420,
    249: 1000,
}