<svg xmlns="http://www.w3.org/2000/svg" width="323" height="141.28" viewBox="0 0 323 141.28">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="forest1" class="cls-1" d="M4858,7177q15.495,9.495,31,19l-3,45-7,45-38-2-23,11-21,6-32-5-29,17-57-3-8-16-23-7-19,11-18-6-27-19h-11q-0.495-7.005-1-14,48.495-12.495,97-25,10.005-1.995,20-4l35,6q18-1.995,36-4,9.495-7.005,19-14l32-9,23-3q5.385-3.315,10.77-6.62c0.74-.46,1.49-0.92,2.23-1.38Q4852.505,7187.5,4858,7177ZM6045,2381,4848,7204" transform="translate(-4569 -7174.88)"/>
</svg>
