<svg xmlns="http://www.w3.org/2000/svg" width="398.32" height="326.35" viewBox="0 0 398.32 326.35">
  <defs>
    <style>
      .cls-1 {
        fill: #dadada;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="vetryanaya2" class="cls-1" d="M5419,4294l-114,30-61,17-125,61-11,11-76-214,37-6,68-20,21-15,17-17,30-13,191-35,29,3-7,78Z" transform="translate(-5029.34 -4090.03)"/>
</svg>
