<svg xmlns="http://www.w3.org/2000/svg" width="60.97" height="94.13" viewBox="0 0 60.97 94.13">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="darkshop" class="cls-1" d="M4255,6149l-7,16-44,4-4-87,54-1Z" transform="translate(-4197 -6078.12)"/>
</svg>
