<svg xmlns="http://www.w3.org/2000/svg" width="61.25" height="264.47" viewBox="0 0 61.25 264.47">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="sklad5" class="cls-1" d="M4227,7110l-13,32-20,18,26-258,29,1Z" transform="translate(-4190.72 -6899)"/>
</svg>
