<svg xmlns="http://www.w3.org/2000/svg" width="313.88" height="428.53" viewBox="0 0 313.88 428.53">
  <defs>
    <style>
      .cls-1 {
        fill: #dadada;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="vetryanaya1" class="cls-1" d="M5304,4731l47-27,48-68,24-100,2-75-2-143-6-9-111,27-40,14-48,19-63,32-31,27-7,36,50,108Z" transform="translate(-5114.12 -4306.16)"/>
</svg>
