<svg xmlns="http://www.w3.org/2000/svg" width="291.81" height="323.38" viewBox="0 0 291.81 323.38">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="casino" class="cls-1" d="M4614,5311l-53-31-35,48-65,30-17,23-31,33-76,97,37,24,13,8,7,4,5,11,16,26,17,12,29,2,24-14,11-14,104-168,6-24-4-21,23-37Z" transform="translate(-4335.28 -5277.81)"/>
</svg>
