<svg xmlns="http://www.w3.org/2000/svg" width="88.15" height="56.22" viewBox="0 0 88.15 56.22">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="shahta2" class="cls-1" d="M5050,3364l-62,35a89.506,89.506,0,0,1-21-38l41-12,36,5Z" transform="translate(-4964.19 -3346.06)"/>
</svg>
