﻿export const ItemType = {
	None: 0,
	Clothes: 1,
	Weapons: 2,
	MeleeWeapons: 3,
	Ammo: 4,
	Alco: 5,
	Eat: 6,
	Water: 7,
	Modification: 8,
	Cases: 9,
}

export const ItemId = {
	Debug: 0,
	HealthKit: 1,
	GasCan: 2,
	Crisps: 3,
	Beer: 4,
	Pizza: 5,
	Burger: 6,
	HotDog: 7,
	Sandwich: 8,
	eCola: 9,
	Sprunk: 10,
	Lockpick: 11,
	BagWithMoney: 12,
	Material: 13,
	Drugs: 14,
	BagWithDrill: 15,
	ArmyLockpick: 16,
	Pocket: 17,
	Cuffs: 18,
	CarKey: 19,
	RusDrink1: 20,
	RusDrink2: 21,
	RusDrink3: 22,
	YakDrink1: 23,
	YakDrink2: 24,
	YakDrink3: 25,
	LcnDrink1: 26,
	LcnDrink2: 27,
	LcnDrink3: 28,
	ArmDrink1: 29,
	ArmDrink2: 30,
	ArmDrink3: 31,
	Present: 40,
	KeyRing: 41,
	Pistol: 100,
	CombatPistol: 101,
	Pistol50: 102,
	SNSPistol: 103,
	HeavyPistol: 104,
	VintagePistol: 105,
	MarksmanPistol: 106,
	Revolver: 107,
	APPistol: 108,
	StunGun: 109,
	FlareGun: 110,
	DoubleAction: 111,
	PistolMk2: 112,
	SNSPistolMk2: 113,
	RevolverMk2: 114,
	MicroSMG: 115,
	MachinePistol: 116,
	SMG: 117,
	AssaultSMG: 118,
	CombatPDW: 119,
	MG: 120,
	CombatMG: 121,
	Gusenberg: 122,
	MiniSMG: 123,
	SMGMk2: 124,
	CombatMGMk2: 125,
	AssaultRifle: 126,
	CarbineRifle: 127,
	AdvancedRifle: 128,
	SpecialCarbine: 129,
	BullpupRifle: 130,
	CompactRifle: 131,
	AssaultRifleMk2: 132,
	CarbineRifleMk2: 133,
	SpecialCarbineMk2: 134,
	BullpupRifleMk2: 135,
	SniperRifle: 136,
	HeavySniper: 137,
	MarksmanRifle: 138,
	HeavySniperMk2: 139,
	MarksmanRifleMk2: 140,
	PumpShotgun: 141,
	SawnOffShotgun: 142,
	BullpupShotgun: 143,
	AssaultShotgun: 144,
	Musket: 145,
	HeavyShotgun: 146,
	DoubleBarrelShotgun: 147,
	SweeperShotgun: 148,
	PumpShotgunMk2: 149,
	RayPistol: 150,
	CeramicPistol: 151,
	NavyRevolver: 152,
	RayCarbine: 153,
	GrenadeLauncher: 154,
	RPG: 155,
	Minigun: 156,
	Firework: 157,
	Railgun: 158,
	HomingLauncher: 159,
	GrenadeLauncherSmoke: 160,
	CompactGrenadeLauncher: 161,
	Widowmaker: 162,
	Knife: 180,
	Nightstick: 181,
	Hammer: 182,
	Bat: 183,
	Crowbar: 184,
	GolfClub: 185,
	Bottle: 186,
	Dagger: 187,
	Hatchet: 188,
	KnuckleDuster: 189,
	Machete: 190,
	Flashlight: 191,
	SwitchBlade: 192,
	PoolCue: 193,
	Wrench: 194,
	BattleAxe: 195,
	PistolAmmo: 200,
	SMGAmmo: 201,
	RiflesAmmo: 202,
	SniperAmmo: 203,
	ShotgunsAmmo: 204,
	Snowball: 205,
	cVarmod: 206,
	cClip: 207,
	cSuppressor: 208,
	cScope: 209,
	cMuzzlebrake: 210,
	cBarrel: 211,
	cFlashlight: 212,
	cGrip: 213,
	cCamo: 214,
	HalloweenCoin: 215,
	Firework1: 216,
	Firework2: 217,
	Firework3: 218,
	Firework4: 219,
	CarCoupon: 220,
	MerryChristmasCoin: 221,
	Bear: 222,
	Note: 223,
	LoveNote: 224,
	Vape: 225,
	Rose: 226,
	Barbell: 227,
	Binoculars: 228,
	Bong: 229,
	Umbrella: 230,
	Camera: 231,
	Microphone: 232,
	Guitar: 233,
	Pickaxe1: 234,
	Pickaxe2: 235,
	Pickaxe3: 236,
	Coal: 237,
	Iron: 238,
	Gold: 239,
	Sulfur: 240,
	Emerald: 241,
	Ruby: 242,
	Radio: 243,
	WorkAxe: 244,
	WoodOak: 245,
	WoodMaple: 246,
	WoodPine: 247,
	Boombox: 248,
	Hookah: 249,
	Case0: 250,
	Case1: 251,
	Case2: 252,
	Case3: 253,
	Case4: 254,
	Case5: 255,
	Case6: 256,
	Case7: 257,
	Case8: 258,
	Case9: 259,
	Case10: 260,
	Case11: 261,
	Case12: 262,
	Ball: 263,
	SummerCoin: 264,
	Case13: 265,
	MilitaryRifle: 266,
	CandyCane: 267,
	Case14: 268,
	Qr: 269,
	QrFake: 270,
	SimCard: 271,
	VehicleNumber: 272,
	Bint: 273,
	Cocaine: 274,
	Rub100: 275,
	Rub200: 276,
	Rub500: 277,
	Rub1000: 278,
	RadioInterceptor: 279,
	Epinephrine: 280,
	Case15: 281,
	Case16: 282,
	Case17: 283,
	Case18: 284,
	Case19: 285,
	Case20: 286,
	Case21: 287,
	AppleCoin: 288,
	Fire: 289,
	Matras: 290,
	Tent: 291,
	Lezhak: 292,
	Towel: 293,
	Flag: 294,
	Barrell: 295,
	Surf: 296,
	Vedro: 297,
	Flagstok: 298,
	Tenttwo: 299,
	Polotence: 300,
	Beachbag: 301,
	Zontik: 302,
	Zontiktwo: 303,
	Zontikthree: 304,
	Closedzontik: 305,
	Vball: 306,
	Bball: 307,
	Boomboxxx: 308,
	Table: 309,
	Tabletwo: 310,
	Tablethree: 311,
	Tablefour: 312,
	Chair: 313,
	Chairtwo: 314,
	Chaierthree: 315,
	Chaierfour: 316,
	Chairtable: 317,
	Korzina: 318,
	Light: 319,
	Alco: 320,
	Alcotwo: 321,
	Alcothree: 322,
	Alcofour: 323,
	Cocktail: 324,
	Cocktailtwo: 325,
	Fruit: 326,
	Fruittwo: 327,
	Packet: 328,
	Buter: 329,
	Patatoes: 330,
	Coffee: 331,
	Podnosfood: 332,
	Bbqtwo: 333,
	Bbq: 334,
	Vaza: 335,
	Flagwtokk: 336,
	Flagau: 337,
	Flagbr: 338,
	Flagch: 339,
	Flagcz: 340,
	Flageng: 341,
	Flageu: 342,
	Flagfr: 343,
	Flagger: 344,
	Flagire: 345,
	Flagisr: 346,
	Flagit: 347,
	Flagjam: 348,
	Flagjap: 349,
	Flagmex: 350,
	Flagnet: 351,
	Flagnig: 352,
	Flagnorw: 353,
	Flagpol: 354,
	Flagrus: 355,
	Flagbel: 356,
	Flagscot: 357,
	Flagscr: 358,
	Flagslovak: 359,
	Flagslov: 360,
	Flagsou: 361,
	Flagspain: 362,
	Flagswede: 363,
	Flagswitz: 364,
	Flagturk: 365,
	Flaguk: 366,
	Flagus: 367,
	Flagwales: 368,
	Flagfin: 369,
	Flowerrr: 370,
	Konus: 371,
	Konuss: 372,
	Otboynik1: 373,
	Otboynik2: 374,
	Dontcross: 375,
	Stop: 376,
	NetProezda: 377,
	Kpp: 378,
	Zabor1: 379,
	Zabor2: 380,
	Airlight: 381,
	Camera1: 382,
	Camera2: 383,
	TacticalRifle: 384,
	PrecisionRifle: 385,
	CombatShotgun: 386,
	HeavyRifle: 387,
	NeonStick: 388,
	Glowstick: 389,
	Giftcoin: 390,
	CombatRifle: 391,
	Glock: 392,
	Bracelets: -15,
	Watches: -14,
	Glasses: -13,
	Hat: -12,
	Top: -11,
	Decals: -10,
	BodyArmor: -9,
	Undershit: -8,
	Jewelry: -7,
	Feet: -6,
	Bag: -5,
	Leg: -4,
	Gloves: -3,
	Ears: -2,
	Mask: -1
}

export const itemsInfo = {
	[ItemId.Mask]: {"Name":"Маска","Description":"Помогает скрыть личность, но в тоже время служит украшением. Может быть сорвана полицейским.","Icon":"inv-item-mask","Type":"Одежда","Stack":1,"functionType":1},
	[ItemId.Gloves]: {"Name":"Перчатки","Description":"Согреют тебя холодной зимой.","Icon":"inv-item-glove","Type":"Одежда","Stack":1,"functionType":1},
	[ItemId.Ears]: {"Name":"Наушники","Description":".","Icon":"inv-item-ears","Type":"Одежда","Stack":1,"functionType":1},
	[ItemId.Leg]: {"Name":"Штаны","Description":"Не дадут тебе замёрзнуть.","Icon":"inv-item-shorts","Type":"Одежда","Stack":1,"functionType":1},
	[ItemId.Bag]: {"Name":"Сумка","Description":"Позволяет переносить в себе любые предметы.","Icon":"inv-item-backpack","Type":"Одежда","Stack":1,"functionType":1},
	[ItemId.Feet]: {"Name":"Обувь","Description":"Современная модель, которая никогда тебя не подведёт.","Icon":"inv-item-sneakers","Type":"Одежда","Stack":1,"functionType":1},
	[ItemId.Jewelry]: {"Name":"Аксессуар","Description":"Позволяет улучшить внешний вид персонажа.","Icon":"inv-item-necklace","Type":"Одежда","Stack":1,"functionType":1},
	[ItemId.Undershit]: {"Name":"Нижняя одежда","Description":"Может быть надета под верхней одеждой, придаёт стильный вид твоему персонажу.","Icon":"inv-item-shirt","Type":"Одежда","Stack":1,"functionType":1},
	[ItemId.BodyArmor]: {"Name":"Бронежилет","Description":"Служит средством защиты персонажа, способен впитать 100 урона, прежде чем сломается.","Icon":"inv-item-armor","Type":"Одежда","Stack":1,"functionType":1},
	[ItemId.Decals]: {"Name":"Украшения","Description":"Позволяют улучшить внешний вид персонажа.","Icon":"inv-item-clock","Type":"Одежда","Stack":1,"functionType":1},
	[ItemId.Top]: {"Name":"Верхняя одежда","Description":"Может быть надета над нижней одеждой, придаёт стильный вид твоему персонажу.","Icon":"inv-item-jacket","Type":"Одежда","Stack":1,"functionType":1},
	[ItemId.Hat]: {"Name":"Головной убор","Description":"Спасёт тебя от солнечного удара в жаркий день.","Icon":"inv-item-cap","Type":"Одежда","Stack":1,"functionType":1},
	[ItemId.Glasses]: {"Name":"Очки","Description":"Защищат от солнца в самый солнечный день.","Icon":"inv-item-glasses","Type":"Одежда","Stack":1,"functionType":1},
	[ItemId.Bracelets]: {"Name":"Браслет","Description":"Позволяют улучшить внешний вид персонажа.","Icon":"inv-item-bracelet","Type":"Одежда","Stack":1,"functionType":1},
	[ItemId.Watches]: {"Name":"Часы","Description":"Показывает статусность твоего персонажа.","Icon":"inv-item-clock","Type":"Одежда","Stack":1,"functionType":1},
	[ItemId.Debug]: {"Name":"None","Description":"","Icon":"","Type":"","Stack":0,"functionType":0},
	[ItemId.BagWithDrill]: {"Name":"Сумка с дрелью","Description":"Используется для взлома хранилища.","Icon":"inv-item-Bag-drill","Type":"Остальное","Stack":1,"functionType":0},
	[ItemId.GasCan]: {"Name":"Канистра","Description":"Можно заправить транспортное средство.","Icon":"inv-item-gasoline","Type":"Инструменты","Stack":2,"functionType":0},
	[ItemId.Crisps]: {"Name":"Чипсы","Description":"Закуска, восстанавливает 30% здоровья.","Icon":"inv-item-Chips","Type":"Еда","Stack":4,"functionType":6},
	[ItemId.Beer]: {"Name":"Пиво","Description":"Пивка для рывка!","Icon":"inv-item-beer","Type":"Вода","Stack":5,"functionType":7},
	[ItemId.Pizza]: {"Name":"Пицца","Description":"Ммм... Пицца... Восстанавливает 30% здоровья.","Icon":"inv-item-pizza","Type":"Еда","Stack":3,"functionType":6},
	[ItemId.Burger]: {"Name":"Бургер","Description":"Булка с котлеткой, восстанавливает 30% здоровья.","Icon":"inv-item-burger","Type":"Еда","Stack":4,"functionType":6},
	[ItemId.HotDog]: {"Name":"Хот-Дог","Description":"Булка с сосиской, восстанавливает 30% здоровья.","Icon":"inv-item-hot-dog","Type":"Еда","Stack":5,"functionType":6},
	[ItemId.Sandwich]: {"Name":"Сэндвич","Description":"Несколько ломтиков хлеба и мяса, восстанавливает 30% здоровья.","Icon":"inv-item-sandwich","Type":"Еда","Stack":7,"functionType":6},
	[ItemId.eCola]: {"Name":"eCola","Description":"Безалкогольный газированный напиток, восстанавливает 10% здоровья.","Icon":"inv-item-eCola","Type":"Вода","Stack":5,"functionType":7},
	[ItemId.Sprunk]: {"Name":"Sprunk","Description":"Сильногазированный прохладительный напиток, восстанавливает 10% здоровья.","Icon":"inv-item-eCola","Type":"Вода","Stack":5,"functionType":7},
	[ItemId.Lockpick]: {"Name":"Отмычка для замков","Description":"Инструмент для вскрытия замков без ключа.","Icon":"inv-item-picklock","Type":"Инструмент","Stack":10,"functionType":0},
	[ItemId.BagWithMoney]: {"Name":"Сумка с деньгами","Description":"Туда можно сложить все деньги после ограбления 24/7.","Icon":"inv-item-briefcase","Type":"Остальное","Stack":1,"functionType":0},
	[ItemId.Material]: {"Name":"Материалы","Description":"Нужны для создания оружия и патронов.","Icon":"inv-item-fraction","Type":"Остальное","Stack":4000,"functionType":0},
	[ItemId.Drugs]: {"Name":"Наркотики","Description":"Волшебный чай накладывает эффект наркотического опьянения.","Icon":"inv-item-marijuana","Type":"Остальное","Stack":50,"functionType":0},
	[ItemId.HealthKit]: {"Name":"Аптечка","Description":"Нужна для лечения, можно использовать 1 раз в 5 минут.","Icon":"inv-item-medical-kit","Type":"Остальное","Stack":10,"functionType":0},
	[ItemId.ArmyLockpick]: {"Name":"Военная отмычка","Description":"Инструмент для вскрытия замков военных автомобилей.","Icon":"inv-item-picklock","Type":"Инструмент","Stack":10,"functionType":0},
	[ItemId.Pocket]: {"Name":"Мешок","Description":"Обычный мешок. Говорят, его можно надеть кому-то на голову...","Icon":"inv-item-bag","Type":"Инструмент","Stack":5,"functionType":0},
	[ItemId.Cuffs]: {"Name":"Стяжки","Description":"Позволяют связать человека с поднятыми руками.","Icon":"inv-item-stretching","Type":"Инструмент","Stack":5,"functionType":0},
	[ItemId.CarKey]: {"Name":"Ключи от машины","Description":"Инструмент, позволяющий открыть/закрыть автомобиль и завести его.","Icon":"inv-item-key","Type":"Инструмент","Stack":1,"functionType":0},
	[ItemId.Present]: {"Name":"Подарок","Description":"Содержит в себе что-то интересное! Открывай быстрее!","Icon":"inv-item-gift","Type":"Инструмент","Stack":1,"functionType":0},
	[ItemId.KeyRing]: {"Name":"Связка ключей","Description":"Собирает все ключи в одну большую связку и экономит место в карманах!","Icon":"inv-item-key-2","Type":"Инструмент","Stack":1,"functionType":0},
	[ItemId.RusDrink1]: {"Name":"<На корке лимона>","Description":"Алкогольный напиток с нотками лимона, накладывает эффект алкогольного опьянения.","Icon":"inv-item-On-lemon-peel","Type":"Спиртное","Stack":5,"functionType":5},
	[ItemId.RusDrink2]: {"Name":"<На бруснике>","Description":"Алкогольный напиток с легкой ноткой брусники, накладывает эффект алкогольного опьянения.","Icon":"inv-item-The-cranberries","Type":"Спиртное","Stack":5,"functionType":5},
	[ItemId.RusDrink3]: {"Name":"<Русский стандарт>","Description":"Старая добрая Русская водка, накладывает эффект алкогольного опьянения.","Icon":"inv-item-Russian-standard","Type":"Спиртное","Stack":5,"functionType":5},
	[ItemId.YakDrink1]: {"Name":"<Asahi>","Description":"Пиво с низким содержанием солода, накладывает эффект алкогольного опьянения.","Icon":"inv-item-Asahi","Type":"Спиртное","Stack":5,"functionType":5},
	[ItemId.YakDrink2]: {"Name":"<Midori>","Description":"Сладкий дынный ликер, накладывает эффект алкогольного опьянения.","Icon":"inv-item-Midori","Type":"Спиртное","Stack":5,"functionType":5},
	[ItemId.YakDrink3]: {"Name":"<Yamazaki>","Description":"Японский виски, накладывает эффект алкогольного опьянения.","Icon":"inv-item-Yamazaki","Type":"Спиртное","Stack":5,"functionType":5},
	[ItemId.LcnDrink1]: {"Name":"<Martini Asti>","Description":"Игристое вино, накладывает эффект алкогольного опьянения.","Icon":"inv-item-Martini-Asti","Type":"Спиртное","Stack":5,"functionType":5},
	[ItemId.LcnDrink2]: {"Name":"<Sambuca>","Description":"Крепкий ликер с приятным анисовым вкусом, накладывает эффект алкогольного опьянения.","Icon":"inv-item-Sambuca","Type":"Спиртное","Stack":5,"functionType":5},
	[ItemId.LcnDrink3]: {"Name":"<Campari>","Description":"Горький ликер на основе ароматических трав и фруктов.","Icon":"inv-item-Campari","Type":"Спиртное","Stack":5,"functionType":5},
	[ItemId.ArmDrink1]: {"Name":"<Дживан>","Description":"Армянский коньяк, накладывает эффект алкогольного опьянения.","Icon":"inv-item-alcohol","Type":"Спиртное","Stack":5,"functionType":5},
	[ItemId.ArmDrink2]: {"Name":"<Арарат>","Description":"Армянский коньяк с фруктово-цветочными нотками, накладывает эффект алкогольного опьянения.","Icon":"inv-item-ararat","Type":"Спиртное","Stack":5,"functionType":5},
	[ItemId.ArmDrink3]: {"Name":"<Noyan Tapan>","Description":"Армянский винный напиток, накладывает эффект алкогольного опьянения.","Icon":"inv-item-Noyan-Tapan","Type":"Спиртное","Stack":5,"functionType":5},
	[ItemId.Pistol]: {"Name":"Pistol","Description":"Стандартный пистолет, обойма вмещает в себя 12 патронов.","Icon":"inv-item-Pistol","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.CombatPistol]: {"Name":"Combat Pistol","Description":"Боевой пистолет, обойма вмещает в себя 12 патронов.","Icon":"inv-item-Pistol","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.Pistol50]: {"Name":"Pistol 50","Description":"Мощный пистолет 0.50 калибра, обойма вмещает в себя 9 патронов.","Icon":"inv-item-Pistol-50","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.SNSPistol]: {"Name":"SNS Pistol","Description":"Карманный пистолет 0.25 калибра, обойма вмещает в себя 6 патронов.","Icon":"inv-item-SNS-Pistol","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.HeavyPistol]: {"Name":"Heavy Pistol","Description":"Тяжелый пистолет, обойма вмещает в себя 18 патронов.","Icon":"inv-item-Heavy-Pistol","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.VintagePistol]: {"Name":"Vintage Pistol","Description":"Винтажный пистолет, обойма вмещает в себя 7 патронов.","Icon":"inv-item-Vintage-Pistol","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.MarksmanPistol]: {"Name":"Marksman Pistol","Description":"Марксманский пистолет, обойма вмещает в себя 1 патрон.","Icon":"inv-item-Marksman-Pistol","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.Revolver]: {"Name":"Revolver","Description":"Револьвер, обойма вмещает в себя 6 патронов.","Icon":"inv-item-Heavy-Revolver","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.APPistol]: {"Name":"AP Pistol","Description":"Бронебойный пистолет, обойма вмещает в себя 18 патронов.","Icon":"inv-item-AP-Pistol","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.StunGun]: {"Name":"Stun Gun","Description":"Электрошоковое оружие, обойма вмещает в себя 1 заряд.","Icon":"inv-item-Stun-Gun","Type":"Оружие ближнего боя","Stack":1,"functionType":2},
	[ItemId.FlareGun]: {"Name":"Flare Gun","Description":"Сигнальный пистолет.","Icon":"inv-item-Flare-Gun","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.DoubleAction]: {"Name":"Double Action Revolver","Description":"Самовзводный револьвер, обойма вмещает в себя 6 патронов.","Icon":"inv-item-Double-Action-Revolver","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.PistolMk2]: {"Name":"Pistol Mk2","Description":"Улучшенная версия обычного пистолета, обойма вмещает в себя 12 патронов.","Icon":"inv-item-Pistol-Mk-II","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.SNSPistolMk2]: {"Name":"SNSPistol Mk2","Description":"Улучшенная версия карманного пистолета, обойма вмещает в себя 6 патронов.","Icon":"inv-item-SNS-Pistol-Mk-II","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.RevolverMk2]: {"Name":"Heavy Revolver Mk2","Description":"Улучшенная версия стандартного револьвера, обойма вмещает в себя 6 патронов.","Icon":"inv-item-Heavy-Revolver-Mk-II","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.MicroSMG]: {"Name":"Micro SMG","Description":"Малогабаритный пистолет - пулемёт, обойма вмещает в себя 16 патронов.","Icon":"inv-item-Micro-SMG","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.MachinePistol]: {"Name":"Machine Pistol","Description":"Автоматический пистолет, обойма вмещает в себя 12 патронов.","Icon":"inv-item-Machine-Pistol","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.SMG]: {"Name":"SMG","Description":"Пистолет - пулемет, обойма вмещает в себя 30 патронов.","Icon":"inv-item-SMG","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.AssaultSMG]: {"Name":"Assault SMG","Description":"Штурмовое автоматическое оружие, обойма вмещает в себя 30 патронов.","Icon":"inv-item-Assault-SMG","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.CombatPDW]: {"Name":"Combat PDW","Description":"Малогабаритная штурмовая винтовка, обойма вмещает в себя 30 патронов.","Icon":"inv-item-Combat-PDW","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.MG]: {"Name":"MG","Description":"Тяжелый пулемет, обойма вмещает в себя 54 патронов.","Icon":"inv-item-MG","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.CombatMG]: {"Name":"Combat MG","Description":"Пулемёт специального назначения, обойма вмещает в себя 100 патронов.","Icon":"inv-item-Combat-MG","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.Gusenberg]: {"Name":"Gusenberg","Description":"Пистолет - пулемет, обойма вмещает в себя 30 патронов.","Icon":"inv-item-Gusenberg-Sweeper","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.MiniSMG]: {"Name":"Mini SMG","Description":"Малогабаритный пистолет - пулемёт, обойма вмещает в себя 20 патронов.","Icon":"inv-item-Mini-SMG","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.SMGMk2]: {"Name":"SMG Mk2","Description":"Улучшенный пистолет-пулемёт, обойма вмещает в себя 30 патронов.","Icon":"inv-item-SMG-Mk-II","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.CombatMGMk2]: {"Name":"Combat MG Mk2","Description":"Обновлённый единый пулемёт, вмещает в себя 100 патронов.","Icon":"inv-item-Combat-MG-Mk-II","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.AssaultRifle]: {"Name":"Assault Rifle","Description":"Штурмовая винтовка, обойма вмещает в себя 30 патронов.","Icon":"inv-item-Assault-Rifle","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.CarbineRifle]: {"Name":"Carbine Rifle","Description":"Американская полуавтоматическая винтовка, обойма вмещает в себя 30 патронов.","Icon":"inv-item-Carbine-Rifle","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.AdvancedRifle]: {"Name":"Advanced Rifle","Description":"Усовершенствованная штурмовая винтовка, обойма вмещает в себя 30 патронов.","Icon":"inv-item-Advanced-Rifle","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.SpecialCarbine]: {"Name":"Special Carbine","Description":"Штурмовая винтовка с меньшей отдачей, обойма вмещает в себя 30 патронов.","Icon":"inv-item-Special-Carbine","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.BullpupRifle]: {"Name":"Bullpup Rifle","Description":"Китайская штурмовая винтовка, обойма вмещает в себя 30 патронов.","Icon":"inv-item-Bullpup-Rifle","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.CompactRifle]: {"Name":"Compact Rifle","Description":"Укороченная винтовка, обойма вмещает в себя 30 патронов.","Icon":"inv-item-Compact-Rifle","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.AssaultRifleMk2]: {"Name":"Assault Rifle Mk2","Description":"Улучшенная штурмовая винтовка, обойма вмещает в себя 30 патронов.","Icon":"inv-item-Assault-Rifle-Mk-II","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.CarbineRifleMk2]: {"Name":"Carbine Rifle Mk2","Description":"Улучшеная полуавтоматическая винтовка, обойма вмещает в себя 30 патронов.","Icon":"inv-item-Carbine-Rifle-Mk-II","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.SpecialCarbineMk2]: {"Name":"Special Carbine Mk2","Description":"Улучшенная штурмовая винтовка с более коротким стволом, обойма вмещает в себя 30 патронов.","Icon":"inv-item-Special-Carbine-Mk-II","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.BullpupRifleMk2]: {"Name":"Bullpup Rifle Mk2","Description":"Улучшеная штурмовая винтовка из Китая, обойма вмещает в себя 30 патронов.","Icon":"inv-item-Bullpup-Rifle-Mk-II","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.MilitaryRifle]: {"Name":"Military Rifle","Description":"Усовершенствованная штурмовая винтовка, обойма вмещает в себя 30 патронов.","Icon":"inv-item-militaryrifle","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.SniperRifle]: {"Name":"Sniper Rifle","Description":"Снайперская винтовка, обойма вмещает в себя 10 патронов.","Icon":"inv-item-Sniper-Rifle","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.HeavySniper]: {"Name":"Heavy Sniper","Description":"Крупнокалиберная снайперская винтовка, обойма вмещает в себя 6 патронов.","Icon":"inv-item-Heavy-Sniper","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.MarksmanRifle]: {"Name":"Marksman Rifle","Description":"Марксманская снайперская винтовка, обойма вмещает в себя 8 патронов.","Icon":"inv-item-Marksman-Rifle","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.HeavySniperMk2]: {"Name":"Heavy Sniper Mk2","Description":"Улучшенная крупнокалиберная снайперская винтовка, обойма вмещает в себя 6 патронов.","Icon":"inv-item-Heavy-Sniper-Mk-II","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.MarksmanRifleMk2]: {"Name":"Marksman Rifle Mk2","Description":"Улучшенная марксманская снайперская винтовка, обойма вмещает в себя 8 патронов.","Icon":"inv-item-Marksman-Rifle-Mk-II","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.PumpShotgun]: {"Name":"Pump Shotgun","Description":"Тактический боевой помповый дробовик, обойма вмещает в себя 8 патронов.","Icon":"inv-item-Pump-Shotgun","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.SawnOffShotgun]: {"Name":"SawnOff Shotgun","Description":"Обрез, обойма вмещает в себя 8 патронов.","Icon":"inv-item-Sawed-Off-Shotgun","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.BullpupShotgun]: {"Name":"Bullpup Shotgun","Description":"Помповый дробовик, обойма вмещает в себя 14 патронов.","Icon":"inv-item-Bullpup-Shotgun","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.AssaultShotgun]: {"Name":"Assault Shotgun","Description":"Штурмовой дробовик, обойма вмещает в себя 8 патронов.","Icon":"inv-item-Assault-Shotgun","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.Musket]: {"Name":"Musket","Description":"Ручное огнестрельное оружие, обойма вмещает в себя 1 патрон.","Icon":"inv-item-Musket","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.HeavyShotgun]: {"Name":"Heavy Shotgun","Description":"Тяжелый шестизарядный дробовик, обойма вмещает в себя 6 патронов.","Icon":"inv-item-Heavy-Shotgun","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.DoubleBarrelShotgun]: {"Name":"Double Barrel Shotgun","Description":"Двуствольный дробовик, обойма вмещает в себя 2 патрона.","Icon":"inv-item-Double-Barrel-Shotgun","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.SweeperShotgun]: {"Name":"Sweeper Shotgun","Description":"Компактный скорострельный дробовик, обойма вмещает в себя 10 патронов.","Icon":"inv-item-Sweeper-Shotgun","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.PumpShotgunMk2]: {"Name":"Pump Shotgun Mk2","Description":"Улучшенный помповый дробовик, обойма вмещает в себя 8 патронов.","Icon":"inv-item-Pump-Shotgun-Mk-II","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.RayPistol]: {"Name":"Up-n-Atomizer","Description":"Футуристичный пистолет, по внешнему виду похож на инопланетный пистолет, не требует боеприпасов.","Icon":"inv-item-Vintage-Pistol","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.CeramicPistol]: {"Name":"Ceramic Pistol","Description":"Керамический пистолет, обойма вмещает в себя 12 патронов.","Icon":"inv-item-Combat-Pistol","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.NavyRevolver]: {"Name":"Navy Revolver","Description":"Армейский револьвер, обойма вмещает в себя 6 патрон.","Icon":"inv-item-Heavy-Revolver-Mk-II","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.RayCarbine]: {"Name":"Unholy Hellbringer","Description":"Футуристичная плазменная винтовка, имеет необычный внешний вид.","Icon":"NEEDTEXT","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.GrenadeLauncher]: {"Name":"Grenade Launcher","Description":"Легкий гранатомёт с полуавтоматическим функционалом, вмещает в себя до 10 боеприпасов.","Icon":"","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.RPG]: {"Name":"RPG","Description":"Ручной противотанковый гранатомёт, вмещает в себя 1 боеприпас.","Icon":"NEEDTEXT","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.Minigun]: {"Name":"Minigun","Description":"6-стовольный пулемет, имеет очень высокую скорострельность, а также вмещает в себя 595 боеприпасов.","Icon":"NEEDTEXT","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.Firework]: {"Name":"Firework Launcher","Description":"Пусковая установка для запуска фейерверков, поможет поднять настроение или устроить праздник.","Icon":"NEEDTEXT","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.Railgun]: {"Name":"Railgun","Description":"Рельсовое тяжелое оружие, имеет большой урон, а также вмещает в себя до 20 боеприпасов.","Icon":"NEEDTEXT","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.HomingLauncher]: {"Name":"Homing Launcher","Description":"Ракетная установка с инфракрасным наведением на цель, вмещает в себя 1 боеприпас.","Icon":"NEEDTEXT","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.GrenadeLauncherSmoke]: {"Name":"Grenade Launcher Smoke","Description":"Легкий гранатомёт, запускает вместо обычных гранат дымовые.","Icon":"NEEDTEXT","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.CompactGrenadeLauncher]: {"Name":"Compact Grenade Launcher","Description":"Компактный гранатомёт, вмещает в себя всего 1 боеприпас.","Icon":"NEEDTEXT","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.Widowmaker]: {"Name":"Widowmaker","Description":"Футуристичный плазменый пулемет, вмещает в себя до 9999 боеприпасов.","Icon":"NEEDTEXT","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.Knife]: {"Name":"Нож","Description":"Острый нож, которым можно порезаться.","Icon":"inv-item-Knife","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.Nightstick]: {"Name":"Дубинка","Description":"Нашли нарушителя порядка? Пора воспользоваться дубинкой.","Icon":"inv-item-Nightstick","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.Hammer]: {"Name":"Молоток","Description":"Молоток поможет вам в домашних делах.","Icon":"inv-item-Hammer","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.Bat]: {"Name":"Бита","Description":"Бита отлично подходит для игры в бейсбол.","Icon":"inv-item-Baseball-Bat","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.Crowbar]: {"Name":"Лом","Description":"Лом один из самых популярных инструментов.","Icon":"inv-item-crowbar","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.GolfClub]: {"Name":"Гольф клюшка","Description":"Позволит отлично провести время на поле для гольфа.","Icon":"inv-item-Golf-Club","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.Bottle]: {"Name":"Бутылка","Description":"Розочка.","Icon":"inv-item-Broken-Bottle","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.Dagger]: {"Name":"Кинжал","Description":"Очень хорошо подходит для коллекционеров редким оружием.","Icon":"inv-item-Antique-Cavalry-Dagger","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.Hatchet]: {"Name":"Топор","Description":"Нужно нарубить дров? Топор отличный помощник в этом.","Icon":"inv-item-Hatchet","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.KnuckleDuster]: {"Name":"Кастет","Description":"Отличный помощник в уличных боях.","Icon":"inv-item-Brass-Knuckles","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.Machete]: {"Name":"Мачете","Description":"Одно из самых эффективных оружий для выживания в диких джунглях. ","Icon":"inv-item-Machete","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.Flashlight]: {"Name":"Фонарик","Description":"Яркий свет, поможет вам если вы потерялись в темном лесу.","Icon":"inv-item-Flashlight","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.SwitchBlade]: {"Name":"Швейцарский нож","Description":"Складной нож, поможет вам в решении многих проблем.","Icon":"inv-item-Switchblade","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.PoolCue]: {"Name":"Кий","Description":"Отлично подойдет для игры в бильярд","Icon":"inv-item-Pool-Cue","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.Wrench]: {"Name":"Ключ","Description":"Ключ, самый верный помощник если что то сломалось.","Icon":"inv-item-Pipe-Wrench","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.BattleAxe]: {"Name":"Боевой топор","Description":"Нужно нарубить врагов? Топор отличный помощник в этом.","Icon":"inv-item-Battle-Axe","Type":"Оружие ближнего боя","Stack":1,"functionType":3},
	[ItemId.PistolAmmo]: {"Name":"Пистолетный калибр","Description":"Данные патроны отлично подойдут к вашему пистолету.","Icon":"inv-item-ammo-pistol","Type":"Патроны","Stack":100,"functionType":4},
	[ItemId.RiflesAmmo]: {"Name":"Автоматный калибр","Description":"Данные патроны отлично подходят для штурмовых винтовок.","Icon":"inv-item-ammo-average","Type":"Патроны","Stack":250,"functionType":4},
	[ItemId.ShotgunsAmmo]: {"Name":"Дробь","Description":"Подходят для любого вида дробовиков.","Icon":"inv-item-ammo-shotgun","Type":"Патроны","Stack":50,"functionType":4},
	[ItemId.SMGAmmo]: {"Name":"Малый калибр","Description":"Отлично подойдут к вашему пистолету-пулемёту и другому малокалиберному оружию.","Icon":"inv-item-ammo-small","Type":"Патроны","Stack":300,"functionType":4},
	[ItemId.SniperAmmo]: {"Name":"Снайперский калибр","Description":"Подходят для всех снайперских винтовок.","Icon":"inv-item-ammo-sniper","Type":"Патроны","Stack":48,"functionType":4},
	[ItemId.Snowball]: {"Name":"Снежный шарик","Description":"Кинь в кого-нибудь!","Icon":"inv-item-marijuana","Type":"Особое","Stack":1,"functionType":2},
	[ItemId.Ball]: {"Name":"Мячик","Description":"Для игр с питомцем","Icon":"inv-item-ball","Type":"Особое","Stack":1,"functionType":2},
	[ItemId.cVarmod]: {"Name":"Раскраска на оружие","Description":"С помощью этого компонента можно разукрасить оружие, сделав его внешне более привлекательным.","Icon":"inv-item-Varmod","Type":"Особое","Stack":1,"functionType":8},
	[ItemId.cClip]: {"Name":"Магазин на оружие","Description":"Улучшенный магазин позволит расширить максимальный боезапас патронов в оружии.","Icon":"inv-item-Clips","Type":"Особое","Stack":1,"functionType":8},
	[ItemId.cSuppressor]: {"Name":"Глушитель на оружие","Description":"Глушитель, будучи установленным на оружие, приглушает звук выстрелов и позволяет совершать бесшумные убийства.","Icon":"inv-item-Suppressors","Type":"Особое","Stack":1,"functionType":8},
	[ItemId.cScope]: {"Name":"Прицел на оружие","Description":"Прицел, будучи установленным, позволит владельцу оружия точнее выцеливать своих врагов (Используйте колёсико мыши)","Icon":"inv-item-Scopes","Type":"Особое","Stack":1,"functionType":8},
	[ItemId.cMuzzlebrake]: {"Name":"Дульный тормоз на оружие","Description":"Данный компонент уменьшает отдачу оружия.","Icon":"inv-item-Muzzle-Brakes","Type":"Особое","Stack":1,"functionType":8},
	[ItemId.cBarrel]: {"Name":"Ствол на оружие","Description":"Улучшенный ствол на оружие позволяет увеличить точность и урон с оружия.","Icon":"inv-item-Barrels","Type":"Особое","Stack":1,"functionType":8},
	[ItemId.cFlashlight]: {"Name":"Фонарик на оружие","Description":"Фонарик, установленный на оружие - отличный способ найти своих врагов в темноте. (Включается на Е)","Icon":"inv-item-Flashlights","Type":"Особое","Stack":1,"functionType":8},
	[ItemId.cGrip]: {"Name":"Рукоять на оружие","Description":"Улучшенная рукоять оружия обеспечивает более комфортную стрельбу.","Icon":"inv-item-Grips","Type":"Особое","Stack":1,"functionType":8},
	[ItemId.cCamo]: {"Name":"Камуфляж для оружия","Description":"С помощью расцветки можно разнообразить внешний вид оружия.","Icon":"inv-item-Varmod","Type":"Особое","Stack":1,"functionType":8},
	[ItemId.HalloweenCoin]: {"Name":"Halloween 2020 Coin","Description":"Хеллоуинская монета, за которую можно получить особые призы в дни проведения мероприятия.","Icon":"inv-item-EventCoin","Type":"Особое","Stack":999999,"functionType":0},
	[ItemId.Firework1]: {"Name":"Фейерверк обычный","Description":"Красивый фейерверк.","Icon":"inv-item-Firework1","Type":"Развлечение","Stack":1,"functionType":0},
	[ItemId.Firework2]: {"Name":"Фейерверк звезда","Description":"Красивый фейерверк в виде звезды.","Icon":"inv-item-Firework2","Type":"Развлечение","Stack":1,"functionType":0},
	[ItemId.Firework3]: {"Name":"Фейерверк взрывной","Description":"Красивый фейерверк в виде взрыва.","Icon":"inv-item-Firework3","Type":"Развлечение","Stack":1,"functionType":0},
	[ItemId.Firework4]: {"Name":"Фейерверк фонтан","Description":"Красивый фейерверк в виде фонтана.","Icon":"inv-item-Firework4","Type":"Развлечение","Stack":1,"functionType":0},
	[ItemId.CarCoupon]: {"Name":"Купон на машину","Description":"Содержит в себе автомобиль, который будет доставлен в гараж после активации.","Icon":"inv-item-car","Type":"Купоны","Stack":1,"functionType":0},
	[ItemId.MerryChristmasCoin]: {"Name":"Christmas 2021 Coin","Description":"Рождественская монета, за которую можно получить особые призы в дни проведения мероприятия.","Icon":"inv-item-EventCoin","Type":"Особое","Stack":999999,"functionType":0},
	[ItemId.Bear]: {"Name":"Медведь Любви","Description":"Подари приятному человеку!","Icon":"inv-item-teddy-bear","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Note]: {"Name":"Записка","Description":"С помощью записки можно оставить послание, передать какой-то секрет или другую важную информацию.","Icon":"inv-item-Note","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.LoveNote]: {"Name":"Любовная записка","Description":"Валентинка поможет описать свои теплые чувства к человеку.","Icon":"inv-item-LoveNote","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Vape]: {"Name":"Вейп","Description":"Напас сочного вейпика всегда поднимает настроение.","Icon":"inv-item-Vape","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Rose]: {"Name":"Роза","Description":"Прекрасный подарок для любимого человека!","Icon":"inv-item-rose","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Barbell]: {"Name":"Гриф","Description":"Для желающих подравнять свою эстетику.","Icon":"inv-item-barbell","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Binoculars]: {"Name":"Бинокль","Description":"Позволяет видеть обьекты на дальнем расстоянии.","Icon":"inv-item-binoculars","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Bong]: {"Name":"Бонг","Description":"Удобная штучка для употребления травки.","Icon":"inv-item-bong","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Umbrella]: {"Name":"Зонтик","Description":"Позволит укрыться от дождя и придаст стиля.","Icon":"inv-item-umbrella","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Camera]: {"Name":"Камера","Description":"Свет... Камера... Мотор!","Icon":"inv-item-camera","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Microphone]: {"Name":"Микрофон","Description":"Предмет настоящего оратора.","Icon":"inv-item-microphone","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Guitar]: {"Name":"Гитара","Description":"Так и просится в руки... Сыграй что-нибудь!","Icon":"inv-item-guitar","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Pickaxe1]: {"Name":"Обычная кирка","Description":"С помощью этой штуки можно работать на шахте.","Icon":"inv-item-pickaxe1","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Pickaxe2]: {"Name":"Усиленная кирка","Description":"С помощью этой штуки можно мощно работать на шахте.","Icon":"inv-item-pickaxe2","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Pickaxe3]: {"Name":"Профессиональная кирка","Description":"С помощью этой штуки можно профессионально работать на шахте.","Icon":"inv-item-pickaxe3","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Coal]: {"Name":"Ископаемый уголь","Description":"Ископаемое из шахты.","Icon":"inv-item-coal","Type":"Особое","Stack":250,"functionType":0},
	[ItemId.Iron]: {"Name":"Железная руда","Description":"Ископаемое из шахты.","Icon":"inv-item-iron","Type":"Особое","Stack":250,"functionType":0},
	[ItemId.Gold]: {"Name":"Золотая руда","Description":"Ископаемое из шахты.","Icon":"inv-item-gold","Type":"Особое","Stack":250,"functionType":0},
	[ItemId.Sulfur]: {"Name":"Серная руда","Description":"Редкое ископаемое из шахты.","Icon":"inv-item-sulfur","Type":"Особое","Stack":250,"functionType":0},
	[ItemId.Emerald]: {"Name":"Изумруд","Description":"Крайне редкое ископаемое из шахты.","Icon":"inv-item-emerald","Type":"Особое","Stack":250,"functionType":0},
	[ItemId.Ruby]: {"Name":"Рубин","Description":"Очень редкое ископаемое из шахты.","Icon":"inv-item-ruby","Type":"Особое","Stack":250,"functionType":0},
	[ItemId.Radio]: {"Name":"Рация","Description":"С помощью рации можно общаться с другими обладателями рации, или даже подслушать полицейскую или другую секретную волну.","Icon":"hud__icon-walkie-talkie","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.WorkAxe]: {"Name":"Рабочий топор","Description":"Инструмент для работы лесорубом.","Icon":"inv-item-workaxe","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.WoodOak]: {"Name":"Дуб","Description":"Древесина.","Icon":"inv-item-woodoak","Type":"Особое","Stack":250,"functionType":0},
	[ItemId.WoodMaple]: {"Name":"Клен","Description":"Древесина.","Icon":"inv-item-woodmaple","Type":"Особое","Stack":250,"functionType":0},
	[ItemId.WoodPine]: {"Name":"Сосна","Description":"Древесина.","Icon":"inv-item-woodpine","Type":"Особое","Stack":250,"functionType":0},
	[ItemId.Boombox]: {"Name":"Бумбокс","Description":"Позволяет проигрывать свою музыку для людей вокруг. Раскачай тусу!","Icon":"inv-item-boombox","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Hookah]: {"Name":"Кальян","Description":"Калюмбас для лютого распыха! Двойное яблочко и полетели...","Icon":"inv-item-hookah","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Case0]: {"Name":"Бесплатный ежедневный кейс","Description":"Бесплатный кейс, можно получить спустя 3 часа игры.","Icon":"inv-item-case0","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case1]: {"Name":"Бесплатный оружейный кейс","Description":"Бесплатный кейс с оружием, можно получить спустя 5 часов игры.","Icon":"inv-item-case1","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case2]: {"Name":"Бесплатный автомобильный кейс","Description":"Бесплатный кейс с машинами, можно получить спустя 8 часов игры.","Icon":"inv-item-case2","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case3]: {"Name":"Стандартный кейс","Description":"Стандартный кейс со стандартными призами.","Icon":"inv-item-case3","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case4]: {"Name":"Странный кейс","Description":"Неплохой кейс для начинающих.","Icon":"inv-item-case4","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case5]: {"Name":"Особенный кейс","Description":"Сочный кейс, есть возможность серьёзно окупиться!","Icon":"inv-item-case5","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case6]: {"Name":"Редкий кейс","Description":"Кейс с вещами и автомобилями повышенной редкости.","Icon":"inv-item-case6","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case7]: {"Name":"Лютый кейс","Description":"Очень лютый кейс. Суперприз - Bugatti, самый быстрый автомобиль.","Icon":"inv-item-case7","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case8]: {"Name":"Мужской кейс","Description":"Кейс с МУЖСКОЙ одеждой из донатного магазина одежды. Испытай удачу и будь стильным, или продай кому-то и будь богатым. А можешь и подарить...","Icon":"inv-item-case8","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case9]: {"Name":"Женский кейс","Description":"Кейс с ЖЕНСКОЙ одеждой из донатного магазина одежды. Отличный вариант, если хочется что-нибудь подарить.","Icon":"inv-item-case9","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case10]: {"Name":"Экзотический кейс","Description":"Кейс с автомобилями из Exotic DonateRoom. Доната Редбаксовна одобряет!","Icon":"inv-item-case10","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case11]: {"Name":"Легендарный кейс","Description":"Все или ничего! Хочешь рискнуть и стать обладателем легендарной кофты RedAge? Тогда крути кейс!","Icon":"inv-item-case11","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case12]: {"Name":"Интересный кейс","Description":"Давно хочешь себе уникальную машину которая нигде не продается? Dodge Charger в этом кейсике!","Icon":"inv-item-case12","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case13]: {"Name":"Вертолетный кейс","Description":"Любишь смотреть на людей свысока? Пожалуй, для этого идеально подойдет вертолёт!","Icon":"inv-item-case13","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case14]: {"Name":"Бронированный кейс","Description":"Классный новый кейс с модными бронированными тачками!","Icon":"inv-item-case14","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case15]: {"Name":"Эксклюзивный кейс","Description":"Крутой новый кейс с размещаемыми предметами!","Icon":"inv-item-case15","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case16]: {"Name":"Снежный кейс [М]","Description":"Эксклюзивный кейс из Battle Pass, в нем находится несколько комплектов одежды с одинаковым шансов выпадения. ","Icon":"inv-item-case16","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case17]: {"Name":"Аметистовый кейс [М]","Description":"Эксклюзивный кейс из Battle Pass, в нем находится несколько комплектов одежды с одинаковым шансов выпадения. ","Icon":"inv-item-case17","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case18]: {"Name":"Праздничный кейс [М]","Description":"Эксклюзивный кейс из Battle Pass, в нем находится несколько комплектов одежды с одинаковым шансов выпадения. ","Icon":"inv-item-case18","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case19]: {"Name":"Снежный кейс [Ж]","Description":"Эксклюзивный кейс из Battle Pass, в нем находится несколько комплектов одежды с одинаковым шансов выпадения. ","Icon":"inv-item-case19","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case20]: {"Name":"Аметистовый кейс [Ж]","Description":"Эксклюзивный кейс из Battle Pass, в нем находится несколько комплектов одежды с одинаковым шансов выпадения. ","Icon":"inv-item-case20","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.Case21]: {"Name":"Праздничный кейс [Ж]","Description":"Эксклюзивный кейс из Battle Pass, в нем находится несколько комплектов одежды с одинаковым шансов выпадения. ","Icon":"inv-item-case21","Type":"Особое","Stack":100,"functionType":9},
	[ItemId.SummerCoin]: {"Name":"Jaguar Coin","Description":"Монета, за которую можно получить особые призы в дни проведения мероприятия. НЕ ЯВЛЯЕТСЯ ДОНАТ ВАЛЮТОЙ!","Icon":"inv-item-EventCoin","Type":"Особое","Stack":999999,"functionType":0},
	[ItemId.CandyCane]: {"Name":"Новогодний леденец","Description":"Новогодний леденец, за который можно получить особые призы в дни проведения мероприятия.","Icon":"inv-item-EventCoin","Type":"Особое","Stack":999999,"functionType":0},
	[ItemId.Qr]: {"Name":"QR-код","Description":"QR-код, содержащий в себе информацию о перенесенном заболевании и/или вакцинации. Предъявить по требованию.","Icon":"inv-item-QR","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.QrFake]: {"Name":"QR-код","Description":"QR-код, содержащий в себе информацию о перенесенном заболевании и/или вакцинации. Предъявить по требованию.","Icon":"inv-item-QR","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.SimCard]: {"Name":"Сим-карта","Description":"Сим-карта с номером телефона. Её можно вставить в любой современный смартфон и пользоваться сотовой сетью: писать смс, звонить и многое другое.","Icon":"inv-item-SimCard","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.VehicleNumber]: {"Name":"Номер на автомобиль","Description":"Номерной знак транспортного средства. Можно установить на свою машину, продать или подарить кому-нибудь!","Icon":"inv-item-SimCard","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Bint]: {"Name":"Бинт","Description":"Бинт, восстанавливает 40% здоровья. Можно использовать раз в 3 минуты. ","Icon":"inv-item-SimCard","Type":"Остальное","Stack":10,"functionType":0},
	[ItemId.Cocaine]: {"Name":"Белый порошок","Description":"Волшебный порошочек накладывает эффект наркотического опьянения.","Icon":"inv-item-SimCard","Type":"Остальное","Stack":10,"functionType":0},
	[ItemId.Rub100]: {"Name":"100 рублей","Description":"Этот предмет ты можешь обменять на реальные деньги у Руководителя проекта! ","Icon":"inv-item-SimCard","Type":"Остальное","Stack":1,"functionType":0},
	[ItemId.Rub200]: {"Name":"200 рублей","Description":"Этот предмет ты можешь обменять на реальные деньги у Руководителя проекта! ","Icon":"inv-item-SimCard","Type":"Остальное","Stack":1,"functionType":0},
	[ItemId.Rub500]: {"Name":"500 рублей","Description":"Этот предмет ты можешь обменять на реальные деньги у Руководителя проекта! ","Icon":"inv-item-SimCard","Type":"Остальное","Stack":1,"functionType":0},
	[ItemId.Rub1000]: {"Name":"1000 рублей","Description":"Этот предмет ты можешь обменять на реальные деньги у Руководителя проекта!","Icon":"inv-item-SimCard","Type":"Остальное","Stack":1,"functionType":0},
	[ItemId.RadioInterceptor]: {"Name":"Радиоперехватчик","Description":"Позволяет получать данные о HeliCrash.","Icon":"hud__icon-walkie-talkie","Type":"Остальное","Stack":1,"functionType":0},
	[ItemId.Epinephrine]: {"Name":"Адреналин","Description":"Нужна для лечения, можно использовать 1 раз в 5 минут.","Icon":"inv-item-medical-kit","Type":"Остальное","Stack":10,"functionType":0},
	[ItemId.AppleCoin]: {"Name":"Apple Coin","Description":"Летняя монета, которую можно получить в дни проведения мероприятия. F за Apple Pay.","Icon":"inv-item-medical-kit","Type":"Остальное","Stack":999,"functionType":0},
	[ItemId.Fire]: {"Name":"Костер","Description":"Подойдет для вечерних посиделок, не обожгитесь!","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Matras]: {"Name":"Надувной матрас","Description":"Для пляжного отдыха и чтобы не утонуть","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Tent]: {"Name":"Палатка","Description":"Для кемпинга, пикника и бурной ночи","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Lezhak]: {"Name":"Лежак","Description":"Для пляжного отдыха, можно лечь и думать о светлом будущем","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Towel]: {"Name":"Свернутое полотенце","Description":"Для пляжного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flag]: {"Name":"Флаг","Description":"Российский флаг, не забудьте приобрести флагшток","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Barrell]: {"Name":"Бочка","Description":"Подойдет для различных нужд","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Surf]: {"Name":"Доска для сёрфа","Description":"Для пляжного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Vedro]: {"Name":"Ведро","Description":"Такое же большое, как киска твоей бывшей, подойдет для пляжного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagstok]: {"Name":"Флаг Sprunk с флагштоком","Description":"Флагшток с прикрепленным большим флагом Sprunk","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Tenttwo]: {"Name":"Палатка","Description":"Для кемпинга, пикника и бурной ночи","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Polotence]: {"Name":"Пляжное полотенце","Description":"Для пляжного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Beachbag]: {"Name":"Пляжная сумка","Description":"Для пляжного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Zontik]: {"Name":"Зонтик","Description":"Защитит от солнца и подойдет для пляжного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Zontiktwo]: {"Name":"Зонтик","Description":"Защитит от солнца и подойдет для пляжного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Zontikthree]: {"Name":"Зонтик","Description":"Защитит от солнца и подойдет для пляжного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Closedzontik]: {"Name":"Закрытый зонтик","Description":"Не защитит от солнца, но подойдет для пляжного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Vball]: {"Name":"Воллейбольный мяч","Description":"Для веселых игр и пляжного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Bball]: {"Name":"Пляжный мяч","Description":"Для веселых игр и пляжного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Boomboxxx]: {"Name":"Бумбокс","Description":"Подойдет для посиделок с друзьями","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Table]: {"Name":"Стол","Description":"Подойдет для пикника или обычного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Tabletwo]: {"Name":"Стол","Description":"Подойдет для пикника или обычного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Tablethree]: {"Name":"Стол","Description":"Подойдет для пикника или обычного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Tablefour]: {"Name":"Стол","Description":"Подойдет для пикника или обычного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Chair]: {"Name":"Стул","Description":"Подойдет для пикника или обычного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Chairtwo]: {"Name":"Стул","Description":"Подойдет для пикника или обычного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Chaierthree]: {"Name":"Стул","Description":"Подойдет для пикника или обычного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Chaierfour]: {"Name":"Стул","Description":"Подойдет для пикника или обычного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Chairtable]: {"Name":"Стол и стул","Description":"Подойдет для пикника или обычного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Korzina]: {"Name":"Корзина","Description":"Подойдет для пикника или обычного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Light]: {"Name":"Освещение","Description":"Освещение, которое пригодится в темное время суток","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Alco]: {"Name":"Бутылка бренди","Description":"Дорогая бутылка бренди для веселья и хорошей ночи с девушкой или парнем","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Alcotwo]: {"Name":"Бутылка коньяка","Description":"Дорогая бутылка коньяка для веселья и хорошей ночи с девушкой или парнем","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Alcothree]: {"Name":"Бутылка текилы","Description":"Дорогая бутылка текилкы для веселья и хорошей ночи с девушкой или парнем","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Alcofour]: {"Name":"Бутылка пива","Description":"Дешевое пиво, подойдет для посиделок с друзьями на природе","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Cocktail]: {"Name":"Коктейль","Description":"Смесь цитрусовых фруктов и алкоголя, идеально подойдет для твоей девушки","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Cocktailtwo]: {"Name":"Коктейль","Description":"Смесь цитрусовых фруктов и алкоголя, идеально подойдет для твоей девушки","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Fruit]: {"Name":"Тарелка с фруктами","Description":"Вкусная и ароматная тарелка с фруктами","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Fruittwo]: {"Name":"Тарелка с фруктами","Description":"Вкусная и ароматная тарелка с фруктами","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Packet]: {"Name":"Пакет","Description":"Подойдет для пикника","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Buter]: {"Name":"Бутерброд","Description":"Подойдет для пикника","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Patatoes]: {"Name":"Картошка фри","Description":"Подойдет для пикника","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Coffee]: {"Name":"Горячий кофе","Description":"Подойдет для пикника","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Podnosfood]: {"Name":"Поднос с едой","Description":"Подойдет для пикника","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Bbqtwo]: {"Name":"Барбекю","Description":"Подойдет для пикника или обычного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Bbq]: {"Name":"Большое барбекю","Description":"Подойдет для пикника или обычного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flowerrr]: {"Name":"Букет цветов","Description":"Красивый букет с разноцветными цветами, который понравится вашей даме","Icon":"prop_snow_flower_02","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Vaza]: {"Name":"Ваза с цветами","Description":"Ваза с красивыми белыми цветами, которая понравится вашей даме","Icon":"vw_prop_flowers_vase_01a","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagwtokk]: {"Name":"Флагшток","Description":"Подойдет для пикника или обычного отдыха","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagau]: {"Name":"Флаг Австралии","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_australia","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagbr]: {"Name":"Флаг Бразилии","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_brazil","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagch]: {"Name":"Флаг Китая","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_china","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagcz]: {"Name":"Флаг Чехии","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_czechrep","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flageng]: {"Name":"Флаг Англии","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_england","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flageu]: {"Name":"Флаг Евросоюза","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_eu_yt","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagfin]: {"Name":"Флаг Финляндии","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_finland","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagfr]: {"Name":"Флаг Франции","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_france","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagger]: {"Name":"Флаг Германии","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_german_yt","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagire]: {"Name":"Флаг Ирландии","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_ireland","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagisr]: {"Name":"Флаг Израиля","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_israel","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagit]: {"Name":"Флаг Италии","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_italy","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagjam]: {"Name":"Флаг Ямайки","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_jamaica","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagjap]: {"Name":"Флаг Японии","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_japan_yt","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagmex]: {"Name":"Флаг Мексики","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_mexico_yt","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagnet]: {"Name":"Флаг Нидерландов","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_netherlands","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagnig]: {"Name":"Флаг Нигерии","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_nigeria","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagnorw]: {"Name":"Флаг Норвегии","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_norway","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagpol]: {"Name":"Флаг Польши","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_poland","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagrus]: {"Name":"Флаг России","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_russia_yt","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagbel]: {"Name":"Флаг Бельгии","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_belgium","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagscot]: {"Name":"Флаг Шотландии","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_scotland_yt","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagscr]: {"Name":"Флаг Скрипт","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_script","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagslov]: {"Name":"Флаг Словакии","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_slovakia","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagslovak]: {"Name":"Флаг Словении","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_slovenia","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagsou]: {"Name":"Флаг Кореи","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_southkorea","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagspain]: {"Name":"Флаг Испании","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_spain","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagswede]: {"Name":"Флаг Швеции","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_sweden","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagswitz]: {"Name":"Флаг Швейцарии","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_switzerland","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagturk]: {"Name":"Флаг Турции","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_turkey","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flaguk]: {"Name":"Флаг Великобритании","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_uk_yt","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagus]: {"Name":"Флаг Америки","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_us_yt","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Flagwales]: {"Name":"Флаг Уэльса","Description":"Флаг среднего размера, для крепления необходим флагшток","Icon":"apa_prop_flag_wales","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Konus]: {"Name":"Конус","Description":"Подойдет в качестве ограждения","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Konuss]: {"Name":"Светящийся конус","Description":"Подойдет в качестве ограждения","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Otboynik1]: {"Name":"Отбойник","Description":"Подойдет в качестве ограждения","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Otboynik2]: {"Name":"Отбойник","Description":"Подойдет в качестве ограждения","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Dontcross]: {"Name":"Перекрытие","Description":"Подойдет в качестве ограждения","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Stop]: {"Name":"Знак STOP","Description":"Подойдет для ПДД","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.NetProezda]: {"Name":"Знак НЕТ ПРОЕЗДА","Description":"Подойдет для ПДД","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Zabor1]: {"Name":"Большой забор","Description":"Подойдет для пикника","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Kpp]: {"Name":"КПП","Description":"Подойдет в качестве установки блок-поста","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Zabor2]: {"Name":"Маленький забор","Description":"Подойдет в качестве ограждения","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Airlight]: {"Name":"Ночной свет","Description":"Подойдет в качестве ограждения","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Camera1]: {"Name":"Камера видеонаблюдения","Description":"Подойдет для наблюдения","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.Camera2]: {"Name":"Камера видеонаблюдения","Description":"Подойдет для наблюдения","Icon":"placingprop","Type":"Размещаемые предметы","Stack":1,"functionType":0},
	[ItemId.TacticalRifle]: {"Name":"Tactical Rifle","Description":"Усовершенствованная штурмовая винтовка, обойма вмещает в себя 30 патронов.","Icon":"inv-item-militaryrifle","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.PrecisionRifle]: {"Name":"Precision Rifle","Description":"Усовершенствованная снайперская  винтовка, обойма вмещает в себя 6 патронов.","Icon":"inv-item-militaryrifle","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.CombatShotgun]: {"Name":"Combat Shotgun","Description":"Усовершенствованный  дробовик, обойма вмещает в себя 10 патронов.","Icon":"inv-item-militaryrifle","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.HeavyRifle]: {"Name":"Heavy Rifle","Description":"Усовершенствованная штурмовая винтовка, обойма вмещает в себя 30 патронов.","Icon":"inv-item-militaryrifle","Type":"Оружие","Stack":1,"functionType":2},
	[ItemId.NeonStick]: {"Name":"Неоновые палочки","Description":"Палочки красного цвета, которые можно держать в руках.","Icon":"inv-item-Assault-Rifle","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Glowstick]: {"Name":"Светящиеся палочки","Description":"Красивые светящиеся палочки, которые можно держать в руках.","Icon":"inv-item-Assault-Rifle","Type":"Особое","Stack":1,"functionType":0},
	[ItemId.Giftcoin]: {"Name":"Подарок","Description":"Подарок, который для тебя подготовил Санта!.","Icon":"inv-item-Assault-Rifle","Type":"Особое","Stack":999,"functionType":0},
	[ItemId.CombatRifle]: {"Name":"Combat Rifle","Description":"Оружие.","Icon":"inv-item-Assault-Rifle","Type":"Особое","Stack":1,"functionType":2},
	[ItemId.Glock]: {"Name":"Glock","Description":"Оружие.","Icon":"inv-item-Assault-Rifle","Type":"Особое","Stack":1,"functionType":2}

}