<div class="support__background">
    <div class="supportchat__block">
        <div class="restartchat__header">
            Чат с администрацией
        </div>
        <div class="supportchat__chat">
            <div class="supportchat__chat_messages">
                <div class="supportchat__chat_message me">
                    <div>
                        <b>Vitalis Vance</b>: Всем привеееееееееееет как дела
                    </div>
                    <div class="time">12.01.21 15:55</div>
                </div>
                <div class="supportchat__chat_message admin">
                    <div>
                        <b class="admin">Vitalis Vance</b>: Лечу на помощь!
                    </div>
                    <div class="time">12.01.21 15:55</div>
                </div>
            </div>
            <div class="supportchat__chat_input">
                <input type="text" class="supportchat__input">
                <div class="supportchat__sendbutton">&#9993;</div>
            </div>
        </div>
    </div>
</div>