<svg xmlns="http://www.w3.org/2000/svg" width="178.69" height="311.16" viewBox="0 0 178.69 311.16">
  <defs>
    <style>
      .cls-1 {
        fill: #d9d9d9;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="farm2" class="cls-1" d="M5378,2684l47,5,37-24,69-62,6-6,8-34-16-100-17-64-14-12-7-3-119,112,19,51,9,44-23,90Z" transform="translate(-5369.25 -2381.5)"/>
</svg>
