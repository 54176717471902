<svg xmlns="http://www.w3.org/2000/svg" width="574.94" height="332.91" viewBox="0 0 574.94 332.91">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="port" class="cls-1" d="M4613,7736l-323,1-15-14-41-37-13-42-16-31-5-94-29-1s-4,61.8-4,68-4,51-4,51l-5,33-6-1-1,30-58-4-1,65-28,3-2-70-5-6-1-20-9-8v-37l10-1,1-37-13-1,1-125,89-4,120-5,11,4,4-15,291-2,3,108,50-1Z" transform="translate(-4042.06 -7433.03)"/>
</svg>
