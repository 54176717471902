<script>
    //import image from '../../images/fibpolice.png'
</script>

<div class="text">
    <p><span style="font-weight: 400;">В штате присутствуют <strong>семьи</strong> и <strong>неофициальные организации. </strong>Создать можно только семью, а статус неофициальной организации - необходимо получить, но об этом немного позже... Семья создается возле&nbsp;здания <strong>Eclipse Tower</strong>, где и располагается сам офис.&nbsp;</span></p>
    <p><span style="font-weight: 400;">Игрок который создает семью должен иметь <strong>как минимум первый уровень</strong> и <strong>250.000$</strong> на руках, а также <strong>не иметь активных варнов</strong>.</span><span style="font-weight: 400;"><br /></span></p>
    <p><span style="font-weight: 400;"><strong>После создания</strong> семьи все игроки получат соответствующее сообщение в глобальный чат с названием вашей семьи. В вашем распоряжении будет <strong>офис</strong> и <strong>гараж</strong> с возможностью дальнейшего улучшения. Помимо этого будет доступен семейный чат /fc /fcb, настройка рангов и возможность <strong>вступать во фракции!</strong></span></p>
    <p><span style="font-weight: 400;"><strong>Список улучшений:</strong></span></p>
    <p><span style="font-weight: 400;">Вы можете купить <strong>личную мастерскую</strong> за <em>75.000$</em>, в которой будет возможность тюнинговать транспорт организации по гос.цене.<br /><br /></span><span style="font-weight: 400;"><strong>Купить склад</strong> за <em>50.000$</em>, на котором будет возможность хранить аптечки, материалы, оружие и запрещенные вещества<br /><br /></span><span style="font-weight: 400;"><strong>Улучшить офис</strong><em> до 2 или 3 уровня</em>, каждый уровень <strong>улучшает интерьер</strong> и <strong>открывает 5 дополнительных мест в гараже</strong>.<br /><br /></span><span style="font-weight: 400;"><strong>Купить чертежи оружия</strong>, стоимость чертежей начинается <em>от 5.000$ и заканчивается 20.000$</em> купив чертеж члены вашей организации смогут создавать данное оружие.<br /><br /></span></p>
    <p><span style="font-weight: 400;">Теперь перейдем к <strong>неофициальной организации, </strong>получить этот статус не так уж просто, необходимо иметь состав, написать SS (скриншот-ситуацию) на форуме, иметь большой актив на сервере, не иметь серьезных нарушений на аккаунте, как только вашу SS-ку одобрят - вы получите новый статус автоматически! После этого появятся такие возможности как</span><span style="font-weight: 400;">: <strong><em>красть оружие</em>, <em>надевать мешок на голову</em>, <em>вести игрока за собой</em>, <em>использовать стяжки</em>, <em>создавать патроны</em>, <em>грабить дома и машины</em>.<br /><br /></strong></span><span style="font-weight: 400;"><strong>Также</strong> если вы хотите создать полноценную RP организацию, следует ознакомиться с темой &ldquo;<strong>Правила неофициальных организаций</strong>&rdquo; на нашем форуме!</span></p>
</div>