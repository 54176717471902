<script>
    import image from '../../images/heli1.jpg'
</script>



<div class="text">
    <p>
        <center><font size="5">HeliCrash - крушение военного или же спасательного вертолета в случайном месте штата, количество которых более 50!</font></center>
        <br />
        <center>
            <strong>Как это работает?</strong><br />
            <br />

            <span style="font-weight: 400;">
                Система доступна для всех игроков и начинает работать с 12 часов дня до 12 ночи. Каждый час, примерно в 45 минут, система дает оповещение в чат, что совсем скоро над штатом пролетит вертолет, который передает сигнал бедствия. Через 10 минут после такого оповещения вертолет падает и начинается настоящая битва за ящики с ценным дропом!<br><br>
                Найти вертолет вам поможет Радиоперехватчик, который будет сообщать точный район крушения, купить же его можно на черном рынке (не забудьте также взять там военные отмычки, они вам пригодятся!). Вертолет же в свою очередь выпускает много дыма, увидеть который можно за несколько сотен метров!<br><br>
                <br />
            </span>
            <span style="font-weight: 400;">
                При каждом крушении вокруг вертолета появляется по 3 ящика, взломать которые можно военной отмычкой. Содержимое ящика различное в зависимости от типа вертолета. Например, в военном могут попасться бронежилеты, материалы, редкое спец. оружие, а в спасательном аптечки, маски, а также особо редкий предмет - Эпинефрин, использовав который вы можете поднять игрока со 100% шансом.<br><br>
                Все действия происходят в основном мире, поэтому есть вероятность встретиться с кем-то еще и вступить в схватку за полученный лут. На данную систему не распространяются правила DM, если вы прибыли на место или неподалеку ожидаете крушение - то вы автоматически становитесь участником данного мероприятия и вас могут убить.<br><br><br>
                Желаем удачи в поисках вертолета и захвате ящиков, победят сильнейшие!<br>
            </span>
        </center>
    </p>
    <br>
        
        <div style="display: flex; justify-content: center;">
            <img src={image} alt="Хеликраш" class="contentImg"/> 
        </div>
</div>