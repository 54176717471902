<svg xmlns="http://www.w3.org/2000/svg" width="150.56" height="234.6" viewBox="0 0 150.56 234.6">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="zavod2" class="cls-1" d="M4146,6916l7,24-3,47-10,124-13,15-33-16-1-25-6-29-37-21,29-51-26-10-18,39-27-14,13-32,34-26,47-39,20-4,17,9Z" transform="translate(-4005.44 -6895.12)"/>
</svg>
