<svg xmlns="http://www.w3.org/2000/svg" width="145.78" height="116.6" viewBox="0 0 145.78 116.6">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="inkas" class="cls-1" d="M2792,5834l-103,72,21,27,19,12,29-4,31-20,42-27Z" transform="translate(-2687.25 -5832.09)"/>
</svg>
