<svg xmlns="http://www.w3.org/2000/svg" width="176.34" height="141.28" viewBox="0 0 176.34 141.28">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="sklad1" class="cls-1" d="M3902,1320l-158,88-15-18,20-16-8-10,71-71,15,11,38-32" transform="translate(-3727.41 -1270.41)"/>
</svg>
