export const houseType = [
    "Трейлер",
    "Эконом",
    "Эконом+",
    "Комфорт",
    "Комфорт+",
    "Премиум",
    "Премиум+",
    "Парковка",
    "Премиум++",
    "Люкс",
]

export const businessType = [
    "24/7", // 0
    "Petrol Station",
    "Premium Autoroom",
    "Luxor Autoroom",
    "Low Autoroom",
    "Motoroom",
    "Gun shop",
    "Clothes Shop",
    "Burger-Shot",
    "Tattoo-salon",
    "Barber-Shop",
    "Masks Shop",
    "LS Customs",
    "CarWash",
    "PetShop",
    "Elite Autoroom", // 15
]