<svg xmlns="http://www.w3.org/2000/svg" width="221.25" height="169.84" viewBox="0 0 221.25 169.84">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="theatre" class="cls-1" d="M4342,5187l-19-3-8,18-23-10-21-20-29-10h-7l-7,2,12,69-24,1-25-2-34-12-29-28,54-42-45-18,5-18,6-18,16-5,15-4,6-17,60,22-4,12,37,14,19,16,19,22,14,4,17,6Z" transform="translate(-4128.59 -5067.38)"/>
</svg>
