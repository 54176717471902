<svg xmlns="http://www.w3.org/2000/svg" width="441" height="372.47" viewBox="0 0 441 372.47">
  <defs>
    <style>
      .cls-1 {
        fill: #dadada;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="vetryanaya3" class="cls-1" d="M5411,4075l-177,30q-37.005,10.005-74,20-14.505,15.495-29,31l-101,25,5-198,49-97,40-35,85-32h37l28-4-12,42,3,20,39,14,23-19,45,14,38,10,27-8,14-8,14,19-11,68q-12.495,53.505-25,107Q5420,4074.5,5411,4075Z" transform="translate(-5026.94 -3812.06)"/>
</svg>
