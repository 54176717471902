export default [
    "-",//0
    "The Families",//1
    "The Ballas Gang",//2
    "Los Santos Vagos",//3
    "Marabunta Grande",//4
    "Blood Street",//5
    "Cityhall",//6
    "Police",//7
    "Hospital",//8
    "FIB",//9
    "La Cosa Nostra",//10
    "Russian Mafia",//11
    "Yakuza",//12
    "Armenian Mafia",//13
    "Army",//14
    "News",//15
    "The Lost",//16
    "Merryweather Security",//17
    "Sheriff"//18
]