export default {
    brow: [
        {name: "Стандарт", x: 0.0, y: 0.0},
        {name: "Выступающий", x: 0.8, y: 0.2},
        {name: "Плоский", x: -0.5, y: 0.0}
    ],
    eyes: [
        {name: "Стандарт", x: 0.0, y: 0.0},
        {name: "Широкие", x: 0.7, y: 0.0},
        {name: "Узкие", x: -0.7, y: 0.0}
    ],
    nose: [
        {name: "Стандарт", x: 0.0, y: 0.0},
        {name: "Большой", x: 0.7, y: 0.4},
        {name: "Маленький", x: -0.7, y: -0.4}
    ],
    noseprofile: [
        {name: "Стандарт", x: 0.0, y: 0.0},
        {name: "Выгнутый", x: 0.2, y: -0.7},
        {name: "С горбинкой", x: -0.2, y: 0.7}
    ],
    nosetip: [
        {name: "Стандарт", x: 0.0, y: 0.0},
        {name: "Искривленный", x: -0.7, y: 0.0},
        {name: "Кверху", x: 0.0, y: -0.6}
    ],
    cbones: [
        {name: "Стандарт", x: 0.0, y: 0.0},
        {name: "Большие", x: 0.4, y: 0.4},
        {name: "Маленькие", x: -0.4, y: -0.4}
    ],
    cheeks: [
        {name: "Стандарт", x: 0.0, y: 0.0},
        {name: "Толстые", x: 0.7, y: 0.0},
        {name: "Впалые", x: -0.7, y: 0.0}
    ],
    lips: [
        {name: "Стандарт", x: 0.0, y: 0.0},
        {name: "Большие", x: 0.7, y: 0.0},
        {name: "Маленькие", x: -0.7, y: 0.0},
    ],
    jaw: [
        {name: "Стандарт", x: 0.0, y: 0.0},
        {name: "Квадратная", x: 0.0, y: 0.6}, 
        {name: "Круглая", x: 0.0, y: -0.6}
    ],
    chinprofile: [
        {name: "Стандарт", x: 0.0, y: 0.0},
        {name: "Большой", x: 0.7, y: 0.4},
        {name: "Маленький", x: -0.7, y: -0.4}
    ],
    chinform: [
        {name: "Стандарт", x: 0.0, y: 0.0},
        {name: "Заостренный", x: 0.5, y: 0.0},
        {name: "Круглый", x: -0.3, y: -0.4},
    ],
}