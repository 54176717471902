<svg xmlns="http://www.w3.org/2000/svg" width="102.53" height="149.41" viewBox="0 0 102.53 149.41">
  <defs>
    <style>
      .cls-1 {
        fill: #d9d9d9;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="sklad7" class="cls-1" d="M5654,2749l61-28-46-115-50,17-1,11Z" transform="translate(-5615.03 -2603.31)"/>
</svg>
