<svg xmlns="http://www.w3.org/2000/svg" width="153.88" height="179.63" viewBox="0 0 153.88 179.63">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="observatorya" class="cls-1" d="M2339,5384l-13,5,1,7-12,5-29,14-39,9-9-3-18-42-10-21-18-25,15-9,10-8-15-40,13-8,7-3,4-14,17,2,9,1,10,3,9-1,8,11,2,9-4,3,2,9,5-1,6,10,5,1,6,9,4,14,10,13,10,23,11,3,1,6,4,10v6Z" transform="translate(-2190.12 -5248.03)"/>
</svg>
