<svg xmlns="http://www.w3.org/2000/svg" width="163.22" height="155.44" viewBox="0 0 163.22 155.44">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="himlab" class="cls-1" d="M6136,3158l-16-3-2-5-118-27,23-88,26-22,73-4,17,8,14,13,3,25,1,13-10,17-12,14,1,19v40Z" transform="translate(-5996.75 -3006)"/>
</svg>
