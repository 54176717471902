<svg xmlns="http://www.w3.org/2000/svg" width="78.69" height="105.72" viewBox="0 0 78.69 105.72">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="huntingshop" class="cls-1" d="M3303,1662c3.63-.18,38,17,38,17l8,24-15,26-9,25-34,7-15-7,1-28S3299.37,1662.18,3303,1662Z" transform="translate(-3273 -1659)"/>
</svg>
