<svg xmlns="http://www.w3.org/2000/svg" width="179.91" height="174.13" viewBox="0 0 179.91 174.13">
  <defs>
    <style>
      .cls-1 {
        fill: #bfbfbf;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="vokzal" class="cls-1" d="M3729,1419l-129,118-28,28-22-15,141-147,16-8Z" transform="translate(-3549.31 -1394.53)"/>
</svg>
