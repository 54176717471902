<svg xmlns="http://www.w3.org/2000/svg" width="198.69" height="254.25" viewBox="0 0 198.69 254.25">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="sklad6" class="cls-1" d="M4273,6904l-14,233,172,15,21-243Z" transform="translate(-4256.28 -6901)"/>
</svg>
