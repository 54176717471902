export default {
    "Sultan": {
        desc: "Полноприводный спортивный автомобиль, очень подходит для тех у кого пока не много денег, но хочется иметь качественный автомобиль.",
        seats: 4,
        acceleration: 6.3,
        maxSpeed: 0
    },
    "SultanRS": {
        desc: "Полноприводный суперкар, имеет небольшие изменения со своей первой версией, но из за своего класса и доступных модификаций цена у него немного больше.",
        seats: 2,
        acceleration: 4.3,
        maxSpeed: 0
    },
    "Kuruma": {
        desc: "Четырёхдверный спортивный седан, имеет среднюю цену на рынке и хорошие опции тюнинга.",
        seats: 4,
        acceleration: 5.7,
        maxSpeed: 0
    },
    "Fugitive": {
        desc: "Четырёхместный седан, подходит для тех, кто ищет красивый автомобиль за небольшие деньги.",
        seats: 4,
        acceleration: 8.3,
        maxSpeed: 0
    },
    "Tailgater": {
        desc: "Высокопроизводительный, четырёхдверный седан, является одним из самых дешевых автомобилей в премиум классе.",
        seats: 4,
        acceleration: 8.8,
        maxSpeed: 0
    },
    "Sentinel": {
        desc: "Это двухдверный спортивный автомобиль с типом кузова 'купе', имеет красивый внешний вид и небольшую цену. ",
        seats: 2,
        acceleration: 8.3,
        maxSpeed: 0
    },
    "F620": {
        desc: "Красивое двухдверное купе. Подходит для людей, которые подобные автомобили, находится в средней ценовой категории.",
        seats: 2,
        acceleration: 6.7,
        maxSpeed: 0
    },
    "Schwarzer": {
        desc: "Двухместное спортивное купе. Данный автомобиль подойдет для тех, кто любит погонять ночью. ",
        seats: 2,
        acceleration: 6.7,
        maxSpeed: 0
    },
    "Exemplar": {
        desc: "Это четырёхдверный автомобиль премиум класса с кузовом типа 'купе', имеет не очень большой выбор в тюнинг-ателье, но его внешний вид сам по себе является достойным за свои деньги.",
        seats: 4,
        acceleration: 6.8,
        maxSpeed: 0
    },
    "Felon": {
        desc: "Четырёхдверный автомобиль премиум класса, производится компанией Lampadati, а также имеет привлекательный внешний вид.",
        seats: 4,
        acceleration: 0,
        maxSpeed: 0
    },
    "Schafter2": {
        desc: "Седан бизнес класса, имеет очень красивый внешний вид, подходит для поездок на деловые встречи. ",
        seats: 4,
        acceleration: 7.1,
        maxSpeed: 0
    },
    "Jackal": {
        desc: "Четырёхдверный седан, имеет роскошный внешний вид, является не самым дорогим автомобилем данного класса, что является его преимуществом.",
        seats: 4,
        acceleration: 8.8,
        maxSpeed: 0
    },
    "Oracle2": {
        desc: "Четырёхдверный седан, имеет заманчивый внешний вид, а также не очень большую цену для такого автомобиля.",
        seats: 4,
        acceleration: 6.4,
        maxSpeed: 0
    },
    "Surano": {
        desc: "Двухдверный спорткар, имеет среднюю цену на рынке, подходит для тех, кто любит удивлять окружающих.",
        seats: 2,
        acceleration: 5.4,
        maxSpeed: 0
    },
    "Zion": {
        desc: "Двухдверное купе, очень похож на некоторые автомобили из данного автосалона, имеет неплохой внешний вид.",
        seats: 2,
        acceleration: 0,
        maxSpeed: 0
    },
    "Dominator": {
        desc: "Двухдверный маслкар, подходит для тех, кто любит уличные гонки или погони от полиции, славится агрессивным внешним видом.",
        seats: 2,
        acceleration: 7.3,
        maxSpeed: 0
    },
    "FQ2": {
        desc: "Среднеразмерный кроссовер, имеет не очень большую цену, а также красивый внешний вид для данного класса авто.",
        seats: 4,
        acceleration: 10.3,
        maxSpeed: 0
    },
    "Gresley": {
        desc: "Четырёхдверный внедорожник. Подходит для людей, которые любят покорять вершины или ездить по пустыне.",
        seats: 4,
        acceleration: 9.5,
        maxSpeed: 0
    },
    "Serrano": {
        desc: "Четырёхдверный кроссовер, имеет неплохой внешний вид и небольшую цену.",
        seats: 4,
        acceleration: 9.1,
        maxSpeed: 0
    },
    "Dubsta": {
        desc: "Четырёхдверный внедорожник премиум класса, подходит для людей которые любят показать свой престиж или похвастаться своими достижениями.",
        seats: 4,
        acceleration: 10,
        maxSpeed: 0
    },
    "Rocoto": {
        desc: "Четырёхдверный компактный кроссовер, подходит для людей, которые не любят большие машины, а также является отличным универсалом.",
        seats: 4,
        acceleration: 9.1,
        maxSpeed: 0
    },
    "Cavalcade2": {
        desc: "Спортивный полноприводный внедорожник, очень удобен для поездок за город и на природу. ",
        seats: 4,
        acceleration: 9.2,
        maxSpeed: 0
    },
    "XLS": {
        desc: "Роскошный внедорожник премиум класса, выполнен в современном стиле, сразу видно, что за рулём сидит Босс!",
        seats: 4,
        acceleration: 7,
        maxSpeed: 0
    },
    "Baller2": {
        desc: "Четырёхдверный внедорожник премиум класса, внедорожник данного поколения славится своей надёжностью и проходимостью по бездорожью.",
        seats: 4,
        acceleration: 7.3,
        maxSpeed: 0
    },
    "Elegy": {
        desc: "Данный автомобиль предоставлен в виде двухдверного купе, подходит для тех людей, которые любят скорость и езду по ночному городу.",
        seats: 2,
        acceleration: 4.5,
        maxSpeed: 0
    },
    "Banshee": {
        desc: "Мощный спортивный автомобиль премиум класса, если Вы любитель быстрой езды и рёва мощного двигателя, то данный автомобиль создан для вас.",
        seats: 2,
        acceleration: 5.6,
        maxSpeed: 0
    },
    "Massacro2": {
        desc: "Двухдверный гоночный автомобиль, продается сразу с интересным винилом и имеет большую вариацию тюнинга.",
        seats: 2,
        acceleration: 4.6,
        maxSpeed: 0
    },
    "GP1": {
        desc: "Двухдверный суперкар, имеет привлекательную цену для автомобиля премиум класса. ",
        seats: 2,
        acceleration: 4,
        maxSpeed: 0
    },
    "Revolter": {
        desc: "Четырёхдверный роскошный спортивный автомобиль. Если вы хотите впечатлить свою вторую половинку, то данный автомобиль точно для вас. ",
        seats: 4,
        acceleration: 3.9,
        maxSpeed: 0
    },
    "Brawler": {
        desc: "Двухместный внедорожник. Данный автомобиль предпочитают люди которые любят гонки по бездорожью.",
        seats: 2,
        acceleration: 5.2,
        maxSpeed: 0
    },
    "Sandking": {
        desc: "Один из самых мощных внедорожников. Настоящий пикап для настоящего мужчины.",
        seats: 4,
        acceleration: 9.3,
        maxSpeed: 0
    },
    "Sandking2": {
        desc: "Двухдверная версия Sandking'a, благодаря этому он легче и мощнее предыдущей версии. Всё еще для настоящего мужчины!",
        seats: 4,
        acceleration: 9.5,
        maxSpeed: 0
    },
    "Feltzer2": {
        desc: "Двухдверный спорткар. Имеет неплохой внешний внешний вид, а также подходит для уличных гонок.",
        seats: 2,
        acceleration: 5.4,
        maxSpeed: 0
    },
    "Massacro": {
        desc: "Двухдверный роскошный спортивный автомобиль, в отличие от его второй версии, выполнен в более спокойном стиле.",
        seats: 2,
        acceleration: 4.7,
        maxSpeed: 0
    },
    "Casco": {
        desc: "Классический спортивный автомобиль, подходит для настоящих ценителей классики.",
        seats: 2,
        acceleration: 5.8,
        maxSpeed: 0
    },
    "Bifta": {
        desc: "Песчанынй багги, очень хорошо подходит под пляжные вечеринки. Обладает хорошим ускорением и управляемостью.",
        seats: 2,
        acceleration: 6.3,
        maxSpeed: 0
    },
    "Cogcabrio": {
        desc: "Двухдверное купе-кабриолет, является автомобилем премиум класса, а так же, как понятно из описания, обладает съемной крышей.",
        seats: 2,
        acceleration: 6.8,
        maxSpeed: 0
    },
    "Dominator2": {
        desc: "Двухдверный гоночный маслкар, отличается от своей первой версии улучшенными характеристиками а также уникальным винилом. ",
        seats: 2,
        acceleration: 4.7,
        maxSpeed: 0
    },
    "Nightshade": {
        desc: "Мощный двухдверный маслкар, обладает хорошим ускорением, а также неплохой для своего класса максимальной скоростью.",
        seats: 2,
        acceleration: 7.8,
        maxSpeed: 0
    },
    "Contender": {
        desc: "Спортивный внедорожник, имеет неплохую максимальную скорость, а также хорошую проходимость по бездорожью.",
        seats: 4,
        acceleration: 5.9,
        maxSpeed: 0
    },
    "Cog55": {
        desc: "Роскошный четырёхдверный седан, данный автомобиль показывает ваш достаток и престиж.",
        seats: 4,
        acceleration: 6.8,
        maxSpeed: 0
    },
    "Cognoscenti": {
        desc: "Престижный четырёхдверный седан, подходит для поездки на деловые встречи и другие официальные мероприятия.",
        seats: 4,
        acceleration: 7.4,
        maxSpeed: 0
    },
    "Alpha": {
        desc: "Спортивное двухдверное купе, подходит для людей, которые любят стиль и простоту в автомобиле.",
        seats: 2,
        acceleration: 5.5,
        maxSpeed: 0
    },
    "Furoregt": {
        desc: "Двухдверный спортивный автомобиль, имеет красивый внешний вид, а также приличную скорость на дороге.",
        seats: 2,
        acceleration: 5.6,
        maxSpeed: 0
    },
    "Lynx": {
        desc: "Двухдверное спортивное купе, обладает средними характеристиками для своего класса, не очень легок в управлении. ",
        seats: 2,
        acceleration: 5.4,
        maxSpeed: 0
    },
    "Pariah": {
        desc: "Любимый многими спортивный двухдверный автомобиль, имеет красивый внешний вид и хорошую максимальную скорость.",
        seats: 2,
        acceleration: 5.4,
        maxSpeed: 0
    },
    "Tampa2": {
        desc: "Двухдверный ширококузовный дрифткар, отлично подходит для тех, кто не любит обычную езду.",
        seats: 2,
        acceleration: 4.7,
        maxSpeed: 0
    },
    "Tropos": {
        desc: "Двухдверный раллийный автомобиль, не является самым лучшим автомобилем в данном классе, но отлично чувствует себя на бездорожье.",
        seats: 2,
        acceleration: 6.1,
        maxSpeed: 0
    },
    "Felon2": {
        desc: "Двухдверный премиум автомобиль, от своей первой версии отличается тем, что имеет съемную крышу, а также всего два посадочных места.",
        seats: 4,
        acceleration: 7.2,
        maxSpeed: 0
    },
    "Sentinel2": {
        desc: "Это двухдверный спортивный автомобиль с типом кузова 'кабриолет', имеет красивый внешний вид, а так же небольшую цену.",
        seats: 2,
        acceleration: 8.5,
        maxSpeed: 0
    },
    "Zion2": {
        desc: "Двухдверное купе, в отличии от своей первой версии, имеет откидную крышу и небольшие изменения по внешнему виду.",
        seats: 2,
        acceleration: 7.4,
        maxSpeed: 0
    },
    "Fusilade": {
        desc: "Двухдверный спортивный автомобиль, обладает хорошими характеристиками для автомобиля своего класса. ",
        seats: 2,
        acceleration: 6,
        maxSpeed: 0
    },
    "Penumbra": {
        desc: "Двухдверное спорт-купе, пусть и красивое, но не обладает такими выдающимися характеристиками, как остальные автомобили из данного класса.",
        seats: 2,
        acceleration: 8.6,
        maxSpeed: 0
    },
    "Rapidgt": {
        desc: "Двухместное купе премиум класса, имеет достойную динамику разгона, а так же отличается от других хорошей управляемостью.",
        seats: 2,
        acceleration: 4.6,
        maxSpeed: 0
    },
    "Rapidgt2": {
        desc: "Полностью схожий автомобиль со своей первой версией, но имеет складную крышу, благодаря чему вы сможете прокатиться по городу на большой скорости, наслаждаясь солнцем и свежим воздухом.",
        seats: 2,
        acceleration: 5,
        maxSpeed: 0
    },
    "Schafter3": {
        desc: "Автомобиль премиум класса, подходит для поездок на официальные встречи, а так же для перевозки важных людей.",
        seats: 4,
        acceleration: 5.5,
        maxSpeed: 0
    },
    "Sentinel3": {
        desc: "Классическое спортивное купе, подходит для гонок между автомобилями такого же класса как и у данной модели, имеет довольно хорошую скорость и разгон для классического автомобиля. ",
        seats: 2,
        acceleration: 5.3,
        maxSpeed: 0
    },
    "Voltic": {
        desc: "Компактный двухдверный родстер, имеет электрический двигатель, благодаря чему у данного автомобиля довольно быстрое ускорение.",
        seats: 2,
        acceleration: 4,
        maxSpeed: 0
    },
    "Gauntlet4": {
        desc: "Двухдверный маслкар, является современной версией автомобиля Gauntlet.",
        seats: 2,
        acceleration: 5.2,
        maxSpeed: 0
    },
    "Caracara2": {
        desc: "Очень огромный пикап, который пройдет по любому бездорожью, размеры данного автомобиля могут напугать любого человека в штате.",
        seats: 4,
        acceleration: 5.6,
        maxSpeed: 0
    },
    "Issi7": {
        desc: "Является компактным спортивным автомобилем, отличается от других версий своими характеристиками и внешним видом.",
        seats: 2,
        acceleration: 4.3,
        maxSpeed: 0
    },
    "Schafter4": {
        desc: "Четырёхдверный автомобиль премиум класса, так же как и другие версии данного авто, подходит для перевозок важных людей и поездок на официальные мероприятия.",
        seats: 4,
        acceleration: 8.5,
        maxSpeed: 0
    },
    "Novak": {
        desc: "Является внедорожником премиум класса, от других внедорожников он отличается своим изящным видом.",
        seats: 4,
        acceleration: 4.9,
        maxSpeed: 0
    },
    "Z190": {
        desc: "Классический спортивный автомобиль, среди своего класса выделяется своим необычным внешним видом, а также простотой в управлении.",
        seats: 2,
        acceleration: 2,
        maxSpeed: 0
    },
    "Gb200": {
        desc: "Двухдверный спортивный автомобиль, очень хорошо подходит под гонки по внедорожью или ночному городу.",
        seats: 0,
        acceleration: 4.7,
        maxSpeed: 0
    },
    "Sultan2": {
        desc: "Четырёхдверный классический спортивный седан, данный автомобиль не сильно дешёвый, но за эту цену он имеет большой выбор визуального и внутреннего тюнинга.",
        seats: 4,
        acceleration: 5.3,
        maxSpeed: 0
    },
    "Rebla": {
        desc: "Четырёхдверный премиум внедорожник, выделяется среди остальных автомобилей данного класса своим внешним видом, а также хорошей управляемостью и скоростью.",
        seats: 4,
        acceleration: 4.5,
        maxSpeed: 0
    },
    "Komoda": {
        desc: "Является четырёхдверным спортивным автомобилем, имеет очень красивый внешний вид, а также большую вариацию визуального и внутреннего тюнинга.",
        seats: 4,
        acceleration: 4.4,
        maxSpeed: 0
    },
    "Comet2": {
        desc: "Двухдверный спортивный автомобиль, имеет очень хорошую скорость а так же красивый внешний вид.",
        seats: 2,
        acceleration: 5.3,
        maxSpeed: 0
    },
    "Coquette": {
        desc: "Двухдверный спорткар, данный автомобиль прекрасно приспособлен для гонок, обладает отличной максимальной скоростью и хорошим разгоном.",
        seats: 2,
        acceleration: 5.7,
        maxSpeed: 0
    },
    "Ninef": {
        desc: "Двухместный спорткар, обладает привлекательным внешним видом, данный автомобиль способен за мгновение достичь большой скорости. ",
        seats: 2,
        acceleration: 5,
        maxSpeed: 0
    },
    "Ninef2": {
        desc: "С технической стороны полностью похож на свою первую версию, различие заключается только в том, что у данной версии имеется откидывающаяся крыша.",
        seats: 2,
        acceleration: 5,
        maxSpeed: 0
    },
    "Jester": {
        desc: "Гибридный спорткар, имеет неплохой внешний вид, а так же хорошую максимальную скорость и динамику разгона.",
        seats: 2,
        acceleration: 4.7,
        maxSpeed: 0
    },
    "Elegy2": {
        desc: "Мощный двухместный спорткар, данный автомобиль является одним из самых управляемых.",
        seats: 2,
        acceleration: 5.3,
        maxSpeed: 0
    },
    "Infernus": {
        desc: "Роскошный двухместный суперкар, данный автомобиль имеет неплохой внешний вид и чем-то похож на классичекую машину.",
        seats: 2,
        acceleration: 4,
        maxSpeed: 0
    },
    "Carbonizzare": {
        desc: "Один из мощных спорткаров, имеет неплохую максимальную скорость и динамику разгона, но сложен в управлении.",
        seats: 2,
        acceleration: 4.5,
        maxSpeed: 0
    },
    "Dubsta2": {
        desc: "По сравнению с первой версией, имеет больше опций тюнинга в Los Santos Customs.",
        seats: 0,
        acceleration: 10,
        maxSpeed: 0
    },
    "Baller3": {
        desc: "Данная версия автомобиля, обладает чуть более серьезными характеристиками, чем его первая версия.",
        seats: 4,
        acceleration: 7.3,
        maxSpeed: 0
    },
    "Huntley": {
        desc: "Роскошный городской джип, является одной из самых быстрых машин в своём классе.",
        seats: 4,
        acceleration: 7.1,
        maxSpeed: 0
    },
    "Superd": {
        desc: "Четырёхместный седан люкс класса, является одним из самых быстрых автомобилей в своем классе, отлично подходит под поездки на деловые встречи.",
        seats: 4,
        acceleration: 7.6,
        maxSpeed: 0
    },
    "Windsor": {
        desc: "Двухдверный купе, имеет очень хорошие характеристики для своего класса авто.",
        seats: 2,
        acceleration: 7,
        maxSpeed: 0
    },
    "BestiaGTS": {
        desc: "Двухдверный спорткар, обладает средними ускорением и максимальной скоростью, если сравнивать с автомобилями своего класса.",
        seats: 2,
        acceleration: 4.6,
        maxSpeed: 0
    },
    "Banshee2": {
        desc: "Данный автомобиль очень сильно отличается от своей первой версии. Помимо улучшенных характеристик, производители даровали ему обновленный внешний вид!",
        seats: 2,
        acceleration: 4.7,
        maxSpeed: 0
    },
    "EntityXF": {
        desc: "Двухдверный суперкар, имеет очень хорошую максимальную скорость и динамику ускорения, всем в штате точно понравится его необычный дизайн.",
        seats: 2,
        acceleration: 4.3,
        maxSpeed: 0
    },
    "Neon": {
        desc: "Мощный и компактный спортивный автомобиль, имеет очень большую скорость и маленькое время разгона, за что и любим тысячами людей!",
        seats: 4,
        acceleration: 2.6,
        maxSpeed: 0
    },
    "Jester2": {
        desc: "Гоночная версия гибридного спорткара, имеет немного улучшенные характеристики по сравнению с первой версией, а также уникальный винил.",
        seats: 2,
        acceleration: 5,
        maxSpeed: 0
    },
    "Turismor": {
        desc: "Двухместный суперкар, обладает очень хорошей максимальной скоростью, именно на этом автомобиле вы сможете посоревноваться почти на трассе с кем угодно.",
        seats: 2,
        acceleration: 4.6,
        maxSpeed: 0
    },
    "Penetrator": {
        desc: "Двухместный спортивный автомобиль, обладает очень хорошей манёвренностью на дорогах.",
        seats: 2,
        acceleration: 4.6,
        maxSpeed: 0
    },
    "Omnis": {
        desc: "Двухместный раллийный автомобиль, данный автомобиль отлично чувствует себя на бездорожье, а так же имеет отличную управляемость.",
        seats: 2,
        acceleration: 4.5,
        maxSpeed: 0
    },
    "Reaper": {
        desc: "Роскошный двухместный суперкар, имеет великолепную динамику ускорения и неплохую управляемость.",
        seats: 2,
        acceleration: 4.5,
        maxSpeed: 0
    },
    "Italigtb2": {
        desc: "Двухместный суперкар, отличается от своей первой версией внешним видом и немного улучшенными характеристиками.",
        seats: 2,
        acceleration: 4,
        maxSpeed: 0
    },
    "Xa21": {
        desc: "Двухместный гибридный суперкар, имеет неплохой двигатель, лёгок и приятен в управлении.",
        seats: 2,
        acceleration: 4.1,
        maxSpeed: 0
    },
    "Osiris": {
        desc: "Двухместный суперкар, является одним из лучших для гонок по ночному городу, имеет отличную скорость и хорошую тормозную систему.",
        seats: 2,
        acceleration: 4,
        maxSpeed: 0
    },
    "Nero": {
        desc: "Двухместный гиперкар, внешне данный автомобиль выглядит необычно, а внутри него вы сможете найти отличную начинку, которая поможет вам в уличных гонках ;)",
        seats: 2,
        acceleration: 4.5,
        maxSpeed: 0
    },
    "Zentorno": {
        desc: "Двухместный суперкар, является одной из самых мощных и роскошных машин, имеет неплохую управляемость.",
        seats: 2,
        acceleration: 3.7,
        maxSpeed: 0
    },
    "Infernus2": {
        desc: "Представляет из себя двухместный спортивный автомобиль, является классической версией первого Infernus.",
        seats: 2,
        acceleration: 5.1,
        maxSpeed: 0
    },
    "Cheetah2": {
        desc: "Двухместный спорткар, является классической версией первого Cheetah.",
        seats: 2,
        acceleration: 5.4,
        maxSpeed: 0
    },
    "Feltzer3": {
        desc: "Является представителем спортивной классики, имеет два посадочных места, а так же необычный внешний вид.",
        seats: 2,
        acceleration: 6,
        maxSpeed: 0
    },
    "Mamba": {
        desc: "Двухместная спортивная классика, не смотря на внешний вид, имеет хороший двигатель и не очень чувствительные тормоза.",
        seats: 2,
        acceleration: 6.2,
        maxSpeed: 0
    },
    "Monroe": {
        desc: "Классический двухместный спорткар, является одним из самых быстрых классических спорткаров в штате.",
        seats: 2,
        acceleration: 6.5,
        maxSpeed: 0
    },
    "Rapidgt3": {
        desc: "Двухдверный классический спортивный автомобиль, имеет симпатичный внешний вид, а также неплохую управляемость. ",
        seats: 2,
        acceleration: 5.8,
        maxSpeed: 0
    },
    "Swinger": {
        desc: "Спортивный двухместный автомобиль, очень компактный и быстрый, имеет открытый верх, благодаря чему получится прочувствовать всю его скорость.",
        seats: 2,
        acceleration: 5.5,
        maxSpeed: 0
    },
    "Torero": {
        desc: "Классический спортивный автомобиль, имеет очень необычный внешний вид, производительность данного автомобиля стоит в ряду с другими классическими споткарами.",
        seats: 2,
        acceleration: 5.5,
        maxSpeed: 0
    },
    "Turismo2": {
        desc: "Классическая версия обычного Turismo, имеет более аэродинамический и легкий кузов, благодаря чему данный автомобиль получает дополнительные бонусы к ускорению. ",
        seats: 2,
        acceleration: 5.1,
        maxSpeed: 0
    },
    "Viseris": {
        desc: "Один из представителей спортивной классики, данный автомобиль может подойти как просто для езды по городу, так и для гоночных соревнований. Имеет хорошую управляемость.",
        seats: 2,
        acceleration: 6.1,
        maxSpeed: 0
    },
    "Coquette3": {
        desc: "Является одним из лучших автомобилей в своем классе, у него очень хорошее ускорение, а также высокая максимальная скорость.",
        seats: 2,
        acceleration: 6.3,
        maxSpeed: 0
    },
    "Dubsta3": {
        desc: "Четырёхдверный вездеход, имеет хорошую максимальную скорость и ускорение, однако управляемость данного автомобиля оставляет желать лучшего.",
        seats: 4,
        acceleration: 6.3,
        maxSpeed: 0
    },
    "Comet3": {
        desc: "Отличается от своей первой версии внешним видом и уникальными дисками, в технической части данные автомобили похожи.",
        seats: 0,
        acceleration: 5,
        maxSpeed: 0
    },
    "Khamelion": {
        desc: "Гибридный двухместный спортивный автомобиль, оснащен неплохим двигателем, управляется... неплохо! ",
        seats: 2,
        acceleration: 4.8,
        maxSpeed: 0
    },
    "Seven70": {
        desc: "Роскошный двухместный спортивный автомобиль, обладает отличными характеристиками, которые лишь немного уступают другим спорткарам. Пойдёт.",
        seats: 2,
        acceleration: 5.2,
        maxSpeed: 0
    },
    "Specter": {
        desc: "Двухместный спортивный автомобиль, имеет неплохой разгон и хорошую управляемость.",
        seats: 2,
        acceleration: 5.6,
        maxSpeed: 0
    },
    "Specter2": {
        desc: "Схож со своей первой версией, но имеет уникальный цвет дисков и окраску.",
        seats: 2,
        acceleration: 5.2,
        maxSpeed: 0
    },
    "Verlierer2": {
        desc: "Мощный двухместный спортивный автомобиль, имеет довольно неплохую максимальную скорость и хорошее ускорение.",
        seats: 2,
        acceleration: 5.1,
        maxSpeed: 0
    },
    "Adder": {
        desc: "Двухместный суперкар, является одним из самых быстрых автомобилей в своём классе, благодаря полному приводу данный автомобиль имеет хорошую управляемость и чувствительность на дороге. ",
        seats: 2,
        acceleration: 5.2,
        maxSpeed: 0
    },
    "Bullet": {
        desc: "Двухместный суперкар, на фоне с остальными суперкарами особо не выделяется. ",
        seats: 2,
        acceleration: 6,
        maxSpeed: 0
    },
    "Cheetah": {
        desc: "Роскошный двухместный суперкар, является одной из самых быстрых машин, разъезжая на нём, вы сможете почувствовать себя королём на ночных дорогах.",
        seats: 2,
        acceleration: 4.9,
        maxSpeed: 0
    },
    "Cyclone": {
        desc: "Двухместный электрический суперкар, данный электромобиль способен развивать такую скорость, на которую не способны другие автомобили данной категории.",
        seats: 2,
        acceleration: 2.6,
        maxSpeed: 0
    },
    "Fmj": {
        desc: "Двухместный суперкар, данный автомобиль имеет отличную управляемость, прекрасное сцепление с дорогой, а так же высокую максимальную скорость.",
        seats: 2,
        acceleration: 4.3,
        maxSpeed: 0
    },
    "Pfister811": {
        desc: "Мощный двухместный суперкар, общие характеристики данного автомобиля весьма не плохи, также он имеет хорошую динамику ускорения.",
        seats: 2,
        acceleration: 3.7,
        maxSpeed: 0
    },
    "Sc1": {
        desc: "Двухместный суперкар, имеет необычный внешний вид, а так же неплохие характеристики.",
        seats: 2,
        acceleration: 5,
        maxSpeed: 0
    },
    "T20": {
        desc: "Двухместный суперкар, является одним из лучших в своей категории, а так же обладает отличными характеристиками и хорошей управляемостью.",
        seats: 2,
        acceleration: 3.3,
        maxSpeed: 0
    },
    "Tempesta": {
        desc: "Двухместный суперкар, данный автомобиль отлично подойдет для гонок по ночному городу.",
        seats: 2,
        acceleration: 4.1,
        maxSpeed: 0
    },
    "Vacca": {
        desc: "Двухместный суперкар, если вы опытный водитель, то вы точно найдете применение данному автомобилю, ведь он обладает неплохими характеристиками.",
        seats: 2,
        acceleration: 5.6,
        maxSpeed: 0
    },
    "Vagner": {
        desc: "Двухместный гиперкар, является одним из самых быстрых автомобилей и имеет необычный внешний вид.",
        seats: 2,
        acceleration: 3.1,
        maxSpeed: 0
    },
    "Visione": {
        desc: "Двухместный суперкар, имеет необычный внешний вид и неплохие характеристики.",
        seats: 2,
        acceleration: 4.1,
        maxSpeed: 0
    },
    "Baller4": {
        desc: "Данный четырёхдверный роскошный внедорожник, имеет схожий внешний вид со своими другими версиями и немного улучшенные характеристики. ",
        seats: 4,
        acceleration: 7.3,
        maxSpeed: 0
    },
    "Jester3": {
        desc: "Классический двухместный спортивный автомобиль, обладает довольно неплохими характеристиками, а так же отлично подходит для различных спортивных состязаний.",
        seats: 2,
        acceleration: 5.3,
        maxSpeed: 0
    },
    "Coquette2": {
        desc: "Двухместный классический спортивный автомобиль, управляемость оставляет желать лучшего, но его корпус выделяется великолепной прочностью.",
        seats: 2,
        acceleration: 5.4,
        maxSpeed: 0
    },
    "Gt500": {
        desc: "Классический спортивный автомобиль, оснащен довольно мощным двигателем, благодаря чему способен развивать высокую максимальную скорость.",
        seats: 2,
        acceleration: 6.5,
        maxSpeed: 0
    },
    "Stinger": {
        desc: "Двухместный классический спортивный автомобиль, обладает отличной максимальной скоростью и ускорением.",
        seats: 2,
        acceleration: 7.3,
        maxSpeed: 0
    },
    "Stingergt": {
        desc: "Классический двухместный спортивный автомобиль, разница между первой версией автомобиля видна лишь в управлении.",
        seats: 2,
        acceleration: 7.3,
        maxSpeed: 0
    },
    "Italigtb": {
        desc: "Двухместный спортивный автомобиль, отличается от другой своей версии внешним видом и чуть изменёнными характеристиками.",
        seats: 2,
        acceleration: 4.3,
        maxSpeed: 0
    },
    "Windsor2": {
        desc: "Роскошный четырёхместный кабриолет, обладает великолепной максимальной скоростью и ускорением.",
        seats: 4,
        acceleration: 7.3,
        maxSpeed: 0
    },
    "Deviant": {
        desc: "Роскошный двухместный маслкар, обладает необычным внешним видом и неплохими характеристиками для автомобиля своей категории.",
        seats: 2,
        acceleration: 7.8,
        maxSpeed: 0
    },
    "Dominator3": {
        desc: "Двухместный современный маслкар, вполне современная машина, оснащённая всеми необходимыми системами.",
        seats: 2,
        acceleration: 6.8,
        maxSpeed: 0
    },
    "Toros": {
        desc: "Роскошный внедорожник, обладает очень красивым дизайном и превосходными характеристиками на дороге. Ррр!",
        seats: 4,
        acceleration: 4.8,
        maxSpeed: 0
    },
    "Italigto": {
        desc: "Двухместный спортивный автомобиль, обладает очень хорошим ускорением, а так же хорошей максимальной скоростью и торможением.",
        seats: 2,
        acceleration: 3.6,
        maxSpeed: 0
    },
    "Jugular": {
        desc: "Четырёхместный спортивный автомобиль, имеет неплохой внешний вид и хорошие характеристики для езды.",
        seats: 4,
        acceleration: 3.6,
        maxSpeed: 0
    },
    "Locust": {
        desc: "Данный двухместный спортивный автомобиль выделяется среди остальных своим открытым верхом, он отлично подойдет для гонок по ночному городу.",
        seats: 2,
        acceleration: 4.6,
        maxSpeed: 0
    },
    "Neo": {
        desc: "Двухместный спортивный автомобиль, выполненный в необычном дизайне. Имеет неплохие характеристики.",
        seats: 2,
        acceleration: 3.6,
        maxSpeed: 0
    },
    "Paragon": {
        desc: "Двухместный спортивный автомобиль люкс класса, имеет конструкцию, аналогичную кабриолету Cognoscenti.",
        seats: 2,
        acceleration: 4.4,
        maxSpeed: 0
    },
    "Schlagen": {
        desc: "Двухместный спортивный автомобиль, имеет очень красивый внешний вид и неплохую производительность на дороге.",
        seats: 2,
        acceleration: 4.6,
        maxSpeed: 0
    },
    "Thrax": {
        desc: "Двухместный гиперкар, имеет очень вызывающий внешний вид и безупречное сцепление с дорогой, а так же выделяется невероятным ускорением и максимальной скоростью. Раньше он стоял в казино :)",
        seats: 2,
        acceleration: 4.5,
        maxSpeed: 0
    },
    "Drafter": {
        desc: "Cпортивное двухместное купе, имеет неплохие характеристики и интересный внешний вид.",
        seats: 2,
        acceleration: 4.6,
        maxSpeed: 0
    },
    "Emerus": {
        desc: "Двухместный гиперкар, имеет необычный внешний вид и хорошие ездовые характеристики.",
        seats: 2,
        acceleration: 4.4,
        maxSpeed: 0
    },
    "Zorrusso": {
        desc: "Данный автомобиль является двухместным гиперкаром. Как и все автомобили от данного производителя, он имеет очень необычный внешний вид.",
        seats: 2,
        acceleration: 4,
        maxSpeed: 0
    },
    "Comet5": {
        desc: "Модифицированный вариант обычного Comet, от оригинальной машины отличается уникальными фарами и обновлённым кузовом. ",
        seats: 2,
        acceleration: 3.9,
        maxSpeed: 0
    },
    "Furia": {
        desc: "Двухместный суперкар, имеет функциональный аэродинамический спойлер, а также неплохую максимальную скорость. ",
        seats: 2,
        acceleration: 3.4,
        maxSpeed: 0
    },
    "Taipan": {
        desc: "Двухместный суперкар, обладает средними характеристиками в плане производительности в своем классе, но при этом способен уверенно чувствовать себя на дороге. ",
        seats: 2,
        acceleration: 4.8,
        maxSpeed: 0
    },
    "Entity2": {
        desc: "Двухместный суперкар, если вам нужен по-настоящему быстрый и надежный автомобиль, то Entity2 создан специально для вас.",
        seats: 2,
        acceleration: 4.2,
        maxSpeed: 0
    },
    "Imorgon": {
        desc: "Двухместный электрический спортивный автомобиль, обладает очень высокой скоростью разона и неплохой максимальной скоростью, а так же пачку крутых винилов в тюнинге.",
        seats: 2,
        acceleration: 3,
        maxSpeed: 0
    },
    "Caddy": {
        desc: "Небольшая машинка, используемая для передвижения по гольф-полям",
        seats: 2,
        acceleration: 17.5,
        maxSpeed: 0
    },
    "Romero": {
        desc: "Четырёхдверный катафалк, имеет очень вместительный багажник.",
        seats: 4,
        acceleration: 15.2,
        maxSpeed: 0
    },
    "Hotknife": {
        desc: "Двухдверный хот-род с весьма неплохой управляемостью.",
        seats: 2,
        acceleration: 6.6,
        maxSpeed: 0
    },
    "Ruston": {
        desc: "Двухдверный редкий спортивный автомобиль имеет хороший разгон и плавно управляется. ",
        seats: 2,
        acceleration: 4.9,
        maxSpeed: 0
    },
    "Outlaw": {
        desc: "Этот спортивный вездеход может проехать абсолютно по любой дороге.",
        seats: 2,
        acceleration: 4.4,
        maxSpeed: 0
    },
    "Stafford": {
        desc: "Классический четырёхдверный седан, не очень хорош в плане скорости, но отлично подойдет для коллекции.",
        seats: 4,
        acceleration: 11.4,
        maxSpeed: 0
    },
    "Shotaro": {
        desc: "Очень редкий спортивный мотоцикл выполненный в уникальном стиле, обладает отличным сцеплением с любой поверхностью, что позволяет быстро стартовать. Невероятно красиво светится.",
        seats: 1,
        acceleration: 4,
        maxSpeed: 0
    },
    "ZType": {
        desc: "Когда это был самый быстрый автомобиль, сегодня ZType - мечта всех коллекционеров.",
        seats: 2,
        acceleration: 6.7,
        maxSpeed: 0
    },
    "RRSVR": {
        desc: "Четырёхдверный полноприводный универсал, предназначен для любого типа дорог. Очень мощный и красивый.",
        seats: 4,
        acceleration: 10,
        maxSpeed: 0
    },
    "BmwM3": {
        desc: "Двухдверное купе с усовершенствованным укреплённым и более аэродинамическим кузовом.",
        seats: 4,
        acceleration: 4.1,
        maxSpeed: 0
    },
    "BmwM5": {
        desc: "Четырёхдверный седан, обеспечивает уровень динамики, точности и мощности, соответствующий самым высоким стандартам. Можно флексить с друзьями, ведь тут аж 4 посадочных места!",
        seats: 4,
        acceleration: 4.5,
        maxSpeed: 0
    },
    "BmwM8": {
        desc: "Элитное двухдверное купе, его превосходный внешний вид и ходовые характеристики не оставят равнодушным НИКОГО!",
        seats: 2,
        acceleration: 4.3,
        maxSpeed: 0
    },
    "MBC63S": {
        desc: "Двухдверное купе, выполненный в компактном стиле. Выглядит отпадно, едет так же. Честно.",
        seats: 2,
        acceleration: 4.5,
        maxSpeed: 0
    },
    "MBG63": {
        desc: "Четырёхдверный внедорожник с повышенной проходимостью, отлично подойдет для того, чтобы \"валить\" на нём. А ещё для деловых встреч, если вы понимаете, о чём я ;)",
        seats: 4,
        acceleration: 9,
        maxSpeed: 0
    },
    "AudiR8": {
        desc: "Полноприводный немецкий спортивный автомобиль, способный быстро набрать скорость и поразить окружающих своим невероятным внешним видом.",
        seats: 2,
        acceleration: 4.6,
        maxSpeed: 0
    },
    "BmwI8": {
        desc: "Полноприводное двухдверное купе с хорошим аэродинамическим сопротивлением. Окружающие будут завидовать.",
        seats: 2,
        acceleration: 4.6,
        maxSpeed: 0
    },
    "DodgeViper": {
        desc: "Классический американский спортивный автомобиль, на борту у него мощный двигатель, который позволит почувствовать форсаж ;)",
        seats: 2,
        acceleration: 4,
        maxSpeed: 0
    },
};