<div class="box-flex policecomputer__licenses">
    <div class="inv-lic-moto" />
    <div class="inv-lic-car" />
    <div class="inv-lic-truck" />
    <div class="inv-lic-boat active" />
    <div class="inv-lic-helipad" />
    <div class="inv-lic-drone" />
    <div class="inv-lic-gun" />
    <div class="inv-lic-medical" />
    <div class="inv-lic-health" />
</div>