<svg xmlns="http://www.w3.org/2000/svg" width="318.25" height="526.04" viewBox="0 0 318.25 526.04">
  <defs>
    <style>
      .cls-1 {
        fill: #dadada;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="oil4" class="cls-1" d="M4863,6885l-15-9-22-8h-19l-23-3-3-4-20-27-11-13-11-3-15-1-22-2,2-52-6-48-10-57-2-28-31-32-9-14s24.5-24.19,27-25,56-34,56-34l60-61,29-27,26-28,55-28,28-12,26,9,4,26s3,17.31,2,22-4,37-4,37l-6,22-7,45-12,22v19l10,17,6,29-13,18-7,12-16,14-16,18-15,36,10,31,16,24-2,14-8,14-15,19-4,17-1,25,4,30-13-2Z" transform="translate(-4643.94 -6366.34)"/>
</svg>
