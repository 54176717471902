<svg xmlns="http://www.w3.org/2000/svg" width="199.56" height="84.38" viewBox="0 0 199.56 84.38">
  <defs>
    <style>
      .cls-1 {
        fill: #c1c1c1;
        fill-opacity: 0.4;
        stroke: #fff;
        stroke-linecap: square;
        stroke-linejoin: bevel;
        stroke-width: 6px;
        stroke-dasharray: 24 12;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <path id="speedcarrent" class="cls-1" d="M3748,6890l-120,39-33,11-17-4-23,6,28,21,7,4,19,1,132-46,10-13Z" transform="translate(-3553.66 -6887.28)"/>
</svg>
